/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CountryApiCountry from '../model/CountryApiCountry';
import CountryApiState from '../model/CountryApiState';


export default class CountryControllerApi {

    
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    getCitiesWithHttpInfo(countryIsoCode, stateIsoCode) {
      let postBody = null;
      // verify the required parameter 'countryIsoCode' is set
      if (countryIsoCode === undefined || countryIsoCode === null) {
        throw new Error("Missing the required parameter 'countryIsoCode' when calling getCities");
      }
      // verify the required parameter 'stateIsoCode' is set
      if (stateIsoCode === undefined || stateIsoCode === null) {
        throw new Error("Missing the required parameter 'stateIsoCode' when calling getCities");
      }

      let pathParams = {
        'countryIsoCode': countryIsoCode,
        'stateIsoCode': stateIsoCode
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [CountryApiState];
      return this.apiClient.callApi(
        '/countries/{countryIsoCode}/states/{stateIsoCode}/cities', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getCities(countryIsoCode, stateIsoCode) {
      return this.getCitiesWithHttpInfo(countryIsoCode, stateIsoCode)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    getCountriesWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [CountryApiCountry];
      return this.apiClient.callApi(
        '/countries', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getCountries() {
      return this.getCountriesWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    getStatesWithHttpInfo(countryIsoCode) {
      let postBody = null;
      // verify the required parameter 'countryIsoCode' is set
      if (countryIsoCode === undefined || countryIsoCode === null) {
        throw new Error("Missing the required parameter 'countryIsoCode' when calling getStates");
      }

      let pathParams = {
        'countryIsoCode': countryIsoCode
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [CountryApiState];
      return this.apiClient.callApi(
        '/countries/{countryIsoCode}/states', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getStates(countryIsoCode) {
      return this.getStatesWithHttpInfo(countryIsoCode)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
