import React, { useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useLingui } from '@lingui/react';
import { Autocomplete, Grid, TextField } from '@mui/material';
import { countryApi } from '@services/api';
import { Spinner } from './Spinner';
import { useQuery } from '@tanstack/react-query';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    gridRowGap: theme.spacing(2),
    placeItems: 'center',
  },
}));

export default function AddressForm(props) {
  const classes = useStyles(props);
  const { i18n } = useLingui();
  const { address, setAddress } = props;

  const { isLoading: isLoadingCountries, data: countries } = useQuery([`getCountries`], () =>
    countryApi.getCountries(),
  );
  const { isInitialLoading: isLoadingStates, data: states } = useQuery(
    [`getStates`, address.country],
    () => countryApi.getStates(address.country),
    {
      enabled: address.country !== '',
    },
  );

  useEffect(() => {
    const fetchUserCountry = async () => {
      try {
        const response = await fetch('https://api.country.is');
        const data = await response.json();
        if (data && data.country) {
          setAddress({ ...address, country: data.country });
        }
      } catch (error) {
        console.error("Error while detecting the user's country:", error);
      }
    };

    if (!address.country) {
      fetchUserCountry();
    }
  }, [address, setAddress]);

  if (isLoadingCountries || isLoadingStates) {
    return <Spinner notFullscreen={true} />;
  }

  return (
    <div className={classes.container}>
      <Autocomplete
        id="country"
        options={countries ?? []}
        getOptionLabel={option => option.name}
        value={countries?.find(c => c.iso2 === address.country) || null}
        onChange={(event, newValue) => {
          setAddress({ ...address, country: newValue?.iso2 || '', state: '' });
        }}
        renderInput={params => <TextField {...params} label={i18n._('Country')} required />}
        fullWidth
      />
      <TextField
        id="full-address-line"
        label={i18n._('Full address (street, number, intercom, etc.)')}
        value={address.line1}
        onChange={({ target }) => setAddress({ ...address, line1: target.value })}
        fullWidth
        required
      />
      <TextField
        id="address-line-2"
        label={i18n._('Further information (optional)')}
        value={address.line2}
        onChange={({ target }) => setAddress({ ...address, line2: target.value })}
        fullWidth
      />
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            id="zip-code"
            label={i18n._('Zip code')}
            type="text"
            value={address.postalCode}
            onChange={({ target }) => setAddress({ ...address, postalCode: target.value })}
            fullWidth
            required
            inputProps={{ maxLength: 10 }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            id="province"
            options={states ?? []}
            getOptionLabel={option => `${option.name} (${option.iso2})`}
            value={states?.find(s => s.iso2 === address.state) || null}
            onChange={(event, newValue) => {
              setAddress({ ...address, state: newValue?.iso2 || '' });
            }}
            renderInput={params => <TextField {...params} label={i18n._('Province')} required />}
            fullWidth
            disabled={!address.country} // Disable state selection if the country is not selected
          />
        </Grid>
      </Grid>
      <TextField
        id="city"
        label={i18n._('City')}
        value={address.city}
        onChange={({ target }) => setAddress({ ...address, city: target.value })}
        fullWidth
        required
        disabled={!address.state} // Disable city selection if the state is not selected
      />
    </div>
  );
}
