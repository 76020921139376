import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const themedContainerStyle = menuTheme => ({
  backgroundColor: menuTheme.background,
  backgroundImage: menuTheme.backgroundImage ? `url(${menuTheme.backgroundImage})` : 'inherit',
  backgroundSize: 'cover',
  backgroundPosition: 'center top',
  backgroundRepeat: 'no-repeat',
  color: menuTheme.text,
});

const useStyles = makeStyles(theme => ({
  backgroundcontainer: {
    position: 'fixed',
    width: '100%',
    height: '100%',
  },
  qrCodeMenucontainer: {
    display: 'grid',
    gridTemplateRows: 'min-content auto min-content',
    width: '100%',
    height: '100%',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
  },
  fab: {
    position: 'absolute',
    zIndex: '1110',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function QrMenuPageLayout(props) {
  const classes = useStyles(props);
  return (
    <div className={classes.backgroundcontainer} style={themedContainerStyle(props.theme)}>
      {props.children}
    </div>
  );
}
