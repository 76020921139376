import React from 'react';
import { IconButton, MenuItem, Popover } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { useLingui } from '@lingui/react';

export const CommonActionMenu = props => {
  const {
    onClickDuplicate,
    onClickEdit,
    editButtonName,
    onClickPdf,
    onClickDelete,
    pdfEnabled,
    onClickDisable,
    disableButtonLabel,
  } = props;
  const [open, setOpen] = React.useState(null);
  const { i18n } = useLingui();

  const handleClick = event => {
    event.stopPropagation();
    setOpen(event.currentTarget);
  };

  const handleAction = (event, actionFunction) => {
    event.stopPropagation();
    if (actionFunction) {
      setOpen(null);
      actionFunction();
    }
  };

  const handleClose = event => {
    event.stopPropagation();
    setOpen(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Popover
        open={!!open}
        onClose={handleClose}
        anchorEl={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {onClickDuplicate && (
          <MenuItem
            key={'duplicate-item'}
            onClick={event => handleAction(event, props.onClickDuplicate)}
          >
            <FileCopyOutlinedIcon color="primary" sx={{ mr: 1 }} />
            {i18n._('Duplicate')}
          </MenuItem>
        )}

        {onClickEdit && (
          <MenuItem key={'edit-item'} onClick={event => handleAction(event, props.onClickEdit)}>
            <EditIcon color="primary" sx={{ mr: 1 }} />
            {editButtonName}
          </MenuItem>
        )}

        {onClickDisable && (
          <MenuItem
            key={'disable-item'}
            onClick={event => handleAction(event, props.onClickDisable)}
          >
            <VisibilityIcon color="primary" sx={{ mr: 1 }} />
            {disableButtonLabel}
          </MenuItem>
        )}

        {pdfEnabled && onClickPdf && (
          <MenuItem key={'generate-pdf'} onClick={event => handleAction(event, props.onClickPdf)}>
            <PictureAsPdfIcon color="primary" sx={{ mr: 1 }} />
            {i18n._('Generate PDF')}
          </MenuItem>
        )}

        {onClickDelete && (
          <MenuItem key={'delete'} onClick={event => handleAction(event, props.onClickDelete)}>
            <DeleteIcon color="error" sx={{ mr: 1 }} />
            {i18n._('Delete')}
          </MenuItem>
        )}
      </Popover>
    </>
  );
};
