/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ActivateUserResponse from '../model/ActivateUserResponse';
import CreateUserRequest from '../model/CreateUserRequest';
import LogLanguageMetricRequest from '../model/LogLanguageMetricRequest';
import PasswordRecoveryRequest from '../model/PasswordRecoveryRequest';
import PasswordResetRequest from '../model/PasswordResetRequest';


export default class UserControllerApi {

    
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    activateUserWithHttpInfo(token) {
      let postBody = null;
      // verify the required parameter 'token' is set
      if (token === undefined || token === null) {
        throw new Error("Missing the required parameter 'token' when calling activateUser");
      }

      let pathParams = {
      };
      let queryParams = {
        'token': token
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ActivateUserResponse;
      return this.apiClient.callApi(
        '/users/activate', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    activateUser(token) {
      return this.activateUserWithHttpInfo(token)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    createUserWithHttpInfo(request) {
      let postBody = request;
      // verify the required parameter 'request' is set
      if (request === undefined || request === null) {
        throw new Error("Missing the required parameter 'request' when calling createUser");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/users', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    createUser(request) {
      return this.createUserWithHttpInfo(request)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    logLanguageMetricWithHttpInfo(request) {
      let postBody = request;
      // verify the required parameter 'request' is set
      if (request === undefined || request === null) {
        throw new Error("Missing the required parameter 'request' when calling logLanguageMetric");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/users/language', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    logLanguageMetric(request) {
      return this.logLanguageMetricWithHttpInfo(request)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    passwordRecoveryWithHttpInfo(request) {
      let postBody = request;
      // verify the required parameter 'request' is set
      if (request === undefined || request === null) {
        throw new Error("Missing the required parameter 'request' when calling passwordRecovery");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/users/password/recovery', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    passwordRecovery(request) {
      return this.passwordRecoveryWithHttpInfo(request)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    passwordResetWithHttpInfo(request) {
      let postBody = request;
      // verify the required parameter 'request' is set
      if (request === undefined || request === null) {
        throw new Error("Missing the required parameter 'request' when calling passwordReset");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/users/password/reset', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    passwordReset(request) {
      return this.passwordResetWithHttpInfo(request)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
