/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Banner from '../model/Banner';
import CreateOrUpdateBannerUrlRequest from '../model/CreateOrUpdateBannerUrlRequest';
import CreateOrUpdateBannerUrlResponse from '../model/CreateOrUpdateBannerUrlResponse';


export default class BannerControllerApi {

    
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    createOrUpdateBannerWithHttpInfo(clubId, menuId, request) {
      let postBody = request;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling createOrUpdateBanner");
      }
      // verify the required parameter 'menuId' is set
      if (menuId === undefined || menuId === null) {
        throw new Error("Missing the required parameter 'menuId' when calling createOrUpdateBanner");
      }
      // verify the required parameter 'request' is set
      if (request === undefined || request === null) {
        throw new Error("Missing the required parameter 'request' when calling createOrUpdateBanner");
      }

      let pathParams = {
        'clubId': clubId,
        'menuId': menuId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = CreateOrUpdateBannerUrlResponse;
      return this.apiClient.callApi(
        '/clubs/{clubId}/banners/menu/{menuId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    createOrUpdateBanner(clubId, menuId, request) {
      return this.createOrUpdateBannerWithHttpInfo(clubId, menuId, request)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    deleteBannerWithHttpInfo(clubId, menuId) {
      let postBody = null;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling deleteBanner");
      }
      // verify the required parameter 'menuId' is set
      if (menuId === undefined || menuId === null) {
        throw new Error("Missing the required parameter 'menuId' when calling deleteBanner");
      }

      let pathParams = {
        'clubId': clubId,
        'menuId': menuId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/clubs/{clubId}/banners/menu/{menuId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    deleteBanner(clubId, menuId) {
      return this.deleteBannerWithHttpInfo(clubId, menuId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    getBannerWithHttpInfo(clubId, menuId) {
      let postBody = null;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling getBanner");
      }
      // verify the required parameter 'menuId' is set
      if (menuId === undefined || menuId === null) {
        throw new Error("Missing the required parameter 'menuId' when calling getBanner");
      }

      let pathParams = {
        'clubId': clubId,
        'menuId': menuId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Banner;
      return this.apiClient.callApi(
        '/clubs/{clubId}/banners/menu/{menuId}/banner', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getBanner(clubId, menuId) {
      return this.getBannerWithHttpInfo(clubId, menuId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    getBannerUploadUrlWithHttpInfo(clubId, menuId) {
      let postBody = null;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling getBannerUploadUrl");
      }
      // verify the required parameter 'menuId' is set
      if (menuId === undefined || menuId === null) {
        throw new Error("Missing the required parameter 'menuId' when calling getBannerUploadUrl");
      }

      let pathParams = {
        'clubId': clubId,
        'menuId': menuId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = CreateOrUpdateBannerUrlResponse;
      return this.apiClient.callApi(
        '/clubs/{clubId}/banners/menu/{menuId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getBannerUploadUrl(clubId, menuId) {
      return this.getBannerUploadUrlWithHttpInfo(clubId, menuId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
