import React, { useState } from 'react';
import { Box, Button, Container, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Spinner } from '@common/Spinner';
import { saveAs } from 'file-saver';
import { useLingui } from '@lingui/react';
import { getMenuPdf, menuApi } from '@services/api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useHistory, useParams } from 'react-router-dom';
import { clubApi } from '@services/api';
import WordConfirmationModal from 'components/modals/word-confirmation-modal';
import { MenuInfo } from 'components/menu/menu-card';
import { EmptyContent } from 'components/menu/empty-content';
import TopbarPageLayout from 'layout/TopbarPageLayout';
import { fillRoute, routes } from '@services/routes';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export default function MenusPage(props) {
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState();
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);
  const { clubId } = useParams();
  const queryClient = useQueryClient();
  const { i18n } = useLingui();
  const { isLoading, data } = useQuery(['getClubMenuList', clubId], () =>
    menuApi.getClubMenuList(clubId),
  );
  const deleteMutation = useMutation(() => menuApi.deleteMenu(clubId, selectedMenu.id), {
    onSuccess: async () => await queryClient.invalidateQueries(['getClubMenuList', clubId]),
  });
  const duplicateMutation = useMutation(async menuId => menuApi.duplicateMenu(clubId, menuId), {
    onSuccess: async () => await queryClient.invalidateQueries(['getClubMenuList', clubId]),
  });
  const updateMenuMutation = useMutation(
    async menu => menuApi.batchCreateOrUpdateMenu(clubId, { menu }),
    {
      onSuccess: async () => await queryClient.invalidateQueries(['getClubMenuList', clubId]),
    },
  );
  const { isLoading: isLoadingClub, data: clubData } = useQuery([`getClub_${clubId}`], () =>
    clubApi.getClub(clubId),
  );

  const onClickDelete = menu => {
    setSelectedMenu(menu);
    setShowDeleteModal(true);
  };

  const onDeleteConfirm = () => {
    deleteMutation.mutate();
    setShowDeleteModal(false);
  };

  const onClickPdf = async menu => {
    setIsLoadingPdf(true);
    const { body } = await getMenuPdf(clubId, menu.id);
    saveAs(new Blob([body], { type: 'application/pdf' }), 'menu.pdf');
    setIsLoadingPdf(false);
  };

  if (
    isLoading ||
    duplicateMutation.isLoading ||
    deleteMutation.isLoading ||
    updateMenuMutation.isLoading ||
    isLoadingPdf ||
    isLoadingClub
  ) {
    return <Spinner />;
  }

  const sortedMenu = data.sort((a, b) => a.position - b.position);
  const onDragAndDrop = droppedItem => {
    if (!droppedItem.destination || droppedItem.source.index === droppedItem.destination.index)
      return;
    const [reorderedItem] = sortedMenu.splice(droppedItem.source.index, 1);
    sortedMenu.splice(droppedItem.destination.index, 0, reorderedItem);
    sortedMenu.forEach((item, index) => (item.position = index));
    updateMenuMutation.mutate(sortedMenu);
  };

  return (
    <TopbarPageLayout title={i18n._('MENU')}>
      <Container
        sx={{
          py: { xs: 4, md: 8 },
          px: { xs: 4, md: 16 },
          '& .MuiTextField-root': {
            bgcolor: 'background.paper',
          },
          mb: 20,
        }}
      >
        {sortedMenu.length > 0 ? (
          <DragDropContext onDragEnd={onDragAndDrop}>
            <Droppable droppableId="list-container">
              {provided => (
                <Grid
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  container
                  spacing={1.5}
                  justifyContent="center"
                >
                  {sortedMenu.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                      {provided => (
                        <Grid
                          item
                          ref={provided.innerRef}
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}
                          xs={12}
                          md={8}
                        >
                          <MenuInfo
                            menu={item}
                            onView={() =>
                              history.push(
                                fillRoute(routes.menuSectionList, { clubId, menuId: item.id }),
                              )
                            }
                            onEdit={() =>
                              history.push(fillRoute(routes.menuEdit, { clubId, menuId: item.id }))
                            }
                            editButtonName={i18n._('Rename')}
                            onScheduling={() =>
                              history.push(
                                fillRoute(routes.menuSchedulingList, { clubId, menuId: item.id }),
                              )
                            }
                            onAddPromotion={() =>
                              history.push(fillRoute(routes.bannerNew, { clubId, menuId: item.id }))
                            }
                            onShowPromotion={() =>
                              history.push(
                                fillRoute(routes.menuBanner, { clubId, menuId: item.id }),
                              )
                            }
                            onDuplicate={() => duplicateMutation.mutate(item.id)}
                            onPdf={() => onClickPdf(item)}
                            onDelete={() => onClickDelete(item)}
                            pdfEnabled={clubData.pdfMenuEnabled}
                            bannerEnabled={clubData.bannerUpdateEnabled}
                          />
                        </Grid>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <EmptyContent text={i18n._('Please, add your first menu')} />
        )}
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            p: 3,
            pt: 2,
            display: 'flex',
            justifyContent: 'space-around',
            bgcolor: '#f5f5f5',
          }}
        >
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => history.push(fillRoute(routes.menuNew, { clubId }))}
            sx={{
              borderRadius: '25px',
              px: 8,
              py: 1.5,
              bgcolor: '#397FF7',
            }}
          >
            {i18n._('Add menu')}
          </Button>
        </Box>
      </Container>
      <WordConfirmationModal
        show={showDeleteModal}
        title={`${i18n._('Delete menu')} : ${selectedMenu?.adminDisplayName ?? selectedMenu?.name}`}
        onConfirm={onDeleteConfirm}
        onClose={() => setShowDeleteModal(false)}
      />
    </TopbarPageLayout>
  );
}
