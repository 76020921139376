import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { decodeHtml } from '@common/encode-translation-fix';

const useStyles = makeStyles(theme => ({
  containerMenus: {
    display: 'grid',
    gridAutoRows: 'min-content',
    gridAutoFlow: 'row',
    gridRowGap: theme.spacing(3),
    justifyItems: 'center',
    padding: theme.spacing(4),
    color: props => props.colorTheme.text,
    minHeight: `calc(100vh - ${theme.spacing(25)}px)`,
  },
  buttonFont: {
    font: props => (props.colorTheme && props.colorTheme.font ? props.colorTheme.font : 'inherit'),
    color: props =>
      props.colorTheme && props.colorTheme.buttonText ? props.colorTheme.buttonText : 'inherit',
  },
}));

export const QrCodeMenusBody = props => {
  const classes = useStyles(props);
  const { items, onClickViewMenu, colorTheme } = props;

  const userLang = navigator.language || navigator.userLanguage;
  const languageCode = userLang ? userLang.toLowerCase().substring(0, 2) : 'it';
  console.log(`${userLang} ${languageCode}`);

  return (
    <div className={classes.containerMenus}>
      {items &&
        items.map(b => (
          <Button
            key={'menuButton-' + b.id}
            style={{
              maxHeight: 70,
              paddingTop: 20,
              paddingBottom: 20,
              backgroundColor: colorTheme.main,
              '&:hover': {
                backgroundColor: colorTheme.main,
              },
            }}
            variant="contained"
            color="secondary"
            fullWidth={true}
            onClick={() => onClickViewMenu(b.id)}
          >
            <span className={classes.buttonFont}>
              {languageCode === 'it'
                ? b.name
                : decodeHtml(b.translations?.[languageCode]?.translatedName) ??
                  decodeHtml(b.translations?.['en']?.translatedName) ??
                  b.name}
            </span>
          </Button>
        ))}
    </div>
  );
};
