/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import TableZoneProjection from './TableZoneProjection';


class GetClubTableZoneListResponse {
    
    constructor() { 
        
        GetClubTableZoneListResponse.initialize(this);
    }

    
    static initialize(obj) { 
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new GetClubTableZoneListResponse();

            if (data.hasOwnProperty('tableZones')) {
                obj['tableZones'] = ApiClient.convertToType(data['tableZones'], [TableZoneProjection]);
            }
        }
        return obj;
    }

    
    static validateJSON(data) {
        if (data['tableZones']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['tableZones'])) {
                throw new Error("Expected the field `tableZones` to be an array in the JSON data but got " + data['tableZones']);
            }
            // validate the optional field `tableZones` (array)
            for (const item of data['tableZones']) {
                TableZoneProjection.validateJsonObject(item);
            };
        }

        return true;
    }


}




GetClubTableZoneListResponse.prototype['tableZones'] = undefined;






export default GetClubTableZoneListResponse;

