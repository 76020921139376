/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import BatchCreateOrUpdateMenuItemRequest from '../model/BatchCreateOrUpdateMenuItemRequest';
import BatchCreateOrUpdateMenuRequest from '../model/BatchCreateOrUpdateMenuRequest';
import BatchCreateOrUpdateMenuSectionRequest from '../model/BatchCreateOrUpdateMenuSectionRequest';
import CreateFullMenuRequest from '../model/CreateFullMenuRequest';
import CreateMenuFromExcelResponse from '../model/CreateMenuFromExcelResponse';
import CreateOrUpdateMenuItemRequest from '../model/CreateOrUpdateMenuItemRequest';
import CreateOrUpdateMenuRequest from '../model/CreateOrUpdateMenuRequest';
import CreateOrUpdateMenuSectionRequest from '../model/CreateOrUpdateMenuSectionRequest';
import GetMenuResponse from '../model/GetMenuResponse';
import GetTableMenuResponse from '../model/GetTableMenuResponse';
import MenuItemDto from '../model/MenuItemDto';
import MenuListItemProjection from '../model/MenuListItemProjection';
import MenuSectionDto from '../model/MenuSectionDto';


export default class MenuControllerApi {

    
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    batchCreateOrUpdateMenuWithHttpInfo(clubId, request) {
      let postBody = request;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling batchCreateOrUpdateMenu");
      }
      // verify the required parameter 'request' is set
      if (request === undefined || request === null) {
        throw new Error("Missing the required parameter 'request' when calling batchCreateOrUpdateMenu");
      }

      let pathParams = {
        'clubId': clubId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/clubs/{clubId}/menu/batch', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    batchCreateOrUpdateMenu(clubId, request) {
      return this.batchCreateOrUpdateMenuWithHttpInfo(clubId, request)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    batchCreateOrUpdateMenuItemWithHttpInfo(clubId, menuId, sectionId, request) {
      let postBody = request;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling batchCreateOrUpdateMenuItem");
      }
      // verify the required parameter 'menuId' is set
      if (menuId === undefined || menuId === null) {
        throw new Error("Missing the required parameter 'menuId' when calling batchCreateOrUpdateMenuItem");
      }
      // verify the required parameter 'sectionId' is set
      if (sectionId === undefined || sectionId === null) {
        throw new Error("Missing the required parameter 'sectionId' when calling batchCreateOrUpdateMenuItem");
      }
      // verify the required parameter 'request' is set
      if (request === undefined || request === null) {
        throw new Error("Missing the required parameter 'request' when calling batchCreateOrUpdateMenuItem");
      }

      let pathParams = {
        'clubId': clubId,
        'menuId': menuId,
        'sectionId': sectionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/clubs/{clubId}/menu/{menuId}/sections/{sectionId}/items/batch', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    batchCreateOrUpdateMenuItem(clubId, menuId, sectionId, request) {
      return this.batchCreateOrUpdateMenuItemWithHttpInfo(clubId, menuId, sectionId, request)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    batchCreateOrUpdateMenuSectionWithHttpInfo(clubId, menuId, request) {
      let postBody = request;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling batchCreateOrUpdateMenuSection");
      }
      // verify the required parameter 'menuId' is set
      if (menuId === undefined || menuId === null) {
        throw new Error("Missing the required parameter 'menuId' when calling batchCreateOrUpdateMenuSection");
      }
      // verify the required parameter 'request' is set
      if (request === undefined || request === null) {
        throw new Error("Missing the required parameter 'request' when calling batchCreateOrUpdateMenuSection");
      }

      let pathParams = {
        'clubId': clubId,
        'menuId': menuId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/clubs/{clubId}/menu/{menuId}/sections/batch', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    batchCreateOrUpdateMenuSection(clubId, menuId, request) {
      return this.batchCreateOrUpdateMenuSectionWithHttpInfo(clubId, menuId, request)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    createFullMenuWithHttpInfo(clubId, request) {
      let postBody = request;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling createFullMenu");
      }
      // verify the required parameter 'request' is set
      if (request === undefined || request === null) {
        throw new Error("Missing the required parameter 'request' when calling createFullMenu");
      }

      let pathParams = {
        'clubId': clubId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/clubs/{clubId}/menu', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    createFullMenu(clubId, request) {
      return this.createFullMenuWithHttpInfo(clubId, request)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    createMenuFromExcelWithHttpInfo(clubId, file) {
      let postBody = null;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling createMenuFromExcel");
      }
      // verify the required parameter 'file' is set
      if (file === undefined || file === null) {
        throw new Error("Missing the required parameter 'file' when calling createMenuFromExcel");
      }

      let pathParams = {
        'clubId': clubId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'file': file
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = CreateMenuFromExcelResponse;
      return this.apiClient.callApi(
        '/clubs/{clubId}/menu/excel', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    createMenuFromExcel(clubId, file) {
      return this.createMenuFromExcelWithHttpInfo(clubId, file)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    createOrUpdateMenuWithHttpInfo(clubId, request) {
      let postBody = request;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling createOrUpdateMenu");
      }
      // verify the required parameter 'request' is set
      if (request === undefined || request === null) {
        throw new Error("Missing the required parameter 'request' when calling createOrUpdateMenu");
      }

      let pathParams = {
        'clubId': clubId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/clubs/{clubId}/menu', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    createOrUpdateMenu(clubId, request) {
      return this.createOrUpdateMenuWithHttpInfo(clubId, request)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    createOrUpdateMenuItemWithHttpInfo(clubId, menuId, sectionId, request) {
      let postBody = request;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling createOrUpdateMenuItem");
      }
      // verify the required parameter 'menuId' is set
      if (menuId === undefined || menuId === null) {
        throw new Error("Missing the required parameter 'menuId' when calling createOrUpdateMenuItem");
      }
      // verify the required parameter 'sectionId' is set
      if (sectionId === undefined || sectionId === null) {
        throw new Error("Missing the required parameter 'sectionId' when calling createOrUpdateMenuItem");
      }
      // verify the required parameter 'request' is set
      if (request === undefined || request === null) {
        throw new Error("Missing the required parameter 'request' when calling createOrUpdateMenuItem");
      }

      let pathParams = {
        'clubId': clubId,
        'menuId': menuId,
        'sectionId': sectionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/clubs/{clubId}/menu/{menuId}/sections/{sectionId}/items', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    createOrUpdateMenuItem(clubId, menuId, sectionId, request) {
      return this.createOrUpdateMenuItemWithHttpInfo(clubId, menuId, sectionId, request)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    createOrUpdateMenuSectionWithHttpInfo(clubId, menuId, request) {
      let postBody = request;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling createOrUpdateMenuSection");
      }
      // verify the required parameter 'menuId' is set
      if (menuId === undefined || menuId === null) {
        throw new Error("Missing the required parameter 'menuId' when calling createOrUpdateMenuSection");
      }
      // verify the required parameter 'request' is set
      if (request === undefined || request === null) {
        throw new Error("Missing the required parameter 'request' when calling createOrUpdateMenuSection");
      }

      let pathParams = {
        'clubId': clubId,
        'menuId': menuId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/clubs/{clubId}/menu/{menuId}/sections', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    createOrUpdateMenuSection(clubId, menuId, request) {
      return this.createOrUpdateMenuSectionWithHttpInfo(clubId, menuId, request)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    deleteMenuWithHttpInfo(clubId, menuId) {
      let postBody = null;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling deleteMenu");
      }
      // verify the required parameter 'menuId' is set
      if (menuId === undefined || menuId === null) {
        throw new Error("Missing the required parameter 'menuId' when calling deleteMenu");
      }

      let pathParams = {
        'clubId': clubId,
        'menuId': menuId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/clubs/{clubId}/menu/{menuId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    deleteMenu(clubId, menuId) {
      return this.deleteMenuWithHttpInfo(clubId, menuId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    deleteMenuItemWithHttpInfo(clubId, itemId, menuId, sectionId) {
      let postBody = null;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling deleteMenuItem");
      }
      // verify the required parameter 'itemId' is set
      if (itemId === undefined || itemId === null) {
        throw new Error("Missing the required parameter 'itemId' when calling deleteMenuItem");
      }
      // verify the required parameter 'menuId' is set
      if (menuId === undefined || menuId === null) {
        throw new Error("Missing the required parameter 'menuId' when calling deleteMenuItem");
      }
      // verify the required parameter 'sectionId' is set
      if (sectionId === undefined || sectionId === null) {
        throw new Error("Missing the required parameter 'sectionId' when calling deleteMenuItem");
      }

      let pathParams = {
        'clubId': clubId,
        'itemId': itemId,
        'menuId': menuId,
        'sectionId': sectionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/clubs/{clubId}/menu/{menuId}/sections/{sectionId}/items/{itemId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    deleteMenuItem(clubId, itemId, menuId, sectionId) {
      return this.deleteMenuItemWithHttpInfo(clubId, itemId, menuId, sectionId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    deleteMenuSectionWithHttpInfo(clubId, menuId, sectionId) {
      let postBody = null;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling deleteMenuSection");
      }
      // verify the required parameter 'menuId' is set
      if (menuId === undefined || menuId === null) {
        throw new Error("Missing the required parameter 'menuId' when calling deleteMenuSection");
      }
      // verify the required parameter 'sectionId' is set
      if (sectionId === undefined || sectionId === null) {
        throw new Error("Missing the required parameter 'sectionId' when calling deleteMenuSection");
      }

      let pathParams = {
        'clubId': clubId,
        'menuId': menuId,
        'sectionId': sectionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/clubs/{clubId}/menu/{menuId}/sections/{sectionId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    deleteMenuSection(clubId, menuId, sectionId) {
      return this.deleteMenuSectionWithHttpInfo(clubId, menuId, sectionId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    duplicateMenuWithHttpInfo(clubId, menuId) {
      let postBody = null;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling duplicateMenu");
      }
      // verify the required parameter 'menuId' is set
      if (menuId === undefined || menuId === null) {
        throw new Error("Missing the required parameter 'menuId' when calling duplicateMenu");
      }

      let pathParams = {
        'clubId': clubId,
        'menuId': menuId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/clubs/{clubId}/menu/{menuId}/duplicate', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    duplicateMenu(clubId, menuId) {
      return this.duplicateMenuWithHttpInfo(clubId, menuId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    getClubMenuListWithHttpInfo(clubId) {
      let postBody = null;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling getClubMenuList");
      }

      let pathParams = {
        'clubId': clubId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [MenuListItemProjection];
      return this.apiClient.callApi(
        '/clubs/{clubId}/menu', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getClubMenuList(clubId) {
      return this.getClubMenuListWithHttpInfo(clubId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    getMenuWithHttpInfo(clubId, menuId) {
      let postBody = null;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling getMenu");
      }
      // verify the required parameter 'menuId' is set
      if (menuId === undefined || menuId === null) {
        throw new Error("Missing the required parameter 'menuId' when calling getMenu");
      }

      let pathParams = {
        'clubId': clubId,
        'menuId': menuId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = GetMenuResponse;
      return this.apiClient.callApi(
        '/clubs/{clubId}/menu/{menuId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getMenu(clubId, menuId) {
      return this.getMenuWithHttpInfo(clubId, menuId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    getMenuAsPdfWithHttpInfo(clubId, menuId) {
      let postBody = null;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling getMenuAsPdf");
      }
      // verify the required parameter 'menuId' is set
      if (menuId === undefined || menuId === null) {
        throw new Error("Missing the required parameter 'menuId' when calling getMenuAsPdf");
      }

      let pathParams = {
        'clubId': clubId,
        'menuId': menuId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/pdf'];
      let returnType = 'Blob';
      return this.apiClient.callApi(
        '/clubs/{clubId}/menu/{menuId}/pdf', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getMenuAsPdf(clubId, menuId) {
      return this.getMenuAsPdfWithHttpInfo(clubId, menuId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    getMenuItemWithHttpInfo(clubId, itemId, menuId, sectionId) {
      let postBody = null;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling getMenuItem");
      }
      // verify the required parameter 'itemId' is set
      if (itemId === undefined || itemId === null) {
        throw new Error("Missing the required parameter 'itemId' when calling getMenuItem");
      }
      // verify the required parameter 'menuId' is set
      if (menuId === undefined || menuId === null) {
        throw new Error("Missing the required parameter 'menuId' when calling getMenuItem");
      }
      // verify the required parameter 'sectionId' is set
      if (sectionId === undefined || sectionId === null) {
        throw new Error("Missing the required parameter 'sectionId' when calling getMenuItem");
      }

      let pathParams = {
        'clubId': clubId,
        'itemId': itemId,
        'menuId': menuId,
        'sectionId': sectionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = MenuItemDto;
      return this.apiClient.callApi(
        '/clubs/{clubId}/menu/{menuId}/sections/{sectionId}/items/{itemId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getMenuItem(clubId, itemId, menuId, sectionId) {
      return this.getMenuItemWithHttpInfo(clubId, itemId, menuId, sectionId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    getMenuSectionWithHttpInfo(clubId, menuId, sectionId) {
      let postBody = null;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling getMenuSection");
      }
      // verify the required parameter 'menuId' is set
      if (menuId === undefined || menuId === null) {
        throw new Error("Missing the required parameter 'menuId' when calling getMenuSection");
      }
      // verify the required parameter 'sectionId' is set
      if (sectionId === undefined || sectionId === null) {
        throw new Error("Missing the required parameter 'sectionId' when calling getMenuSection");
      }

      let pathParams = {
        'clubId': clubId,
        'menuId': menuId,
        'sectionId': sectionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = MenuSectionDto;
      return this.apiClient.callApi(
        '/clubs/{clubId}/menu/{menuId}/sections/{sectionId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getMenuSection(clubId, menuId, sectionId) {
      return this.getMenuSectionWithHttpInfo(clubId, menuId, sectionId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    getMenusForTableWithHttpInfo(clubId, tableId) {
      let postBody = null;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling getMenusForTable");
      }
      // verify the required parameter 'tableId' is set
      if (tableId === undefined || tableId === null) {
        throw new Error("Missing the required parameter 'tableId' when calling getMenusForTable");
      }

      let pathParams = {
        'clubId': clubId,
        'tableId': tableId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = GetTableMenuResponse;
      return this.apiClient.callApi(
        '/clubs/{clubId}/menu/tables/{tableId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getMenusForTable(clubId, tableId) {
      return this.getMenusForTableWithHttpInfo(clubId, tableId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
