/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class ClubTableProjection {
    
    constructor() { 
        
        ClubTableProjection.initialize(this);
    }

    
    static initialize(obj) { 
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ClubTableProjection();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('premium')) {
                obj['premium'] = ApiClient.convertToType(data['premium'], 'Boolean');
            }
            if (data.hasOwnProperty('tableZoneId')) {
                obj['tableZoneId'] = ApiClient.convertToType(data['tableZoneId'], 'Number');
            }
        }
        return obj;
    }

    
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }

        return true;
    }


}




ClubTableProjection.prototype['id'] = undefined;


ClubTableProjection.prototype['name'] = undefined;


ClubTableProjection.prototype['premium'] = undefined;


ClubTableProjection.prototype['tableZoneId'] = undefined;






export default ClubTableProjection;

