/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class MenuItemTranslation {
    
    constructor() { 
        
        MenuItemTranslation.initialize(this);
    }

    
    static initialize(obj) { 
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MenuItemTranslation();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('language')) {
                obj['language'] = ApiClient.convertToType(data['language'], 'String');
            }
            if (data.hasOwnProperty('originalDescription')) {
                obj['originalDescription'] = ApiClient.convertToType(data['originalDescription'], 'String');
            }
            if (data.hasOwnProperty('originalIngredients')) {
                obj['originalIngredients'] = ApiClient.convertToType(data['originalIngredients'], 'String');
            }
            if (data.hasOwnProperty('originalName')) {
                obj['originalName'] = ApiClient.convertToType(data['originalName'], 'String');
            }
            if (data.hasOwnProperty('translatedDescription')) {
                obj['translatedDescription'] = ApiClient.convertToType(data['translatedDescription'], 'String');
            }
            if (data.hasOwnProperty('translatedIngredients')) {
                obj['translatedIngredients'] = ApiClient.convertToType(data['translatedIngredients'], 'String');
            }
            if (data.hasOwnProperty('translatedName')) {
                obj['translatedName'] = ApiClient.convertToType(data['translatedName'], 'String');
            }
        }
        return obj;
    }

    
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['language'] && !(typeof data['language'] === 'string' || data['language'] instanceof String)) {
            throw new Error("Expected the field `language` to be a primitive type in the JSON string but got " + data['language']);
        }
        // ensure the json data is a string
        if (data['originalDescription'] && !(typeof data['originalDescription'] === 'string' || data['originalDescription'] instanceof String)) {
            throw new Error("Expected the field `originalDescription` to be a primitive type in the JSON string but got " + data['originalDescription']);
        }
        // ensure the json data is a string
        if (data['originalIngredients'] && !(typeof data['originalIngredients'] === 'string' || data['originalIngredients'] instanceof String)) {
            throw new Error("Expected the field `originalIngredients` to be a primitive type in the JSON string but got " + data['originalIngredients']);
        }
        // ensure the json data is a string
        if (data['originalName'] && !(typeof data['originalName'] === 'string' || data['originalName'] instanceof String)) {
            throw new Error("Expected the field `originalName` to be a primitive type in the JSON string but got " + data['originalName']);
        }
        // ensure the json data is a string
        if (data['translatedDescription'] && !(typeof data['translatedDescription'] === 'string' || data['translatedDescription'] instanceof String)) {
            throw new Error("Expected the field `translatedDescription` to be a primitive type in the JSON string but got " + data['translatedDescription']);
        }
        // ensure the json data is a string
        if (data['translatedIngredients'] && !(typeof data['translatedIngredients'] === 'string' || data['translatedIngredients'] instanceof String)) {
            throw new Error("Expected the field `translatedIngredients` to be a primitive type in the JSON string but got " + data['translatedIngredients']);
        }
        // ensure the json data is a string
        if (data['translatedName'] && !(typeof data['translatedName'] === 'string' || data['translatedName'] instanceof String)) {
            throw new Error("Expected the field `translatedName` to be a primitive type in the JSON string but got " + data['translatedName']);
        }

        return true;
    }


}




MenuItemTranslation.prototype['id'] = undefined;


MenuItemTranslation.prototype['language'] = undefined;


MenuItemTranslation.prototype['originalDescription'] = undefined;


MenuItemTranslation.prototype['originalIngredients'] = undefined;


MenuItemTranslation.prototype['originalName'] = undefined;


MenuItemTranslation.prototype['translatedDescription'] = undefined;


MenuItemTranslation.prototype['translatedIngredients'] = undefined;


MenuItemTranslation.prototype['translatedName'] = undefined;






export default MenuItemTranslation;

