import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import LanguageIcon from '@material-ui/icons/Language';
import WhatsappIcon from '@material-ui/icons/WhatsApp';

const useStyles = makeStyles(theme => ({
  footer: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridRowGap: theme.spacing(1),
    width: '100vw',
    padding: theme.spacing(1, 3, 2.5, 3),
    backgroundColor: '#202020',
  },
  socialLinkButton: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'min-content auto',
    gridColumnGap: theme.spacing(1),
    alignItems: 'center',
    width: '100%',
  },
  link: {
    color: '#fff',
  },
  socialLinkButtons: {
    display: 'flex',
    justifyContent: 'space-around',
    color: '#fff',
  },
  infoList: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  infos: {
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 0.43)',
    whiteSpace: 'break-spaces',
  },

  powerBy: {
    marginTop: theme.spacing(1),
    letterSpacing: '2px',
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 0.43)',
  },
  logo: {
    objectFit: 'contain',
    placeSelf: 'center',
    maxWidth: '70px',
  },
}));

export const QrCodeMenuFooter = props => {
  const classes = useStyles(props);
  const {
    address,
    phone,
    email,
    instagram: igLink,
    facebook: fbLink,
    website: urlLink,
    whatsapp,
  } = props;

  const urlEncodedWhatsapp = whatsapp?.replace(/[+{}-]/g, '').replace(/^0+/, '');

  return (
    <div className={classes.footer}>
      {/* social links */}
      {(igLink || fbLink || urlLink || whatsapp) && (
        <div className={classes.socialLinkButtons}>
          {igLink && (
            <IconButton color="inherit" aria-label="instagram" href={igLink}>
              <InstagramIcon />
            </IconButton>
          )}
          {fbLink && (
            <IconButton color="inherit" aria-label="facebook" href={fbLink}>
              <FacebookIcon />
            </IconButton>
          )}
          {urlLink && (
            <IconButton color="inherit" aria-label="site" href={urlLink} target="_blank">
              <LanguageIcon />
            </IconButton>
          )}
          {whatsapp && (
            <IconButton
              color="inherit"
              aria-label="site"
              href={'https://wa.me/' + urlEncodedWhatsapp}
            >
              <WhatsappIcon />
            </IconButton>
          )}
        </div>
      )}

      {/* contacts */}
      {(address || email || phone) && (
        <div className={classes.infoList}>
          {address && (
            <a href={'http://maps.apple.com/?q=' + address} className={classes.infos}>
              {address}
            </a>
          )}
          {email && (
            <a href={'mailto:' + email} className={classes.infos}>
              {email}
            </a>
          )}
          {phone && (
            <a href={'tel:' + phone} className={classes.infos}>
              {phone}
            </a>
          )}
        </div>
      )}

      <span className={classes.powerBy}>POWERED BY</span>
      <img
        className={classes.logo}
        src={`${import.meta.env.VITE_S3_URL}/assets/images/logo-vertical-g.svg`}
        alt="logo"
      />
    </div>
  );
};
