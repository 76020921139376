import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useLingui } from '@lingui/react';
import Button from '@material-ui/core/Button';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { clubApi } from '@services/api';
import { Spinner } from '@common/Spinner';
import { fillRoute, routes } from '@services/routes';
import {
  Checkbox,
  Container,
  FormControlLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import AddressForm from '@common/AddressForm';
import { brandsApi } from '@services/api';
import * as Sentry from '@sentry/react';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    gridRowGap: theme.spacing(2),
    placeItems: 'center',
  },

  formContainer: {
    width: '80%',
    height: '80%',
  },
  buttonContainer: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    gridTemplateRows: 'min-content',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(8, 0, 4, 0),
  },

  stepFormContainer: {
    display: 'grid',
    gridAutoRows: 'min-content',
    gridAutoFlow: 'row',
    gridRowGap: theme.spacing(4),
    alignItems: 'center',
  },
  infoSpan: {
    fontSize: '20px',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
  },
}));

export default function ClubPage(props) {
  const classes = useStyles(props);
  const history = useHistory();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { i18n } = useLingui();
  const [activeStep, setActiveStep] = useState(0);
  const [name, setName] = useState('');
  const [numberOfTables, setNumberOfTables] = useState('');
  const [brandId, setBrandId] = useState(0);
  const [salesAccount, setSalesAccount] = useState('');
  const [address, setAddress] = useState({
    line1: '',
    line2: '',
    country: '',
    postalCode: '',
    city: '',
    state: '',
  });
  const [billingAddress, setBillingAddress] = useState(null);
  const [isBillingAddressDifferent, setIsBillingAddressDifferent] = useState(false);
  const [vatNumber, setVatNumber] = useState('');
  const [companyName, setCompanyName] = useState('');

  const onChangeIsBillingAddressDifferent = ({ target }) => {
    setIsBillingAddressDifferent(target.checked);
    if (target.checked) {
      setBillingAddress({
        line1: '',
        line2: '',
        country: '',
        postalCode: '',
        city: '',
        state: '',
      });
    } else {
      setBillingAddress(null);
    }
  };

  const stepTitles = [
    i18n._('Enter your business details'),
    i18n._('Do you work with a brand?'),
    i18n._('Tax information'),
  ];
  const stepDescriptions = [
    i18n._(
      'Tell us about your business. These information will help our experts to suggest you the right plan and tools for your business.',
    ),
    i18n._(
      'Share more details about the brand you are collaborating with. This information will help our experts assess your needs and challenges.',
    ),
    i18n._(
      "Enter your VAT No. and the billing address if it is different from your club's address.",
    ),
  ];

  const { isFetching, data } = useQuery([`getPartnerBrands`], () => brandsApi.getPartnerBrands());
  const mutation = useMutation(
    () =>
      clubApi.createClub({
        name,
        numberOfTables,
        vatNumber,
        address,
        billingAddress,
        // TODO: rimuovere
        brandName: brandId === 0 ? null : data.find(({ id }) => id === brandId).name,
        brandId: brandId === 0 ? null : brandId,
        brandSalesAccount: salesAccount,
        companyName,
      }),
    {
      onSuccess: async ({ clubId }) => {
        await queryClient.invalidateQueries([`getClubsOwned`]);
        if (data.find(({ id }) => id === brandId)?.enabled) {
          // Francoli with approval
          enqueueSnackbar(
            i18n._(
              "Your club has been created. We'll send you an email once it has been activated. Meanwhile you can start creating your menu",
            ),
            {
              variant: 'info',
            },
          );
          history.push(fillRoute(routes.menuList, { clubId }));
        } else {
          history.push(fillRoute(routes.clubPayment, { clubId }));
        }
      },
      onError: error => {
        if (error.status === 400) {
          enqueueSnackbar(
            i18n._(
              'Invalid VAT number. Check that the VAT number is valid in your billing country',
            ),
          );
        } else {
          enqueueSnackbar(i18n._('An error occurred'));
          Sentry.captureException(error);
        }
      },
    },
  );

  const onFormSubmit = event => {
    event.preventDefault();
    if (hasNextStep()) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    } else {
      mutation.mutate();
    }
  };

  const hasNextStep = () => activeStep !== stepTitles.length - 1;

  if (isFetching || mutation.isLoading) {
    return <Spinner />;
  }

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <Typography variant="subtitle1">{i18n._('Club Information')}</Typography>
            <TextField
              label={i18n._('Club name')}
              value={name}
              onChange={({ target }) => setName(target.value)}
              required
            />
            <TextField
              label={i18n._('Tables number') + '(' + i18n._('approx.') + ')'}
              type="number"
              inputMode="numeric"
              value={numberOfTables}
              onChange={({ target }) => setNumberOfTables(target.value)}
              required
            />

            <Typography variant="subtitle1">{i18n._('Club Address')}</Typography>
            <AddressForm address={address} setAddress={setAddress} />
          </>
        );
      case 1:
        return (
          <>
            <TextField
              select
              label={i18n._('Brand reference')}
              value={brandId}
              onChange={({ target }) => setBrandId(target.value)}
            >
              <MenuItem value={0}>{i18n._('None')}</MenuItem>
              {data.map(option => (
                // TODO: change to id after backend fix
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label={i18n._('Sales account (Name and Last name)')}
              value={salesAccount}
              onChange={({ target }) => setSalesAccount(target.value)}
              required={brandId !== 0}
            />
          </>
        );
      case 2:
        return (
          <>
            <TextField
              label={i18n._('Company name')}
              value={companyName}
              onChange={({ target }) => setCompanyName(target.value)}
              required
            />
            <TextField
              label={i18n._('VAT No.')}
              value={vatNumber}
              onChange={({ target }) => setVatNumber(target.value)}
              required
            />

            <div>
              <Typography variant="subtitle1">{i18n._('Billing Address')}</Typography>

              <Stack spacing={2.5} mt={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isBillingAddressDifferent}
                      onChange={onChangeIsBillingAddressDifferent}
                    />
                  }
                  label={i18n._('Billing address different from club address?')}
                />
                {isBillingAddressDifferent && (
                  <AddressForm address={billingAddress} setAddress={setBillingAddress} />
                )}
              </Stack>
            </div>
          </>
        );
    }
  };

  return (
    <Container
      sx={{
        overflow: 'hidden',
        minHeight: 1,
        py: { xs: 8, md: 16 },
        px: { xs: 4, md: 16 },
      }}
    >
      <form className={classes.stepFormContainer} onSubmit={onFormSubmit}>
        <div>
          <div className={classes.infoSpan}>{stepTitles[activeStep]}</div>
          <span>{stepDescriptions[activeStep]}</span>
        </div>
        {getStepContent(activeStep)}

        <div className={classes.buttonContainer}>
          {activeStep === 0 ? (
            <div />
          ) : (
            <Button
              onClick={() => setActiveStep(prevActiveStep => prevActiveStep - 1)}
              className={classes.backButton}
            >
              {i18n._('Back')}
            </Button>
          )}
          <Button type="submit" variant="contained" color="primary">
            {hasNextStep() ? i18n._('Next') : i18n._('Subscribe')}
          </Button>
        </div>
      </form>
    </Container>
  );
}
