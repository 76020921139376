/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import MenuSchedulingProjection from './MenuSchedulingProjection';


class GetMenuSchedulingsResponse {
    
    constructor() { 
        
        GetMenuSchedulingsResponse.initialize(this);
    }

    
    static initialize(obj) { 
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new GetMenuSchedulingsResponse();

            if (data.hasOwnProperty('schedulings')) {
                obj['schedulings'] = ApiClient.convertToType(data['schedulings'], [MenuSchedulingProjection]);
            }
        }
        return obj;
    }

    
    static validateJSON(data) {
        if (data['schedulings']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['schedulings'])) {
                throw new Error("Expected the field `schedulings` to be an array in the JSON data but got " + data['schedulings']);
            }
            // validate the optional field `schedulings` (array)
            for (const item of data['schedulings']) {
                MenuSchedulingProjection.validateJsonObject(item);
            };
        }

        return true;
    }


}




GetMenuSchedulingsResponse.prototype['schedulings'] = undefined;






export default GetMenuSchedulingsResponse;

