/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class MenuListItemProjection {
    
    constructor() { 
        
        MenuListItemProjection.initialize(this);
    }

    
    static initialize(obj) { 
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MenuListItemProjection();

            if (data.hasOwnProperty('adminDisplayName')) {
                obj['adminDisplayName'] = ApiClient.convertToType(data['adminDisplayName'], 'String');
            }
            if (data.hasOwnProperty('hasBanner')) {
                obj['hasBanner'] = ApiClient.convertToType(data['hasBanner'], 'Boolean');
            }
            if (data.hasOwnProperty('hasSchedulings')) {
                obj['hasSchedulings'] = ApiClient.convertToType(data['hasSchedulings'], 'Boolean');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('position')) {
                obj['position'] = ApiClient.convertToType(data['position'], 'Number');
            }
        }
        return obj;
    }

    
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['adminDisplayName'] && !(typeof data['adminDisplayName'] === 'string' || data['adminDisplayName'] instanceof String)) {
            throw new Error("Expected the field `adminDisplayName` to be a primitive type in the JSON string but got " + data['adminDisplayName']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }

        return true;
    }


}




MenuListItemProjection.prototype['adminDisplayName'] = undefined;


MenuListItemProjection.prototype['hasBanner'] = undefined;


MenuListItemProjection.prototype['hasSchedulings'] = undefined;


MenuListItemProjection.prototype['id'] = undefined;


MenuListItemProjection.prototype['name'] = undefined;


MenuListItemProjection.prototype['position'] = undefined;






export default MenuListItemProjection;

