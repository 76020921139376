import React, { useState } from 'react';
import { Box, Button, Card, Container, Grid, Stack } from '@mui/material';
import { Spinner } from '@common/Spinner';
import { useLingui } from '@lingui/react';
import MenuSchedulingCard from '../../components/menu/menu-scheduling-card';
import AddIcon from '@material-ui/icons/Add';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { menuSchedulingApi, tableZoneApi } from '@services/api';
import { useHistory, useParams } from 'react-router-dom';
import TopbarPageLayout from 'layout/TopbarPageLayout';
import { fillRoute, routes } from '@services/routes';

export default function MenuSchedulingPage(props) {
  const queryClient = useQueryClient();
  const history = useHistory();
  const [schedulings, setSchedulings] = useState([]);
  const { menuId, clubId } = useParams();
  const { i18n } = useLingui();
  const { isLoading: isLoadingMenuScheduling } = useQuery(
    [`getMenuSchedulings_${clubId}_${menuId}`],
    async () => {
      const response = await menuSchedulingApi.getMenuSchedulings(clubId, menuId);
      response.schedulings.forEach(s => {
        s.tableZoneId = s.tableZone.id;
      });
      setSchedulings(response.schedulings);
      return response;
    },
  );

  const { data: tableZoneData, isLoading: isLoadingTableZone } = useQuery(
    [`getClubTableZoneList`],
    () => tableZoneApi.getClubTableZoneList(clubId),
  );

  const mutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries([
        `getMenuSchedulings_${clubId}_${menuId}`,
        ['getClubMenuList', clubId],
      ]);
      history.push(fillRoute(routes.menuList, { clubId }));
    },
  };

  const mutation = useMutation(
    () => menuSchedulingApi.batchCreateOrUpdateMenuScheduling(clubId, menuId, { schedulings }),
    mutationOptions,
  );

  const deleteMutation = useMutation(
    schedulingId => menuSchedulingApi.deleteScheduling(clubId, menuId, schedulingId),
    mutationOptions,
  );

  // TODO: ogni locale deve avere una zona di default, si risolverà con la registrazione locale
  const addNewScheduling = () => {
    if (tableZoneData?.tableZones) {
      const newScheduling = {
        tableZoneId: tableZoneData.tableZones[0].id,
        startTime: '00:00',
        endTime: '00:00',
        days: [],
      };

      setSchedulings([...schedulings, newScheduling]);
    }
  };

  const deleteScheduling = index => {
    const [toDelete] = schedulings.splice(index, 1);
    if (toDelete.id !== undefined) {
      deleteMutation.mutate(toDelete.id);
    }
    setSchedulings([...schedulings]);
  };

  if (
    isLoadingTableZone ||
    isLoadingMenuScheduling ||
    mutation.isLoading ||
    deleteMutation.isLoading
  ) {
    return <Spinner />;
  }
  return (
    <TopbarPageLayout title={i18n._('Schedulings')} onBack={fillRoute(routes.menuList, { clubId })}>
      <Container
        sx={{
          py: { xs: 4, md: 8 },
          px: { xs: 4, md: 16 },
          '& .MuiTextField-root': {
            bgcolor: 'background.paper',
          },
          mb: 20,
        }}
      >
        <Grid container spacing={1.5} justifyContent="center">
          {schedulings.map((scheduling, index) => (
            <Grid item key={index} xs={12} md={8}>
              <MenuSchedulingCard
                scheduling={scheduling}
                zones={tableZoneData.tableZones}
                cardIndex={index + 1}
                onDelete={() => deleteScheduling(index)}
              />
            </Grid>
          ))}
          <Grid item key={'addNewScheduling'} xs={12} md={8}>
            <Button
              startIcon={<AddIcon />}
              variant="outlined"
              color="primary"
              fullWidth
              onClick={addNewScheduling}
              sx={{
                borderRadius: '25px',
                px: 4,
                py: 1.5,
              }}
            >
              {i18n._('Add scheduling')}
            </Button>
          </Grid>
        </Grid>

        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            p: 3,
            pt: 2,
            display: 'flex',
            justifyContent: 'space-around',
            bgcolor: '#f5f5f5',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={mutation.mutate}
            sx={{
              borderRadius: '25px',
              px: 8,
              py: 1.5,
              bgcolor: '#397FF7',
            }}
          >
            {i18n._('Save')}
          </Button>
        </Box>
      </Container>
    </TopbarPageLayout>
  );
}
