import React, { useState } from 'react';
import { useLingui } from '@lingui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Button, Container, Stack, TextField, Typography } from '@mui/material';

import { Spinner } from '@common/Spinner';
import { menuApi } from '@services/api';
import TopbarPageLayout from 'layout/TopbarPageLayout';
import { fillRoute, routes } from '@services/routes';

export function EditMenuPage(props) {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { clubId, menuId } = useParams();
  const [name, setName] = useState('');
  const [adminDisplayName, setAdminDisplayName] = useState('');
  const [footer, setFooter] = useState('');
  const { i18n } = useLingui();
  const { isInitialLoading, data } = useQuery(
    [`getMenu_${clubId}_${menuId}`],
    async () => {
      const response = await menuApi.getMenu(clubId, menuId);
      setName(response.name ?? '');
      setAdminDisplayName(response.adminDisplayName ?? '');
      setFooter(response.footer ?? '');
      return response;
    },
    {
      enabled: menuId != null,
    },
  );
  const updateMenuMutation = useMutation(menu => menuApi.createOrUpdateMenu(clubId, menu), {
    onSuccess: async () => {
      await queryClient.invalidateQueries([
        ['getClubMenuList', clubId],
        `getMenu_${clubId}_${menuId}`,
      ]);
      history.replace(fillRoute(routes.menuList, { clubId }));
    },
  });

  const onFormSubmit = e => {
    e.preventDefault();
    updateMenuMutation.mutate({
      id: data?.id,
      name,
      adminDisplayName: adminDisplayName || name,
      footer,
      position: data?.position ?? 0,
    });
  };

  if (isInitialLoading || updateMenuMutation.isLoading) {
    return <Spinner />;
  }

  return (
    <TopbarPageLayout
      title={data?.adminDisplayName ?? data?.name ?? i18n._('NEW MENU')}
      onBack={fillRoute(routes.menuList, { clubId })}
    >
      <Container
        sx={{
          py: { xs: 6, md: 8 },
          px: { xs: 4, md: 16 },
          '& .MuiTextField-root': {
            bgcolor: 'background.paper',
          },
        }}
      >
        <form onSubmit={onFormSubmit}>
          <Stack spacing={2} sx={{ mb: 5 }}>
            <Typography>{i18n._('Name of the menu')}</Typography>
            <TextField
              label={i18n._('Name displayed by customers')}
              value={name}
              onChange={({ target }) => setName(target.value)}
              margin="normal"
              variant="outlined"
              required
              inputProps={{ maxLength: 100, pattern: '.*\\S+.*' }}
            />
            <TextField
              label={i18n._('Hidden note')}
              value={adminDisplayName}
              onChange={({ target }) => setAdminDisplayName(target.value)}
              margin="normal"
              variant="outlined"
              inputProps={{ maxLength: 100, pattern: '.*\\S+.*' }}
            />
            <Typography variant="subtitle2">
              {i18n._(
                'The hidden note is only displayed in the management system and not by customers who open the menu.',
              )}
            </Typography>
            <Typography variant="subtitle2">
              {i18n._("It's helpful to differentiate menus with the same name.")}
            </Typography>
          </Stack>
          <Stack spacing={2} sx={{ mb: 5 }}>
            <Typography>{i18n._('Insert footer')}</Typography>
            <TextField
              label={i18n._('Footer')}
              value={footer}
              onChange={({ target }) => setFooter(target.value)}
              margin="normal"
              variant="outlined"
              inputProps={{ maxLength: 400 }}
              multiline
            />
          </Stack>
          <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              p: 3,
              pt: 2,
              display: 'flex',
              justifyContent: 'space-around',
              bgcolor: '#f5f5f5',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{
                borderRadius: '25px',
                px: 8,
                py: 1.5,
                bgcolor: '#397FF7',
              }}
            >
              {i18n._('Save Menu')}
            </Button>
          </Box>
        </form>
      </Container>
    </TopbarPageLayout>
  );
}
