/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import MenuDescription from './MenuDescription';
import MenuTheme from './MenuTheme';


class GetTableMenuResponse {
    
    constructor() { 
        
        GetTableMenuResponse.initialize(this);
    }

    
    static initialize(obj) { 
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new GetTableMenuResponse();

            if (data.hasOwnProperty('clubAddress')) {
                obj['clubAddress'] = ApiClient.convertToType(data['clubAddress'], 'String');
            }
            if (data.hasOwnProperty('clubDisplayEmail')) {
                obj['clubDisplayEmail'] = ApiClient.convertToType(data['clubDisplayEmail'], 'String');
            }
            if (data.hasOwnProperty('clubDisplayPhone')) {
                obj['clubDisplayPhone'] = ApiClient.convertToType(data['clubDisplayPhone'], 'String');
            }
            if (data.hasOwnProperty('clubFacebook')) {
                obj['clubFacebook'] = ApiClient.convertToType(data['clubFacebook'], 'String');
            }
            if (data.hasOwnProperty('clubInstagram')) {
                obj['clubInstagram'] = ApiClient.convertToType(data['clubInstagram'], 'String');
            }
            if (data.hasOwnProperty('clubWebsite')) {
                obj['clubWebsite'] = ApiClient.convertToType(data['clubWebsite'], 'String');
            }
            if (data.hasOwnProperty('clubWhatsapp')) {
                obj['clubWhatsapp'] = ApiClient.convertToType(data['clubWhatsapp'], 'String');
            }
            if (data.hasOwnProperty('logo')) {
                obj['logo'] = ApiClient.convertToType(data['logo'], 'String');
            }
            if (data.hasOwnProperty('menu')) {
                obj['menu'] = ApiClient.convertToType(data['menu'], [MenuDescription]);
            }
            if (data.hasOwnProperty('menuFooter')) {
                obj['menuFooter'] = ApiClient.convertToType(data['menuFooter'], 'String');
            }
            if (data.hasOwnProperty('refreshTime')) {
                obj['refreshTime'] = ApiClient.convertToType(data['refreshTime'], 'Number');
            }
            if (data.hasOwnProperty('theme')) {
                obj['theme'] = MenuTheme.constructFromObject(data['theme']);
            }
            if (data.hasOwnProperty('translationsEnabled')) {
                obj['translationsEnabled'] = ApiClient.convertToType(data['translationsEnabled'], 'Boolean');
            }
        }
        return obj;
    }

    
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['clubAddress'] && !(typeof data['clubAddress'] === 'string' || data['clubAddress'] instanceof String)) {
            throw new Error("Expected the field `clubAddress` to be a primitive type in the JSON string but got " + data['clubAddress']);
        }
        // ensure the json data is a string
        if (data['clubDisplayEmail'] && !(typeof data['clubDisplayEmail'] === 'string' || data['clubDisplayEmail'] instanceof String)) {
            throw new Error("Expected the field `clubDisplayEmail` to be a primitive type in the JSON string but got " + data['clubDisplayEmail']);
        }
        // ensure the json data is a string
        if (data['clubDisplayPhone'] && !(typeof data['clubDisplayPhone'] === 'string' || data['clubDisplayPhone'] instanceof String)) {
            throw new Error("Expected the field `clubDisplayPhone` to be a primitive type in the JSON string but got " + data['clubDisplayPhone']);
        }
        // ensure the json data is a string
        if (data['clubFacebook'] && !(typeof data['clubFacebook'] === 'string' || data['clubFacebook'] instanceof String)) {
            throw new Error("Expected the field `clubFacebook` to be a primitive type in the JSON string but got " + data['clubFacebook']);
        }
        // ensure the json data is a string
        if (data['clubInstagram'] && !(typeof data['clubInstagram'] === 'string' || data['clubInstagram'] instanceof String)) {
            throw new Error("Expected the field `clubInstagram` to be a primitive type in the JSON string but got " + data['clubInstagram']);
        }
        // ensure the json data is a string
        if (data['clubWebsite'] && !(typeof data['clubWebsite'] === 'string' || data['clubWebsite'] instanceof String)) {
            throw new Error("Expected the field `clubWebsite` to be a primitive type in the JSON string but got " + data['clubWebsite']);
        }
        // ensure the json data is a string
        if (data['clubWhatsapp'] && !(typeof data['clubWhatsapp'] === 'string' || data['clubWhatsapp'] instanceof String)) {
            throw new Error("Expected the field `clubWhatsapp` to be a primitive type in the JSON string but got " + data['clubWhatsapp']);
        }
        // ensure the json data is a string
        if (data['logo'] && !(typeof data['logo'] === 'string' || data['logo'] instanceof String)) {
            throw new Error("Expected the field `logo` to be a primitive type in the JSON string but got " + data['logo']);
        }
        if (data['menu']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['menu'])) {
                throw new Error("Expected the field `menu` to be an array in the JSON data but got " + data['menu']);
            }
            // validate the optional field `menu` (array)
            for (const item of data['menu']) {
                MenuDescription.validateJsonObject(item);
            };
        }
        // ensure the json data is a string
        if (data['menuFooter'] && !(typeof data['menuFooter'] === 'string' || data['menuFooter'] instanceof String)) {
            throw new Error("Expected the field `menuFooter` to be a primitive type in the JSON string but got " + data['menuFooter']);
        }
        // validate the optional field `theme`
        if (data['theme']) { // data not null
          MenuTheme.validateJSON(data['theme']);
        }

        return true;
    }


}




GetTableMenuResponse.prototype['clubAddress'] = undefined;


GetTableMenuResponse.prototype['clubDisplayEmail'] = undefined;


GetTableMenuResponse.prototype['clubDisplayPhone'] = undefined;


GetTableMenuResponse.prototype['clubFacebook'] = undefined;


GetTableMenuResponse.prototype['clubInstagram'] = undefined;


GetTableMenuResponse.prototype['clubWebsite'] = undefined;


GetTableMenuResponse.prototype['clubWhatsapp'] = undefined;


GetTableMenuResponse.prototype['logo'] = undefined;


GetTableMenuResponse.prototype['menu'] = undefined;


GetTableMenuResponse.prototype['menuFooter'] = undefined;


GetTableMenuResponse.prototype['refreshTime'] = undefined;


GetTableMenuResponse.prototype['theme'] = undefined;


GetTableMenuResponse.prototype['translationsEnabled'] = undefined;






export default GetTableMenuResponse;

