import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useLingui } from '@lingui/react';
import TopbarPageLayout from 'layout/TopbarPageLayout';
import { useHistory, useParams } from 'react-router-dom';
import { fillRoute, routes } from '@services/routes';
import {
  Box,
  Chip,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Grid,
  Stack,
} from '@mui/material';
import { Button } from '@material-ui/core';
import { clubApi } from '@services/api';
import { Spinner } from '@common/Spinner';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { addDays, differenceInDays, subDays } from 'date-fns';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
  clubProfileBoard: {
    display: 'grid',
    gridAutoRows: 'min-content',
    gridAutoFlow: 'row',
    gridRowGap: theme.spacing(1),
    padding: theme.spacing(3),
    justifyContent: 'center',
  },
}));

type ClubProfileManagePlanParams = {
  clubId: string;
};

export default function ClubPaymentPlan() {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const history = useHistory();
  const { clubId } = useParams<ClubProfileManagePlanParams>();
  const { i18n } = useLingui();
  const [viewType, setViewType] = useState('default');
  const snackbar = useSnackbar();

  const { isLoading, data } = useQuery(['getClubCurrentPaymentPlan', clubId], () =>
    clubApi.getClubCurrentPaymentPlan(clubId),
  );

  const clubSubscribeMutation = useMutation(
    () =>
      clubApi.subscribeClub(clubId, {
        paymentPlanId: data.paymentPlan?.id,
      }),
    {
      onSuccess: async () => {
        snackbar.enqueueSnackbar(i18n._('Succesfully subscribed!'), {
          variant: 'success',
        });
        await queryClient.invalidateQueries([`getClubsOwned`]);
        history.replace(fillRoute(routes.menuList, { clubId }));
      },
    },
  );

  const mutation = useMutation(() => clubApi.unsubscribeClub(clubId), {
    onSuccess: async () => {
      // Invalidate and refetch
      await queryClient.invalidateQueries([[`getClubCurrentPaymentPlan`, clubId]]);
      history.replace(
        fillRoute(routes.clubProfileList, {
          clubId,
        }),
      );
    },
  });

  useEffect(() => {
    if (data) {
      // offered by AirDrink
      if (data.freeForever) {
        setViewType('freeForever');
      } else {
        // if Brands
        if (data.brand?.name) {
          setViewType('francoli');
        } else {
          // subscription canceled
          if (!data.subscriptionEnabled) {
            if (
              subDays(data.subscriptionExpiration, 7) <= new Date() &&
              data.subscriptionExpiration > new Date()
            ) {
              setViewType('lastWeekToRenew');
            } else {
              setViewType('defaultCanceled');
            }
          } else {
            // payment issue
            if (data.subscriptionExpiration < new Date()) {
              setViewType('paymentIssue');
            } else {
              setViewType('default');
            }
          }
        }
      }
    }
  }, [data]);

  const renderContent = () => {
    const commonContent = (
      <List>
        {[
          i18n._('Edit your menus whenever you want'),
          i18n._('Real-time changes'),
          i18n._('Translations into the languages you want'),
        ].map(text => (
          <ListItem key={text} disablePadding>
            <ListItemText secondary={i18n._(text)} />
          </ListItem>
        ))}
      </List>
    );
    const subscriptionDetails = (
      <Grid item xs={12}>
        <Typography id="subscription-months-label" variant="h5" gutterBottom>
          {`${i18n._('Subscription')} (${data.paymentPlan?.months} ${i18n._('months')})`}
        </Typography>
        {commonContent}
      </Grid>
    );
    const foreverDetails = (
      <Grid item xs={12}>
        <Typography id="subscription-months-label" variant="h5" gutterBottom>
          {`${i18n._('Subscription')}`}
        </Typography>
        {commonContent}
      </Grid>
    );

    switch (viewType) {
      case 'freeForever':
        return (
          <>
            <Grid item xs={12} container direction="column" spacing={2}>
              {foreverDetails}

              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  {i18n._('Payment')}
                </Typography>
                <Typography id="subscription-price-label" variant="body1">
                  {i18n._(
                    `The subscription is offered by AirDrink to thank you for your valuable help as an early adopter.`,
                  )}
                </Typography>
              </Grid>
            </Grid>
          </>
        );
      case 'francoli':
        return (
          <>
            <Grid item xs={12} container direction="column" spacing={2}>
              {foreverDetails}

              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  {i18n._('Payment')}
                </Typography>
                <Typography id="subscription-price-label" variant="body1">
                  {i18n._(
                    `The subscription is offered by Fratelli Francoli for the duration of one year.`,
                  )}
                </Typography>
              </Grid>
            </Grid>
          </>
        );
      case 'lastWeekToRenew':
      case 'defaultCanceled':
        return (
          <>
            <Grid item xs={12} container direction="column" spacing={2}>
              {subscriptionDetails}

              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  {i18n._('Payment')}
                </Typography>
                <Typography id="subscription-price-label" variant="body1">
                  {i18n._(`The subscription renewal was cancelled.`)}
                  <br />
                  {i18n._(`If you decide to reactivate it your next invoice will be `) +
                    (data.paymentPlan?.price / 100).toFixed(2) +
                    '€'}
                </Typography>
                <Typography id="subscription-expiration-label" variant="body1">
                  <br />
                  {i18n._(`Your subscription will expire on `) +
                    new Date(data.subscriptionExpiration).toLocaleDateString()}
                  <br />
                  {i18n._(`To reactivate your subscription renewal please press the button below.`)}
                </Typography>
              </Grid>
            </Grid>
          </>
        );
      case 'paymentIssue':
        return (
          <>
            <Grid item xs={12} container direction="column" spacing={2}>
              {subscriptionDetails}

              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  {i18n._('Payment')}
                </Typography>
                <Typography id="subscription-price-label" variant="body1">
                  {i18n._(`Your invoice to be paid is `) +
                    (data.paymentPlan?.price / 100).toFixed(2) +
                    '€'}
                </Typography>
                <Typography id="subscription-expiration-label" variant="body1">
                  {i18n._(
                    'Please enter a new payment method to continue using the page of your club.',
                  )}
                </Typography>
              </Grid>
            </Grid>
          </>
        );
      default:
        return (
          <>
            <Grid item xs={12} container direction="column" spacing={2}>
              {subscriptionDetails}

              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  {i18n._('Payment')}
                </Typography>
                <Typography id="subscription-price-label" variant="body1">
                  {i18n._(`Your next invoice will be `) +
                    (data.paymentPlan?.price / 100).toFixed(2) +
                    '€'}
                </Typography>
                <Typography id="subscription-expiration-label" variant="body1">
                  {i18n._(`Your subscription will renew automatically on `) +
                    new Date(data.subscriptionExpiration).toLocaleDateString()}
                </Typography>
              </Grid>
            </Grid>
          </>
        );
    }
  };

  const renderWarnings = () => {
    const warningTitles: Record<string, string> = {
      balance_insufficient: i18n._('Balance insufficient'),
      // Add new codes here
      // Codes list: https://docs.stripe.com/error-codes.
    };

    const warningMessages: Record<string, string> = {
      balance_insufficient: i18n._(
        'The payout could not be completed because the associated account does not have a sufficient balance available.',
      ),
      // Add new codes here
    };

    const errorCode = data.paymentErrorCode;

    if (errorCode) {
      return (
        <Stack spacing={1} sx={{ my: 2 }}>
          <Chip label={warningTitles[errorCode]} sx={{ bgcolor: '#D32F2F', color: '#ffffff' }} />
          <Typography id="subscription-price-label" variant="body1">
            {warningMessages[errorCode]}
          </Typography>
        </Stack>
      );
    }
    return null;
  };

  const renderButtons = () => {
    let daysBeforeDeactivation;
    const cancelSubscriptionButton = (
      <Button
        id="subscription-unsubscribe-button"
        variant="outlined"
        color="inherit"
        onClick={() => mutation.mutate()}
        fullWidth
      >
        {i18n._('Cancel the subscription')}
      </Button>
    );
    const renewSubscriptionButton = (
      <Button
        id="subscription-unsubscribe-button"
        variant="contained"
        color="primary"
        onClick={() => clubSubscribeMutation.mutate()}
        fullWidth
      >
        {i18n._('Renew the subscription')}
      </Button>
    );

    switch (viewType) {
      case 'freeForever':
      case 'francoli':
        return (
          <Box sx={{ maxWidth: 600 }}>
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={12} sm={12}>
                {cancelSubscriptionButton}
              </Grid>
            </Grid>
          </Box>
        );
      case 'lastWeekToRenew':
        daysBeforeDeactivation = differenceInDays(data.subscriptionExpiration, new Date());

        return (
          <Box sx={{ maxWidth: 600 }}>
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={12} sm={12}>
                <Chip
                  label={`${daysBeforeDeactivation} ${i18n._('days to the deadline')}`}
                  color="secondary"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                {renewSubscriptionButton}
              </Grid>
            </Grid>
          </Box>
        );
      case 'defaultCanceled':
        return (
          <Box sx={{ maxWidth: 600 }}>
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={12} sm={12}>
                {renewSubscriptionButton}
              </Grid>
            </Grid>
          </Box>
        );
      case 'paymentIssue':
        daysBeforeDeactivation = differenceInDays(
          addDays(data.subscriptionExpiration, 7),
          new Date(),
        );

        return (
          <Box sx={{ maxWidth: 600 }}>
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={12} sm={12}>
                <Chip
                  label={`${daysBeforeDeactivation} ${i18n._(
                    'days before the club is deactivated',
                  )}`}
                  color="secondary"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                {cancelSubscriptionButton}
              </Grid>
            </Grid>
          </Box>
        );
      default:
        return (
          <Box sx={{ maxWidth: 600 }}>
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={12} sm={12}>
                {cancelSubscriptionButton}
              </Grid>
            </Grid>
          </Box>
        );
    }
  };

  if (isLoading || mutation.isLoading || clubSubscribeMutation.isLoading) {
    return <Spinner />;
  }
  // TODO: cosa mostriamo se:
  // - il locale non si è disiscritto ed è scaduto, quindi ci sono potenziali problemi di pagamento (data.subscriptionEnabled = true && data.subscriptionExpiration < new Date()).
  //      Da capire se disabilitare anche a loro il locale dopo il periodo di grazia o se meglio contattarli direttamente
  // - il locale si è disiscritto ed è nel periodo di grazia, quindi se non si muovono a pagare gli disabilitiamo il locale tra 7 giorni (data.subscriptionEnabled = false && data.subscriptionExpiration + 7 < new Date())
  // - il locale si è disiscritto e l'abbonamento è scaduto, quindi il locale è disabilitato (data.subscriptionEnabled = false && data.subscriptionExpiration + 7 > new Date())
  // - il locale si è disiscritto ma l'abbonamento non è scaduto (c'è una data di scadenza ma non verrà rinnovato dopo)
  // - il locale è uno degli early adopter ed è gratis per sempre
  // forse nei primi 2 casi sarebbe ideale mostrare i componenti per comprare un nuovo abbonamento?

  return (
    <TopbarPageLayout
      title={i18n._('Manage your plan')}
      onBack={fillRoute(routes.clubProfileList, {
        clubId,
      })}
    >
      <div className={classes.clubProfileBoard}>
        <Paper
          elevation={3}
          sx={{
            padding: 2,
            maxWidth: 600,
            margin: 'auto',
          }}
        >
          {renderContent()}
        </Paper>

        {data.paymentPending ? (
          <Chip
            label={i18n._('Payment pending approval')}
            sx={{ bgcolor: '#4682B4', color: '#ffffff', my: 1 }}
          />
        ) : (
          renderWarnings()
        )}

        {renderButtons()}
      </div>
    </TopbarPageLayout>
  );
}
