import { i18n } from '@lingui/core';
import { logout } from '@services/api';
import { routes } from '@services/routes';
import { useSnackbar } from 'notistack';
import React, { Component } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

// As January 2023 an error boundary can only be a class
class ErrorBoundaryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { error: '' };
  }

  componentDidCatch(error, info) {
    this.setState({ error });
  }

  render() {
    if (this.state.error) {
      console.error(this.state.error);
      if (this.state.error.status === 401) {
        // Invalid login
        logout();
        this.props.snackbar.enqueueSnackbar(
          i18n._('Your session has expired, please log in again'),
          { variant: 'warning' },
        );
      } else {
        this.props.snackbar.enqueueSnackbar(i18n._('An error occurred'));
      }
      return <Redirect to={routes.login} />;
    }
    return this.props.children;
  }
}

function errorBoundaryHOC(Component) {
  return props => {
    const history = useHistory();
    const snackbar = useSnackbar();
    return <Component history={history} snackbar={snackbar} {...props} />;
  };
}

export default errorBoundaryHOC(ErrorBoundaryComponent);
