import React, { useCallback } from 'react';
import { Box, Card, Chip, Divider, Stack, Typography } from '@mui/material';
import ScheduleIcon from '@mui/icons-material/Schedule';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { useLingui } from '@lingui/react';
import { CommonActionMenu } from 'components/menu/common-actions-menu';

export const MenuInfo = props => {
  const {
    menu,
    onView,
    onDuplicate,
    onEdit,
    onScheduling,
    onAddPromotion,
    onShowPromotion,
    onPdf,
    onDelete,
    pdfEnabled,
    bannerEnabled,
  } = props;
  const { i18n } = useLingui();

  const handleView = useCallback(() => {
    onView(props.menu);
  }, [onView, props.menu]);

  const handleEdit = useCallback(() => {
    onEdit(props.menu);
  }, [onEdit, props.menu]);

  const handleScheduling = useCallback(() => {
    onScheduling(props.menu);
  }, [onScheduling, props.menu]);

  const handleAddPromotion = useCallback(() => {
    onAddPromotion(props.menu);
  }, [onAddPromotion, props.menu]);

  const handleShowPromotion = useCallback(() => {
    onShowPromotion(props.menu);
  }, [onShowPromotion, props.menu]);

  const handleDelete = useCallback(() => {
    onDelete(props.menu);
  }, [onDelete, props.menu]);

  const handleDuplicate = useCallback(() => {
    onDuplicate(props.menu);
  }, [onDuplicate, props.menu]);

  const handlePDF = useCallback(() => {
    onPdf(props.menu);
  }, [onPdf, props.menu]);

  const handleClickChip = (event, action) => {
    event.stopPropagation();
    action();
  };

  return (
    <>
      {menu && (
        <Card
          onClick={() => handleView(menu)}
          spacing={2}
          sx={{
            px: 3,
            py: 2,
            borderRadius: 2,
          }}
        >
          <Stack direction="row" alignItems="center" sx={{ typography: 'subtitle1', mr: -1 }}>
            <Stack direction="row" alignItems="center" flexGrow={1}>
              <Box component="span">{menu.name}</Box>
            </Stack>

            <CommonActionMenu
              onClickEdit={() => handleEdit(menu)}
              editButtonName={i18n._('Edit')}
              onClickDuplicate={() => handleDuplicate(menu)}
              onClickPdf={() => handlePDF(menu)}
              onClickDelete={() => handleDelete(menu)}
              pdfEnabled={pdfEnabled}
            />
          </Stack>
          <Stack sx={{ mb: 0.5 }}>
            <Typography variant="caption" sx={{ color: 'text.disabled' }}>
              {i18n._('Hidden note')}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {menu.adminDisplayName}
            </Typography>
          </Stack>

          <Divider />

          <Stack
            direction="row"
            alignItems="center"
            useFlexGap
            flexWrap="wrap"
            spacing={1}
            sx={{ mt: 0.5, p: 0.5 }}
          >
            <Chip
              label={i18n._('Schedule')}
              clickable
              color={menu?.hasSchedulings ? 'primary' : 'default'}
              icon={<ScheduleIcon />}
              onClick={event => handleClickChip(event, () => handleScheduling(menu))}
              sx={{ opacity: menu?.hasSchedulings ? 1 : 0.5 }}
            />
            {bannerEnabled && (
              <Chip
                label={i18n._('Promote')}
                clickable
                color={menu?.hasBanner ? 'primary' : 'default'}
                icon={<AddAPhotoIcon fontSize="small" />}
                onClick={event =>
                  handleClickChip(event, () =>
                    menu?.hasBanner ? handleShowPromotion(menu) : handleAddPromotion(menu),
                  )
                }
                sx={{ opacity: menu?.hasBanner ? 1 : 0.5 }}
              />
            )}
          </Stack>
        </Card>
      )}
    </>
  );
};
