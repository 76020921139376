/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class MenuTranslation {
    
    constructor() { 
        
        MenuTranslation.initialize(this);
    }

    
    static initialize(obj) { 
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MenuTranslation();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('language')) {
                obj['language'] = ApiClient.convertToType(data['language'], 'String');
            }
            if (data.hasOwnProperty('originalName')) {
                obj['originalName'] = ApiClient.convertToType(data['originalName'], 'String');
            }
            if (data.hasOwnProperty('translatedName')) {
                obj['translatedName'] = ApiClient.convertToType(data['translatedName'], 'String');
            }
        }
        return obj;
    }

    
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['language'] && !(typeof data['language'] === 'string' || data['language'] instanceof String)) {
            throw new Error("Expected the field `language` to be a primitive type in the JSON string but got " + data['language']);
        }
        // ensure the json data is a string
        if (data['originalName'] && !(typeof data['originalName'] === 'string' || data['originalName'] instanceof String)) {
            throw new Error("Expected the field `originalName` to be a primitive type in the JSON string but got " + data['originalName']);
        }
        // ensure the json data is a string
        if (data['translatedName'] && !(typeof data['translatedName'] === 'string' || data['translatedName'] instanceof String)) {
            throw new Error("Expected the field `translatedName` to be a primitive type in the JSON string but got " + data['translatedName']);
        }

        return true;
    }


}




MenuTranslation.prototype['id'] = undefined;


MenuTranslation.prototype['language'] = undefined;


MenuTranslation.prototype['originalName'] = undefined;


MenuTranslation.prototype['translatedName'] = undefined;






export default MenuTranslation;

