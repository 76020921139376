import React, { useRef, useState } from 'react';
import { Box, Button, Container, MenuItem, Stack, TextField } from '@mui/material';
import _ from 'lodash';
import { Spinner } from '@common/Spinner';
import { useLingui } from '@lingui/react';
import { useQuery } from '@tanstack/react-query';
import TopbarPageLayout from 'layout/TopbarPageLayout';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useParams } from 'react-router-dom';
import { fillRoute, routes } from '@services/routes';
import QRCode from 'react-qr-code';
import { tableApi, tableZoneApi } from '@services/api';
import IosShareIcon from '@mui/icons-material/IosShare';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Buffer } from 'buffer';

export const ClubQrCode = props => {
  const { clubId } = useParams();
  const qrCodeRef = useRef();
  const [zoneId, setZoneId] = useState();
  const [filteredTablesData, setfilteredTablesData] = useState();
  const { i18n } = useLingui();
  const { data: tableZoneData, isLoading: isLoadingTableZone } = useQuery(
    [`getClubTableZoneList`],
    async () => {
      const response = await tableZoneApi.getClubTableZoneList(clubId);
      setZoneId(response.tableZones[0].id ?? null);

      return response;
    },
  );

  // TODO: This is used because the menu is tied to a single table instead of a tablezone.
  // We should either move the menu to a tablezone or create a better service
  const { isLoading: isLoadingTables } = useQuery([`getClubTableList_${clubId}`], async () => {
    const response = await tableApi.getClubTableList(clubId);
    setfilteredTablesData(
      _.groupBy(response.tables, function(table) {
        return table.tableZoneId;
      }),
    );

    return response;
  });

  const onZoneChange = ({ target }) => {
    setZoneId(target.value);
  };

  const onImageDownload = () => {
    const svgData = new XMLSerializer().serializeToString(qrCodeRef.current);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = 'QRCode';
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${Buffer.from(svgData).toString('Base64')}`;
  };

  if (isLoadingTables || isLoadingTableZone) {
    return <Spinner />;
  }

  const menuUrl =
    import.meta.env.VITE_APP_URL +
    fillRoute(routes.qrMenuList, {
      clubId,
      tableId: filteredTablesData?.[zoneId]?.[0]?.id,
    });

  const shareLink = async () => {
    if (navigator.share) {
      await navigator.share({
        title: i18n._('Share your menu'),
        text: i18n._('Take a look at our menu on AirDrink: '),
        url: menuUrl,
      });
    }
  };

  function openExternalLink() {
    window.open(`${menuUrl}`, '_blank');
  }

  return (
    <TopbarPageLayout title={i18n._('Your QR Code')}>
      <Container
        sx={{
          py: { xs: 6, md: 8 },
          px: { xs: 4, md: 16 },
          '& .MuiTextField-root': {
            bgcolor: 'background.paper',
          },
        }}
      >
        <Stack spacing={4} sx={{ mb: 5 }}>
          {tableZoneData.tableZones && tableZoneData.tableZones.length > 1 && (
            <TextField
              select
              fullWidth
              name="zoneId"
              label={i18n._('Zone')}
              variant="outlined"
              onChange={onZoneChange}
              value={zoneId}
            >
              {tableZoneData.tableZones.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          )}
          <Box display="flex" flexDirection="column" width="100%" alignItems="center">
            <QRCode ref={qrCodeRef} value={menuUrl} />
          </Box>
          <Stack spacing={2}>
            {navigator.share && (
              <Button
                color="primary"
                variant="outlined"
                startIcon={<IosShareIcon />}
                onClick={shareLink}
                sx={{
                  borderRadius: '25px',
                  px: 8,
                  py: 1.5,
                }}
              >
                {i18n._('Share menu')}
              </Button>
            )}
            <Button
              color="primary"
              variant="outlined"
              startIcon={<OpenInNewIcon />}
              aria-label="site"
              onClick={openExternalLink}
              sx={{
                borderRadius: '25px',
                px: 8,
                py: 1.5,
              }}
            >
              {i18n._('Show your menu')}
            </Button>
          </Stack>
        </Stack>
      </Container>

      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          p: 3,
          pt: 2,
          display: 'flex',
          justifyContent: 'space-around',
          bgcolor: '#f5f5f5',
        }}
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<GetAppIcon />}
          onClick={onImageDownload}
          sx={{
            borderRadius: '25px',
            px: 8,
            py: 1.5,
            bgcolor: '#397FF7',
          }}
        >
          {i18n._('Download')}
        </Button>
      </Box>
    </TopbarPageLayout>
  );
};
