/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class CreateClubResponse {
    
    constructor() { 
        
        CreateClubResponse.initialize(this);
    }

    
    static initialize(obj) { 
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CreateClubResponse();

            if (data.hasOwnProperty('clubId')) {
                obj['clubId'] = ApiClient.convertToType(data['clubId'], 'Number');
            }
        }
        return obj;
    }

    
    static validateJSON(data) {

        return true;
    }


}




CreateClubResponse.prototype['clubId'] = undefined;






export default CreateClubResponse;

