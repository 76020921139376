import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import { i18n } from '@lingui/core';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    placeItems: 'center',
  },
  bannerContainer: {
    position: 'relative',
    display: 'grid',
    gridTemplateRows: 'auto auto',
    gridTemplateColumns: 'minmax(min-content, 70vw)',
    gridTemplateAreas: '"header" "message" "footer"',
    gridRowGap: theme.spacing(2),
    alignItems: 'center',
    borderRadius: '12px',
    boxShadow: '0px 2px 5px rgba(255, 255, 255, 0.2)',
    backgroundColor: props => props.theme.background,
    color: props => props.theme.text,
  },
  headerContainer: {
    gridArea: 'header',
    textAlign: 'center',
    display: 'grid',
    placeItems: 'center',
    padding: theme.spacing(2, 2, 0, 2),
    fontWeight: '500',
  },
  messageContainer: {
    gridArea: 'message',
    textAlign: 'center',
    display: 'grid',
    gridTemplateRows: 'auto',
    gridRowGap: theme.spacing(1),
    padding: theme.spacing(0, 2, 0, 2),
  },
  footerContainer: {
    gridArea: 'footer',
    textAlign: 'center',
    display: 'grid',
    placeItems: 'center',
    padding: theme.spacing(0, 2, 0.5, 2),
    borderBottomLeftRadius: '12px',
    borderBottomRightRadius: '12px',
    backgroundColor: 'rgba(158, 158, 158, 0.3)',
  },
  buttonFont: {
    color: props => props.theme.text,
    fontWeight: '500',
  },
}));

export const QrCodeRefreshPopup = props => {
  const classes = useStyles(props);
  const { theme, open, onClose } = props;

  return (
    <Backdrop
      className={classes.backdrop}
      open={open}
      onClick={onClose}
      style={{ color: theme.text }}
    >
      <div className={classes.bannerContainer}>
        <div className={classes.headerContainer}>{i18n._('Whoops! Your session is expired')}</div>
        <div className={classes.messageContainer}>{i18n._('Click to open the page again.')}</div>
        <div className={classes.footerContainer}>
          <Button color="primary" onClick={onClose}>
            <span className={classes.buttonFont}>OK</span>
          </Button>
        </div>
      </div>
    </Backdrop>
  );
};
