import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useLingui } from '@lingui/react';
import ButtonBase from '@material-ui/core/ButtonBase';
import RefreshIcon from '@mui/icons-material/Refresh';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    gridRowGap: theme.spacing(1),
    width: '100vw',
    height: '100vh',
    alignContent: 'center',
    textAlign: 'center',
    backgroundColor: '#202020',
    color: props => (props.colorText ? props.colorText : '#fff'),
  },
  title: {
    fontSize: '32px',
    fontWeight: '600',
  },
  text: {
    opacity: 0.85,
  },
  button: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridColumnGap: theme.spacing(1),
    marginTop: theme.spacing(4),
    fontSize: '16px',
    color: '#29b7d5',
  },
}));

export const GenericError = props => {
  const classes = useStyles(props);

  return (
    <div className={classes.container}>
      <span className={classes.title}>{i18n._('Oops!')}</span>
      <span className={classes.text}>{i18n._('It seems something went wrong.')}</span>
      <ButtonBase className={classes.button}>
        <RefreshIcon />
        <span>{i18n._('Try again')}</span>
      </ButtonBase>
    </div>
  );
};
