/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import BatchCreateOrUpdateMenuSchedulingRequest from '../model/BatchCreateOrUpdateMenuSchedulingRequest';
import GetMenuSchedulingsResponse from '../model/GetMenuSchedulingsResponse';


export default class MenuSchedulingControllerApi {

    
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    batchCreateOrUpdateMenuSchedulingWithHttpInfo(clubId, menuId, request) {
      let postBody = request;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling batchCreateOrUpdateMenuScheduling");
      }
      // verify the required parameter 'menuId' is set
      if (menuId === undefined || menuId === null) {
        throw new Error("Missing the required parameter 'menuId' when calling batchCreateOrUpdateMenuScheduling");
      }
      // verify the required parameter 'request' is set
      if (request === undefined || request === null) {
        throw new Error("Missing the required parameter 'request' when calling batchCreateOrUpdateMenuScheduling");
      }

      let pathParams = {
        'clubId': clubId,
        'menuId': menuId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/clubs/{clubId}/menu/{menuId}/schedulings', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    batchCreateOrUpdateMenuScheduling(clubId, menuId, request) {
      return this.batchCreateOrUpdateMenuSchedulingWithHttpInfo(clubId, menuId, request)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    deleteSchedulingWithHttpInfo(clubId, menuId, schedulingId) {
      let postBody = null;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling deleteScheduling");
      }
      // verify the required parameter 'menuId' is set
      if (menuId === undefined || menuId === null) {
        throw new Error("Missing the required parameter 'menuId' when calling deleteScheduling");
      }
      // verify the required parameter 'schedulingId' is set
      if (schedulingId === undefined || schedulingId === null) {
        throw new Error("Missing the required parameter 'schedulingId' when calling deleteScheduling");
      }

      let pathParams = {
        'clubId': clubId,
        'menuId': menuId,
        'schedulingId': schedulingId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/clubs/{clubId}/menu/{menuId}/schedulings/{schedulingId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    deleteScheduling(clubId, menuId, schedulingId) {
      return this.deleteSchedulingWithHttpInfo(clubId, menuId, schedulingId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    getMenuSchedulingsWithHttpInfo(clubId, menuId) {
      let postBody = null;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling getMenuSchedulings");
      }
      // verify the required parameter 'menuId' is set
      if (menuId === undefined || menuId === null) {
        throw new Error("Missing the required parameter 'menuId' when calling getMenuSchedulings");
      }

      let pathParams = {
        'clubId': clubId,
        'menuId': menuId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = GetMenuSchedulingsResponse;
      return this.apiClient.callApi(
        '/clubs/{clubId}/menu/{menuId}/schedulings', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getMenuSchedulings(clubId, menuId) {
      return this.getMenuSchedulingsWithHttpInfo(clubId, menuId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
