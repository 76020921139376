/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import TableZoneProjection from './TableZoneProjection';


class MenuSchedulingProjection {
    
    constructor() { 
        
        MenuSchedulingProjection.initialize(this);
    }

    
    static initialize(obj) { 
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MenuSchedulingProjection();

            if (data.hasOwnProperty('days')) {
                obj['days'] = ApiClient.convertToType(data['days'], ['Number']);
            }
            if (data.hasOwnProperty('endTime')) {
                obj['endTime'] = ApiClient.convertToType(data['endTime'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('startTime')) {
                obj['startTime'] = ApiClient.convertToType(data['startTime'], 'String');
            }
            if (data.hasOwnProperty('tableZone')) {
                obj['tableZone'] = TableZoneProjection.constructFromObject(data['tableZone']);
            }
        }
        return obj;
    }

    
    static validateJSON(data) {
        // ensure the json data is an array
        if (!Array.isArray(data['days'])) {
            throw new Error("Expected the field `days` to be an array in the JSON data but got " + data['days']);
        }
        // ensure the json data is a string
        if (data['endTime'] && !(typeof data['endTime'] === 'string' || data['endTime'] instanceof String)) {
            throw new Error("Expected the field `endTime` to be a primitive type in the JSON string but got " + data['endTime']);
        }
        // ensure the json data is a string
        if (data['startTime'] && !(typeof data['startTime'] === 'string' || data['startTime'] instanceof String)) {
            throw new Error("Expected the field `startTime` to be a primitive type in the JSON string but got " + data['startTime']);
        }
        // validate the optional field `tableZone`
        if (data['tableZone']) { // data not null
          TableZoneProjection.validateJSON(data['tableZone']);
        }

        return true;
    }


}




MenuSchedulingProjection.prototype['days'] = undefined;


MenuSchedulingProjection.prototype['endTime'] = undefined;


MenuSchedulingProjection.prototype['id'] = undefined;


MenuSchedulingProjection.prototype['startTime'] = undefined;


MenuSchedulingProjection.prototype['tableZone'] = undefined;






export default MenuSchedulingProjection;

