import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Spinner } from '@common/Spinner';
import { GenericError } from '@common/GenericError';
import { QrCodeMenusBody } from './QrCodeMenusBody';
import { QrCodeMenuFooter } from './QrCodeMenuFooter';
import { QrCodeRefreshPopup } from './QrCodeRefreshPopup';
import { useQuery } from '@tanstack/react-query';
import { menuApi } from '@services/api';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import _ from 'lodash';
import { useCallback } from 'react';
import { NotFoundError } from '../../common/NotFoundError';
import { fillRoute, routes } from '@services/routes';
import QrMenuPageLayout from 'layout/qr-menu-page-layout';

const useStyles = makeStyles(theme => ({
  backgroundcontainer: {
    position: 'fixed',
    width: '100%',
    height: '100%',
  },
  qrCodeMenucontainer: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
  },
  logoMenusContainer: {
    textAlign: 'center',
    padding: theme.spacing(3, 0, 1, 0),
    height: theme.spacing(18),
  },
  logoImages: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
    alignSelf: 'center',
  },
}));

export const QrCodeMenus = props => {
  const classes = useStyles(props);
  const [showRefreshPopup, setShowRefreshPopup] = useState(false);
  const [sortedMenuList, setSortedMenuList] = useState(undefined);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const showEmbedded = searchParams.get('embedded') == 'true';

  const params = useParams();
  const history = useHistory();
  const { clubId, tableId } = params;

  const { isLoading, error, data } = useQuery(
    [`getMenusForTable_${clubId}_${tableId}`],
    async () => {
      const data = await menuApi.getMenusForTable(clubId, tableId);

      // Sorts menu by position
      const newSortedMenuList = data?.menu.sort((a, b) => a.position - b.position);

      // TODO: https://developer.mozilla.org/en-US/docs/Web/API/structuredClone or similar?
      // TODO: remove lodash if it's used only for this and replace it with https://github.com/FormidableLabs/react-fast-compare
      // IN THESE CASES WITHOUT MENU ORDER
      if (sortedMenuList !== undefined && !_.isEqual(sortedMenuList, newSortedMenuList)) {
        setShowRefreshPopup(true);
      }

      setSortedMenuList(newSortedMenuList);

      return data;
    },

    {
      refetchInterval: data => data?.refreshTime * 1000 ?? false,
      refetchIntervalInBackground: true,
      onError: null,
    },
  );

  // TODO: remove from the backend the footer field from this response since
  // there's no footer on this page

  const refreshMenu = () => history.go(0);

  const goToMenu = useCallback(
    menuId =>
      history.push(
        fillRoute(routes.qrMenuView, { clubId, menuId, tableId }) + history.location.search,
      ),
    [clubId, tableId, history],
  );

  useEffect(() => {
    // If there's only one menu, navigate to that menu
    if (data?.menu.length === 1) {
      goToMenu(data.menu[0].id);
      return;
    }
  }, [data, goToMenu]);

  if (isLoading) {
    return <Spinner colorSpinner={'#fff'} />;
  }

  if (error) {
    if (error.status === 404) {
      return <NotFoundError />;
    }
    return <GenericError colorText={'#fff'} />;
  }

  return (
    <QrMenuPageLayout theme={data.theme} translationsEnabled={data.translationsEnabled}>
      <QrCodeRefreshPopup theme={data.theme} open={showRefreshPopup} onClose={refreshMenu} />

      <div className={classes.qrCodeMenucontainer}>
        {!showEmbedded && (
          <div className={classes.logoMenusContainer}>
            <img onClick={refreshMenu} className={classes.logoImages} src={data.logo} alt="logo" />
          </div>
        )}
        <QrCodeMenusBody
          items={sortedMenuList}
          onClickViewMenu={goToMenu}
          colorTheme={data.theme}
        />
        {!showEmbedded && (
          <QrCodeMenuFooter
            address={data.clubAddress}
            phone={data.clubDisplayPhone}
            email={data.clubDisplayEmail}
            instagram={data.clubInstagram}
            facebook={data.clubFacebook}
            website={data.clubWebsite}
            whatsapp={data.clubWhatsapp}
          />
        )}
      </div>
    </QrMenuPageLayout>
  );
};
