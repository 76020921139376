/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CreateOrUpdateTableRequest from '../model/CreateOrUpdateTableRequest';
import GetClubTableListResponse from '../model/GetClubTableListResponse';
import GetTableResponse from '../model/GetTableResponse';


export default class TableControllerApi {

    
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    createOrUpdateTableWithHttpInfo(clubId, request) {
      let postBody = request;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling createOrUpdateTable");
      }
      // verify the required parameter 'request' is set
      if (request === undefined || request === null) {
        throw new Error("Missing the required parameter 'request' when calling createOrUpdateTable");
      }

      let pathParams = {
        'clubId': clubId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/clubs/{clubId}/tables', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    createOrUpdateTable(clubId, request) {
      return this.createOrUpdateTableWithHttpInfo(clubId, request)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    deleteTableWithHttpInfo(clubId, tableId) {
      let postBody = null;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling deleteTable");
      }
      // verify the required parameter 'tableId' is set
      if (tableId === undefined || tableId === null) {
        throw new Error("Missing the required parameter 'tableId' when calling deleteTable");
      }

      let pathParams = {
        'clubId': clubId,
        'tableId': tableId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/clubs/{clubId}/tables/{tableId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    deleteTable(clubId, tableId) {
      return this.deleteTableWithHttpInfo(clubId, tableId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    getClubTableListWithHttpInfo(clubId) {
      let postBody = null;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling getClubTableList");
      }

      let pathParams = {
        'clubId': clubId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = GetClubTableListResponse;
      return this.apiClient.callApi(
        '/clubs/{clubId}/tables', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getClubTableList(clubId) {
      return this.getClubTableListWithHttpInfo(clubId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    getTableWithHttpInfo(clubId, tableId) {
      let postBody = null;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling getTable");
      }
      // verify the required parameter 'tableId' is set
      if (tableId === undefined || tableId === null) {
        throw new Error("Missing the required parameter 'tableId' when calling getTable");
      }

      let pathParams = {
        'clubId': clubId,
        'tableId': tableId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = GetTableResponse;
      return this.apiClient.callApi(
        '/clubs/{clubId}/tables/{tableId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getTable(clubId, tableId) {
      return this.getTableWithHttpInfo(clubId, tableId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
