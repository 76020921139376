import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { useLingui } from '@lingui/react';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    gridTemplateRows: 'auto min-content',
    gridTemplateAreas: '"header" "footer"',
    padding: '0 !important',
  },
  header: {
    padding: theme.spacing(2),
    gridArea: 'header',
    display: 'grid',
    gridTemplateRows: 'auto auto',
    gridRowGap: theme.spacing(2),
  },
  navigation: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },

  close: {
    maxWidth: '50px',
  },
  messageContainer: {
    textAlign: 'center',
    display: 'grid',
    gridTemplateRows: 'auto auto',
    gridRowGap: theme.spacing(2),
  },
  title: {
    fontWeight: '900',
    fontSize: '18px',
  },
  message: {
    fontWeight: '300',
  },

  footer: {
    gridArea: 'footer',
  },

  dialogActionsButtons: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
}));

export default function ConfirmationModal(props) {
  const classes = useStyles(props);
  const { i18n } = useLingui();
  return (
    <Dialog
      aria-labelledby="dialogTitle"
      open={props.show}
      onClose={props.onClose}
      className={classes.dialog}
    >
      <DialogContent className={classes.container}>
        <div className={classes.header}>
          <div className={classes.messageContainer}>
            <div className={classes.title}>{props.title} </div>
            <div className={classes.message}>{props.message}</div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActionsButtons}>
        {!props.hideCancel && (
          <Button onClick={props.onClose} color="secondary">
            {i18n._('CANCEL')}
          </Button>
        )}
        <Button onClick={() => props.onConfirm()} color="primary">
          {i18n._('CONFIRM')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
