/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class Message {
    
    constructor() { 
        
        Message.initialize(this);
    }

    
    static initialize(obj) { 
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Message();

            if (data.hasOwnProperty('fullMessage')) {
                obj['fullMessage'] = ApiClient.convertToType(data['fullMessage'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('severity')) {
                obj['severity'] = ApiClient.convertToType(data['severity'], 'Number');
            }
            if (data.hasOwnProperty('summary')) {
                obj['summary'] = ApiClient.convertToType(data['summary'], 'String');
            }
        }
        return obj;
    }

    
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['fullMessage'] && !(typeof data['fullMessage'] === 'string' || data['fullMessage'] instanceof String)) {
            throw new Error("Expected the field `fullMessage` to be a primitive type in the JSON string but got " + data['fullMessage']);
        }
        // ensure the json data is a string
        if (data['summary'] && !(typeof data['summary'] === 'string' || data['summary'] instanceof String)) {
            throw new Error("Expected the field `summary` to be a primitive type in the JSON string but got " + data['summary']);
        }

        return true;
    }


}




Message.prototype['fullMessage'] = undefined;


Message.prototype['id'] = undefined;


Message.prototype['severity'] = undefined;


Message.prototype['summary'] = undefined;






export default Message;

