import React from 'react';
import { i18n } from '@lingui/core';
import { makeStyles } from '@material-ui/core/styles';
import { decodeHtml } from '@common/encode-translation-fix';

const useStyles = makeStyles(theme => ({
  tabContainer: {
    display: 'grid',
    gridAutoRows: 'min-content',
    gridAutoFlow: 'row',
    height: 'inherit',
    minHeight: `calc(100vh - ${theme.spacing(18)}px)`,
    gridRowGap: theme.spacing(1),
    padding: theme.spacing(1),
    color: props => props.colorTheme.text,
    paddingBottom: theme.spacing(5),
  },
  itemContainer: {
    display: 'grid',
    gridAutoFlow: 'row',
    textAlign: 'center',
    padding: theme.spacing(1.5, 1),
    borderRadius: 12,
    border: '2px solid ',
    font: props => (props.colorTheme && props.colorTheme.font ? props.colorTheme.font : 'inherit'),
  },
  labelContainer: {
    width: '100%',
    marginTop: theme.spacing(-1.5),
    marginLeft: theme.spacing(-1),
  },
  labelBadge: {
    float: 'left',
    marginTop: '-2px',
    marginLeft: '-2px',
  },
  label: {
    padding: '0 6px 0 10px',
    position: 'relative',
    height: '26px',
    zIndex: 1,
    float: 'left',
    borderTopLeftRadius: 5,
    '&::after': {
      content: `''`,
      width: '10px',
      height: '26px',
      borderRight: '10px solid transparent',
      borderTop: '26px solid',
      mozBoxSizing: 'border-box',
      webkitBoxSizing: 'border-box',
      boxSizing: 'border-box',
      position: 'absolute',
      top: 0,
      right: '-10px',
    },
  },
  labelText: {
    overflow: 'hidden!important',
    lineHeight: '26px',
    height: '26px',
    color: '#fff',
    fontSize: '12px',
  },
  itemName: {
    marginBottom: theme.spacing(1),
  },
  itemDescription: {
    whiteSpace: 'pre-wrap',
    fontSize: '85%',
  },
  itemIngredients: {
    whiteSpace: 'pre-wrap',
    fontSize: '85%',
  },
  itemNotes: {
    fontSize: '60%',
  },
  itemAllergens: {
    marginTop: theme.spacing(1),
    fontSize: '60%',
  },
  itemPrice: {},
  itemDisabled: {},
  bestSellerLabel: {
    backgroundColor: ({ colorTheme }) => colorTheme?.bestSellerLabelColor ?? '#E67A00',
    color: ({ colorTheme }) => colorTheme?.bestSellerLabelColor ?? '#E67A00',
  },
  suggestedLabel: {
    backgroundColor: ({ colorTheme }) => colorTheme?.suggestedLabelColor ?? '#007AE6',
    color: ({ colorTheme }) => colorTheme?.suggestedLabelColor ?? '#007AE6',
  },
}));

// TODO: probabilmente questo componente non serve, scorporare
export const QrCodeTabBody = props => {
  const classes = useStyles(props);
  const { items } = props;

  const userLang = navigator.language || navigator.userLanguage;
  const languageCode = userLang ? userLang.toLowerCase().substring(0, 2) : 'it';
  console.log(`${userLang} ${languageCode}`);

  return (
    <div className={classes.tabContainer}>
      {items &&
        items.map(
          b =>
            !b.disabled && (
              <div
                key={'itemContainer-' + b.id}
                className={classes.itemContainer}
                style={{
                  backgroundColor: b.outOfStock ? 'rgba(85, 85, 85, 0.3)' : 'transparent',
                  borderColor: b.bestSeller || b.suggested ? '#88888850' : 'transparent',
                  backdropFilter: b.bestSeller || b.suggested ? 'blur(2px)' : 'transparent',
                }}
              >
                {(b.bestSeller || b.suggested) && (
                  <div className={classes.labelContainer}>
                    <span className={classes.labelBadge}>
                      <span
                        className={`${classes.label} ${
                          b.bestSeller ? classes.bestSellerLabel : classes.suggestedLabel
                        }`}
                      >
                        <span className={classes.labelText}>
                          {b.bestSeller ? i18n._('Best seller') : i18n._('Suggested')}
                        </span>
                      </span>
                    </span>
                  </div>
                )}
                <span
                  className={classes.itemName}
                  style={{ opacity: b.outOfStock ? 0.5 : 1 }}
                  translate={b.translateName ? 'yes' : 'no'}
                >
                  {languageCode === 'it'
                    ? b.name
                    : b.translateName
                    ? decodeHtml(b.translations?.[languageCode]?.translatedName) ??
                      decodeHtml(b.translations?.['en']?.translatedName) ??
                      b.name
                    : b.name}
                </span>
                {b.description && (
                  <span
                    className={classes.itemDescription}
                    style={{ opacity: b.outOfStock ? 0.5 : 1 }}
                  >
                    {languageCode === 'it'
                      ? b.description
                      : decodeHtml(b.translations?.[languageCode]?.translatedDescription) ??
                        decodeHtml(b.translations?.['en']?.translatedDescription) ??
                        b.description}
                  </span>
                )}
                {b.ingredients && (
                  <span
                    className={classes.itemIngredients}
                    style={{ opacity: b.outOfStock ? 0.5 : 1 }}
                  >
                    {languageCode === 'it'
                      ? b.ingredients
                      : decodeHtml(b.translations?.[languageCode]?.translatedIngredients) ??
                        decodeHtml(b.translations?.['en']?.translatedIngredients) ??
                        b.ingredients}
                  </span>
                )}
                {b.additionalNotes && (
                  <span className={classes.itemNotes} style={{ opacity: b.outOfStock ? 0.5 : 1 }}>
                    {b.additionalNotes}
                  </span>
                )}
                {b.allergens && (
                  <span
                    className={classes.itemAllergens}
                    style={{ opacity: b.outOfStock ? 0.5 : 1 }}
                  >
                    Allergeni: {b.allergens}
                  </span>
                )}
                {(b.priceText || b.price) && (
                  <span className={classes.itemPrice} style={{ opacity: b.outOfStock ? 0.5 : 1 }}>
                    {b.priceText ? b.priceText : `${b.price} €`}
                  </span>
                )}
                {b.outOfStock ? (
                  <span className={classes.itemDisabled}>{i18n._('Out of stock')}</span>
                ) : null}
              </div>
            ),
        )}
    </div>
  );
};
