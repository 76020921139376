/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import ClubNameProjection from '../model/ClubNameProjection';
import ClubSubscriptionProjection from '../model/ClubSubscriptionProjection';
import CreateClubRequest from '../model/CreateClubRequest';
import CreateClubResponse from '../model/CreateClubResponse';
import GetClubResponse from '../model/GetClubResponse';
import PaymentPlan from '../model/PaymentPlan';
import SetupClubPaymentResponse from '../model/SetupClubPaymentResponse';
import SubscribeClubRequest from '../model/SubscribeClubRequest';
import UpdateClubRequest from '../model/UpdateClubRequest';
import UpdateClubSettingsRequest from '../model/UpdateClubSettingsRequest';


export default class ClubControllerApi {

    
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    activateClubWithHttpInfo(token) {
      let postBody = null;
      // verify the required parameter 'token' is set
      if (token === undefined || token === null) {
        throw new Error("Missing the required parameter 'token' when calling activateClub");
      }

      let pathParams = {
      };
      let queryParams = {
        'token': token
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/clubs/activate', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    activateClub(token) {
      return this.activateClubWithHttpInfo(token)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    createClubWithHttpInfo(request, opts) {
      opts = opts || {};
      let postBody = request;
      // verify the required parameter 'request' is set
      if (request === undefined || request === null) {
        throw new Error("Missing the required parameter 'request' when calling createClub");
      }

      let pathParams = {
      };
      let queryParams = {
        'accountNonExpired': opts['accountNonExpired'],
        'accountNonLocked': opts['accountNonLocked'],
        'authorities[0].authority': opts['authorities0Authority'],
        'credentialsNonExpired': opts['credentialsNonExpired'],
        'email': opts['email'],
        'enabled': opts['enabled'],
        'forceLogout': opts['forceLogout'],
        'freeTrialAvailable': opts['freeTrialAvailable'],
        'id': opts['id'],
        'name': opts['name'],
        'password': opts['password'],
        'passwordTwo': opts['passwordTwo'],
        'phone': opts['phone'],
        'surname': opts['surname'],
        'totpSecret': opts['totpSecret'],
        'updateTime': opts['updateTime'],
        'username': opts['username']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = CreateClubResponse;
      return this.apiClient.callApi(
        '/clubs', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    createClub(request, opts) {
      return this.createClubWithHttpInfo(request, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    getClubWithHttpInfo(clubId) {
      let postBody = null;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling getClub");
      }

      let pathParams = {
        'clubId': clubId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = GetClubResponse;
      return this.apiClient.callApi(
        '/clubs/{clubId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getClub(clubId) {
      return this.getClubWithHttpInfo(clubId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    getClubAvailablePaymentPlansWithHttpInfo(clubId) {
      let postBody = null;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling getClubAvailablePaymentPlans");
      }

      let pathParams = {
        'clubId': clubId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [PaymentPlan];
      return this.apiClient.callApi(
        '/clubs/{clubId}/payments/plans', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getClubAvailablePaymentPlans(clubId) {
      return this.getClubAvailablePaymentPlansWithHttpInfo(clubId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    getClubCurrentPaymentPlanWithHttpInfo(clubId) {
      let postBody = null;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling getClubCurrentPaymentPlan");
      }

      let pathParams = {
        'clubId': clubId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ClubSubscriptionProjection;
      return this.apiClient.callApi(
        '/clubs/{clubId}/payments/plans/current', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getClubCurrentPaymentPlan(clubId) {
      return this.getClubCurrentPaymentPlanWithHttpInfo(clubId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    getClubsOwnedWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'authenticated': opts['authenticated'],
        'authorities[0].authority': opts['authorities0Authority'],
        'credentials': opts['credentials'],
        'details': opts['details'],
        'name': opts['name'],
        'principal': opts['principal']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [ClubNameProjection];
      return this.apiClient.callApi(
        '/clubs', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getClubsOwned(opts) {
      return this.getClubsOwnedWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    promoteClubBrandedMenuItemsWithHttpInfo(clubId) {
      let postBody = null;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling promoteClubBrandedMenuItems");
      }

      let pathParams = {
        'clubId': clubId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/clubs/{clubId}/promote', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    promoteClubBrandedMenuItems(clubId) {
      return this.promoteClubBrandedMenuItemsWithHttpInfo(clubId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    setupClubPaymentWithHttpInfo(clubId) {
      let postBody = null;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling setupClubPayment");
      }

      let pathParams = {
        'clubId': clubId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = SetupClubPaymentResponse;
      return this.apiClient.callApi(
        '/clubs/{clubId}/payments/setup', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    setupClubPayment(clubId) {
      return this.setupClubPaymentWithHttpInfo(clubId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    subscribeClubWithHttpInfo(clubId, request) {
      let postBody = request;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling subscribeClub");
      }
      // verify the required parameter 'request' is set
      if (request === undefined || request === null) {
        throw new Error("Missing the required parameter 'request' when calling subscribeClub");
      }

      let pathParams = {
        'clubId': clubId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/clubs/{clubId}/subscription', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    subscribeClub(clubId, request) {
      return this.subscribeClubWithHttpInfo(clubId, request)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    unsubscribeClubWithHttpInfo(clubId) {
      let postBody = null;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling unsubscribeClub");
      }

      let pathParams = {
        'clubId': clubId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/clubs/{clubId}/subscription', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    unsubscribeClub(clubId) {
      return this.unsubscribeClubWithHttpInfo(clubId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    updateClubWithHttpInfo(clubId, request) {
      let postBody = request;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling updateClub");
      }
      // verify the required parameter 'request' is set
      if (request === undefined || request === null) {
        throw new Error("Missing the required parameter 'request' when calling updateClub");
      }

      let pathParams = {
        'clubId': clubId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/clubs/{clubId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    updateClub(clubId, request) {
      return this.updateClubWithHttpInfo(clubId, request)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }
    updateClubSettingsWithHttpInfo(clubId, request) {
      let postBody = request;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling updateClubSettings");
      }
      // verify the required parameter 'request' is set
      if (request === undefined || request === null) {
        throw new Error("Missing the required parameter 'request' when calling updateClubSettings");
      }

      let pathParams = {
        'clubId': clubId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/clubs/{clubId}/settings', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    updateClubSettings(clubId, request) {
      return this.updateClubSettingsWithHttpInfo(clubId, request)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
