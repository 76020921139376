import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useLingui } from '@lingui/react';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    gridRowGap: theme.spacing(1),
    width: '100vw',
    height: '100vh',
    alignContent: 'center',
    textAlign: 'center',
    backgroundColor: '#202020',
    color: props => (props.colorText ? props.colorText : '#fff'),
  },
  title: {
    fontSize: '32px',
    fontWeight: '600',
  },
  text: {
    opacity: 0.85,
  },
}));

export const NotFoundError = props => {
  const classes = useStyles(props);
  const { i18n } = useLingui();
  return (
    <div className={classes.container}>
      <span className={classes.title}>{i18n._('Oops!')}</span>
      <span className={classes.text}>
        {i18n._("We can't seem to find the page you are looking for.")}
      </span>
    </div>
  );
};
