/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class MenuTheme {
    
    constructor() { 
        
        MenuTheme.initialize(this);
    }

    
    static initialize(obj) { 
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MenuTheme();

            if (data.hasOwnProperty('background')) {
                obj['background'] = ApiClient.convertToType(data['background'], 'String');
            }
            if (data.hasOwnProperty('backgroundImage')) {
                obj['backgroundImage'] = ApiClient.convertToType(data['backgroundImage'], 'String');
            }
            if (data.hasOwnProperty('bestSellerLabelColor')) {
                obj['bestSellerLabelColor'] = ApiClient.convertToType(data['bestSellerLabelColor'], 'String');
            }
            if (data.hasOwnProperty('buttonText')) {
                obj['buttonText'] = ApiClient.convertToType(data['buttonText'], 'String');
            }
            if (data.hasOwnProperty('divider')) {
                obj['divider'] = ApiClient.convertToType(data['divider'], 'String');
            }
            if (data.hasOwnProperty('font')) {
                obj['font'] = ApiClient.convertToType(data['font'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('main')) {
                obj['main'] = ApiClient.convertToType(data['main'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('pdfHighlight')) {
                obj['pdfHighlight'] = ApiClient.convertToType(data['pdfHighlight'], 'String');
            }
            if (data.hasOwnProperty('pdfSectionTitle')) {
                obj['pdfSectionTitle'] = ApiClient.convertToType(data['pdfSectionTitle'], 'String');
            }
            if (data.hasOwnProperty('pdfText')) {
                obj['pdfText'] = ApiClient.convertToType(data['pdfText'], 'String');
            }
            if (data.hasOwnProperty('suggestedLabelColor')) {
                obj['suggestedLabelColor'] = ApiClient.convertToType(data['suggestedLabelColor'], 'String');
            }
            if (data.hasOwnProperty('tabBackground')) {
                obj['tabBackground'] = ApiClient.convertToType(data['tabBackground'], 'String');
            }
            if (data.hasOwnProperty('tabText')) {
                obj['tabText'] = ApiClient.convertToType(data['tabText'], 'String');
            }
            if (data.hasOwnProperty('text')) {
                obj['text'] = ApiClient.convertToType(data['text'], 'String');
            }
        }
        return obj;
    }

    
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['background'] && !(typeof data['background'] === 'string' || data['background'] instanceof String)) {
            throw new Error("Expected the field `background` to be a primitive type in the JSON string but got " + data['background']);
        }
        // ensure the json data is a string
        if (data['backgroundImage'] && !(typeof data['backgroundImage'] === 'string' || data['backgroundImage'] instanceof String)) {
            throw new Error("Expected the field `backgroundImage` to be a primitive type in the JSON string but got " + data['backgroundImage']);
        }
        // ensure the json data is a string
        if (data['bestSellerLabelColor'] && !(typeof data['bestSellerLabelColor'] === 'string' || data['bestSellerLabelColor'] instanceof String)) {
            throw new Error("Expected the field `bestSellerLabelColor` to be a primitive type in the JSON string but got " + data['bestSellerLabelColor']);
        }
        // ensure the json data is a string
        if (data['buttonText'] && !(typeof data['buttonText'] === 'string' || data['buttonText'] instanceof String)) {
            throw new Error("Expected the field `buttonText` to be a primitive type in the JSON string but got " + data['buttonText']);
        }
        // ensure the json data is a string
        if (data['divider'] && !(typeof data['divider'] === 'string' || data['divider'] instanceof String)) {
            throw new Error("Expected the field `divider` to be a primitive type in the JSON string but got " + data['divider']);
        }
        // ensure the json data is a string
        if (data['font'] && !(typeof data['font'] === 'string' || data['font'] instanceof String)) {
            throw new Error("Expected the field `font` to be a primitive type in the JSON string but got " + data['font']);
        }
        // ensure the json data is a string
        if (data['main'] && !(typeof data['main'] === 'string' || data['main'] instanceof String)) {
            throw new Error("Expected the field `main` to be a primitive type in the JSON string but got " + data['main']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['pdfHighlight'] && !(typeof data['pdfHighlight'] === 'string' || data['pdfHighlight'] instanceof String)) {
            throw new Error("Expected the field `pdfHighlight` to be a primitive type in the JSON string but got " + data['pdfHighlight']);
        }
        // ensure the json data is a string
        if (data['pdfSectionTitle'] && !(typeof data['pdfSectionTitle'] === 'string' || data['pdfSectionTitle'] instanceof String)) {
            throw new Error("Expected the field `pdfSectionTitle` to be a primitive type in the JSON string but got " + data['pdfSectionTitle']);
        }
        // ensure the json data is a string
        if (data['pdfText'] && !(typeof data['pdfText'] === 'string' || data['pdfText'] instanceof String)) {
            throw new Error("Expected the field `pdfText` to be a primitive type in the JSON string but got " + data['pdfText']);
        }
        // ensure the json data is a string
        if (data['suggestedLabelColor'] && !(typeof data['suggestedLabelColor'] === 'string' || data['suggestedLabelColor'] instanceof String)) {
            throw new Error("Expected the field `suggestedLabelColor` to be a primitive type in the JSON string but got " + data['suggestedLabelColor']);
        }
        // ensure the json data is a string
        if (data['tabBackground'] && !(typeof data['tabBackground'] === 'string' || data['tabBackground'] instanceof String)) {
            throw new Error("Expected the field `tabBackground` to be a primitive type in the JSON string but got " + data['tabBackground']);
        }
        // ensure the json data is a string
        if (data['tabText'] && !(typeof data['tabText'] === 'string' || data['tabText'] instanceof String)) {
            throw new Error("Expected the field `tabText` to be a primitive type in the JSON string but got " + data['tabText']);
        }
        // ensure the json data is a string
        if (data['text'] && !(typeof data['text'] === 'string' || data['text'] instanceof String)) {
            throw new Error("Expected the field `text` to be a primitive type in the JSON string but got " + data['text']);
        }

        return true;
    }


}




MenuTheme.prototype['background'] = undefined;


MenuTheme.prototype['backgroundImage'] = undefined;


MenuTheme.prototype['bestSellerLabelColor'] = undefined;


MenuTheme.prototype['buttonText'] = undefined;


MenuTheme.prototype['divider'] = undefined;


MenuTheme.prototype['font'] = undefined;


MenuTheme.prototype['id'] = undefined;


MenuTheme.prototype['main'] = undefined;


MenuTheme.prototype['name'] = undefined;


MenuTheme.prototype['pdfHighlight'] = undefined;


MenuTheme.prototype['pdfSectionTitle'] = undefined;


MenuTheme.prototype['pdfText'] = undefined;


MenuTheme.prototype['suggestedLabelColor'] = undefined;


MenuTheme.prototype['tabBackground'] = undefined;


MenuTheme.prototype['tabText'] = undefined;


MenuTheme.prototype['text'] = undefined;






export default MenuTheme;

