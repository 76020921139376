import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useLingui } from '@lingui/react';
import Backdrop from '@material-ui/core/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import Zoom from '@material-ui/core/Zoom';
import { useState } from 'react';

const themedContainerStyle = menuTheme => ({
  backgroundColor: menuTheme.background,
  backgroundImage: menuTheme.backgroundImage ? `url(${menuTheme.backgroundImage})` : 'inherit',
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
});

const useStyles = makeStyles(theme => ({
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    perspective: '1000px',
    zIndex: theme.zIndex.drawer + 1,
  },
  card: {
    display: 'grid',
    width: '90vw',
    height: '90vh',
    transition: 'transform 0.6s !important',
    transformStyle: 'preserve-3d',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    color: props => props.theme.text,
    font: props => (props.theme && props.theme.font ? props.theme.font : 'inherit'),
  },
  cardSide: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backfaceVisibility: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 2px 5px rgba(0,0,0,1)',
    borderRadius: theme.spacing(2.5),
  },
  cardFront: {
    backgroundColor: '#f0f0f0',
  },
  cardBack: {
    transform: 'rotateY(180deg) !important',
    backgroundColor: 'grey',
  },
  cardFlipped: {
    transform: 'rotateY(180deg) !important',
  },
  aspectRatio: {
    width: 'auto',
    height: 'auto',
    maxWidth: '90vw',
    maxHeight: '90vh',
    objectFit: 'cover',
    alignSelf: 'center',
    justifySelf: 'center',
  },
  image: {
    borderRadius: theme.spacing(2.5),
  },
  sideContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },

  //front content
  closeCard: {
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  clickToFlip: {
    display: 'flex',
    height: theme.spacing(6),

    backgroundColor: props => `${props.theme.background}cc`,
  },
  clickToFlipGradient: {
    height: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',

    backgroundImage: props => `linear-gradient(${props.theme.background}cc, transparent)`,
    textAlign: 'center',
    padding: theme.spacing(0, 2),
  },
  label: {
    position: 'absolute',
    top: theme.spacing(11),
    left: 0,
    padding: theme.spacing(1, 3, 1, 2),
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    backgroundColor: props => props.theme.main,
    color: '#fff',
    fontSize: theme.spacing(2.2),
  },

  //back content
  backContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    gridRowGap: theme.spacing(2),
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(0, 2, 2, 2),
  },
  title: {
    fontSize: theme.spacing(2.75),
    fontWeight: 700,
  },
  subTitle: {
    fontSize: theme.spacing(2),
    fontWeight: 500,
  },
  evidence: {
    fontSize: theme.spacing(2.25),
    letterSpacing: theme.spacing(0.25),
    fontWeight: 700,
  },
  price: {
    fontSize: theme.spacing(2.25),
    fontWeight: 'bold',
    letterSpacing: theme.spacing(1),
  },

  border: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: theme.spacing(2.5),
  },
  frontBorder: {
    border: props => `2px solid ${props.theme.main}`,
  },
  backBorder: {
    border: '2px solid #dadada50',
  },
}));

export default function QrCodeBanner(props) {
  const classes = useStyles(props);
  const [open, setOpen] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const { url, onClose, banner } = props;
  const { i18n } = useLingui();
  const closeBanner = e => {
    setOpen(false);
    onClose();
  };

  const handleCardClick = () => {
    // TODO: remove this check later, for now it's added to allow a seemless transition between the old and the new popup version
    if (banner) {
      setIsFlipped(!isFlipped);
    }
  };

  return (
    <Backdrop open={open} className={classes.cardContainer}>
      <Zoom in={open}>
        <div className={`${classes.card} ${isFlipped ? classes.cardFlipped : ''}`}>
          <div className={`${classes.cardSide} ${classes.cardFront} ${classes.aspectRatio}`}>
            <img
              src={banner?.imageUrl ?? url}
              alt="Banner Content"
              className={`${classes.aspectRatio} ${classes.image}`}
              onLoad={() => setOpen(true)}
            />
            <div className={classes.sideContainer} onClick={handleCardClick}>
              <div className={classes.clickToFlip}></div>
              <div className={classes.clickToFlipGradient}>
                {/* TODO: temp workaround to allow seemless transition */}
                {banner && <span className={classes.evidence}>{i18n._('Click to read more')}</span>}
              </div>

              {banner?.forYou && <div className={classes.label}>{i18n._('Selected for you')}</div>}

              <div className={`${classes.border} ${classes.frontBorder}`} />
            </div>
            <div className={classes.closeCard} onClick={closeBanner}>
              {i18n._('Close')}
              <CloseIcon fontSize="large" />
            </div>
          </div>

          <div
            className={`${classes.cardSide} ${classes.cardBack} ${classes.aspectRatio}`}
            style={themedContainerStyle(props.theme)}
          >
            <img
              src={banner?.imageUrl ?? url}
              alt="Banner Content"
              className={classes.aspectRatio}
              style={{ visibility: 'hidden' }}
            />
            <div className={classes.sideContainer} onClick={handleCardClick}>
              <div className={classes.backContainer}>
                <span className={classes.title}>{banner?.title}</span>
                <span className={classes.subTitle}>{banner?.subtitle}</span>
                <span>{banner?.description}</span>
                <span className={classes.price}>
                  {banner?.price} {isNaN(banner?.price.replace(',', '.')) ? '' : '€'}
                </span>
              </div>
              <div className={`${classes.border} ${classes.backBorder}`} />
            </div>
            <div className={classes.closeCard} onClick={closeBanner}>
              {i18n._('Close')}
              <CloseIcon fontSize="large" />
            </div>
          </div>
        </div>
      </Zoom>
    </Backdrop>
  );
}
