import React from 'react';
import { Stack, Typography } from '@mui/material';

export const EmptyContent = props => {
  const { text } = props;

  return (
    <Stack
      sx={{
        py: 12,
        m: 'auto',
        maxWidth: 400,
        textAlign: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography
        variant="caption"
        sx={{ fontSize: '20px', opacity: 0.8, lineHeight: '1.5', letterSpacing: '1.15px' }}
      >
        {text}
      </Typography>
    </Stack>
  );
};
