/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class PaymentPlan {
    
    constructor() { 
        
        PaymentPlan.initialize(this);
    }

    
    static initialize(obj) { 
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PaymentPlan();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('isoCountryCode')) {
                obj['isoCountryCode'] = ApiClient.convertToType(data['isoCountryCode'], 'String');
            }
            if (data.hasOwnProperty('months')) {
                obj['months'] = ApiClient.convertToType(data['months'], 'Number');
            }
            if (data.hasOwnProperty('price')) {
                obj['price'] = ApiClient.convertToType(data['price'], 'Number');
            }
            if (data.hasOwnProperty('vat')) {
                obj['vat'] = ApiClient.convertToType(data['vat'], 'Number');
            }
        }
        return obj;
    }

    
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['isoCountryCode'] && !(typeof data['isoCountryCode'] === 'string' || data['isoCountryCode'] instanceof String)) {
            throw new Error("Expected the field `isoCountryCode` to be a primitive type in the JSON string but got " + data['isoCountryCode']);
        }

        return true;
    }


}




PaymentPlan.prototype['id'] = undefined;


PaymentPlan.prototype['isoCountryCode'] = undefined;


PaymentPlan.prototype['months'] = undefined;


PaymentPlan.prototype['price'] = undefined;


PaymentPlan.prototype['vat'] = undefined;






export default PaymentPlan;

