/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import PartnerBrand from './PartnerBrand';
import PaymentPlan from './PaymentPlan';


class ClubSubscriptionProjection {
    
    constructor() { 
        
        ClubSubscriptionProjection.initialize(this);
    }

    
    static initialize(obj) { 
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ClubSubscriptionProjection();

            if (data.hasOwnProperty('brand')) {
                obj['brand'] = PartnerBrand.constructFromObject(data['brand']);
            }
            if (data.hasOwnProperty('freeForever')) {
                obj['freeForever'] = ApiClient.convertToType(data['freeForever'], 'Boolean');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('paymentErrorCode')) {
                obj['paymentErrorCode'] = ApiClient.convertToType(data['paymentErrorCode'], 'String');
            }
            if (data.hasOwnProperty('paymentPending')) {
                obj['paymentPending'] = ApiClient.convertToType(data['paymentPending'], 'Boolean');
            }
            if (data.hasOwnProperty('paymentPlan')) {
                obj['paymentPlan'] = PaymentPlan.constructFromObject(data['paymentPlan']);
            }
            if (data.hasOwnProperty('subscriptionEnabled')) {
                obj['subscriptionEnabled'] = ApiClient.convertToType(data['subscriptionEnabled'], 'Boolean');
            }
            if (data.hasOwnProperty('subscriptionExpiration')) {
                obj['subscriptionExpiration'] = ApiClient.convertToType(data['subscriptionExpiration'], 'Date');
            }
        }
        return obj;
    }

    
    static validateJSON(data) {
        // validate the optional field `brand`
        if (data['brand']) { // data not null
          PartnerBrand.validateJSON(data['brand']);
        }
        // ensure the json data is a string
        if (data['paymentErrorCode'] && !(typeof data['paymentErrorCode'] === 'string' || data['paymentErrorCode'] instanceof String)) {
            throw new Error("Expected the field `paymentErrorCode` to be a primitive type in the JSON string but got " + data['paymentErrorCode']);
        }
        // validate the optional field `paymentPlan`
        if (data['paymentPlan']) { // data not null
          PaymentPlan.validateJSON(data['paymentPlan']);
        }

        return true;
    }


}




ClubSubscriptionProjection.prototype['brand'] = undefined;


ClubSubscriptionProjection.prototype['freeForever'] = undefined;


ClubSubscriptionProjection.prototype['id'] = undefined;


ClubSubscriptionProjection.prototype['paymentErrorCode'] = undefined;


ClubSubscriptionProjection.prototype['paymentPending'] = undefined;


ClubSubscriptionProjection.prototype['paymentPlan'] = undefined;


ClubSubscriptionProjection.prototype['subscriptionEnabled'] = undefined;


ClubSubscriptionProjection.prototype['subscriptionExpiration'] = undefined;






export default ClubSubscriptionProjection;

