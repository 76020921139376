import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Spinner } from '@common/Spinner';
import { useSnackbar } from 'notistack';
import { useMutation } from '@tanstack/react-query';
import { useLingui } from '@lingui/react';
import { TextField } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { routes } from '@services/routes';
import { Button } from '@material-ui/core';
import { userApi } from '@services/api';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    height: '100vh',
    width: '100vw',
    alignItems: 'center',
    justifyContent: 'center',
  },

  topContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(3),
    fontSize: '16px',
  },

  formContainerInner: {
    display: 'flex',
    flexDirection: 'column',
    gridRowGap: theme.spacing(2),
    padding: theme.spacing(3),
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleText: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  errorMessage: {
    color: '#FF0000',
  },
}));

export default function PasswordResetPage(props) {
  const classes = useStyles(props);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get('token');
  const { i18n } = useLingui();
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const mutation = useMutation(() => userApi.passwordReset({ newPassword: password, token }), {
    onSuccess: () => {
      history.replace(routes.login);
      enqueueSnackbar(i18n._('Your password was successfully changed, now you can login'), {
        variant: 'info',
      });
    },
    onError: () => {
      enqueueSnackbar(i18n._('The link you used is invalid or expired'));
    },
  });

  if (mutation.isLoading) {
    return <Spinner />;
  }

  return (
    <div className={classes.container}>
      <div className={classes.topContainer}>
        <span className={classes.titleText}>{i18n._('Create a secure password')}</span>
        <span>
          {i18n._(
            'Your password must contain at least 8 characters and include a combination of numbers, letters, and special characters (!$@%).',
          )}
        </span>
      </div>

      <form className={classes.formContainerInner}>
        <TextField
          label={i18n._('New password')}
          value={password}
          onChange={({ target }) => setPassword(target.value)}
          type={'password'}
          required
          fullWidth
        />

        <TextField
          label={i18n._('Repeat the new password')}
          value={repeatPassword}
          onChange={({ target }) => setRepeatPassword(target.value)}
          type={'password'}
          required
          fullWidth
        />

        <Button
          color="primary"
          variant="contained"
          onClick={mutation.mutate}
          disabled={password !== repeatPassword || password.length < 8}
        >
          {i18n._('Reset password')}
        </Button>
        {password !== repeatPassword && (
          <p className={classes.errorMessage}>{i18n._('Password do not match')}</p>
        )}
        {password.length < 8 && (
          <p className={classes.errorMessage}>{i18n._('Password is too short')}</p>
        )}
      </form>

      <div />
    </div>
  );
}
