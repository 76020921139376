/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import GetClubTableZoneListResponse from '../model/GetClubTableZoneListResponse';


export default class TableZoneControllerApi {

    
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }

    getClubTableZoneListWithHttpInfo(clubId) {
      let postBody = null;
      // verify the required parameter 'clubId' is set
      if (clubId === undefined || clubId === null) {
        throw new Error("Missing the required parameter 'clubId' when calling getClubTableZoneList");
      }

      let pathParams = {
        'clubId': clubId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = GetClubTableZoneListResponse;
      return this.apiClient.callApi(
        '/clubs/{clubId}/zones', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }
    getClubTableZoneList(clubId) {
      return this.getClubTableZoneListWithHttpInfo(clubId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
