import React from 'react';
import LoginPage from '@pages/auth/login-page';
import { isLoggedIn } from '@services/api';
import { Redirect, Route } from 'react-router-dom';

export default function AuthenticatedRoute(props) {
  if (isLoggedIn()) {
    return <Route {...props} />;
  }
  return <Redirect to={LoginPage} />;
}
