import React, { useState } from 'react';
import { Box, Button, Container, DialogContentText, IconButton, Link } from '@mui/material';
import Cropper from 'react-easy-crop';
import { useLingui } from '@lingui/react';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import Crop54Icon from '@mui/icons-material/Crop54';
import Crop169Icon from '@mui/icons-material/Crop169';
import HideImageIcon from '@mui/icons-material/HideImage';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import { useRef } from 'react';
import { MultiselectionModal } from 'components/modals/multiselection-modal';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router-dom';
import TopbarPageLayout from 'layout/TopbarPageLayout';
import { fillRoute, routes } from '@services/routes';

export const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.src = url;
  });

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);
  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

async function getCroppedImg(imageSrc, pixelCrop, imageType) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const rotRad = getRadianAngle(0);
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, 0);
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(1, 1);
  ctx.translate(-image.width / 2, -image.height / 2);
  ctx.drawImage(image, 0, 0);
  const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  ctx.putImageData(data, 0, 0);
  return canvas.toDataURL(imageType);
}

export default function BannerCreationPage(props) {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [image, setImage] = useState();
  const [imageType, setImageType] = useState();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState();
  const [aspectRatio, setAspectRatio] = useState(1);
  const [showTermsOfService, setShowTermsOfService] = useState(false);
  const hiddenFileInput = useRef();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const { clubId, menuId } = useParams();
  const { i18n } = useLingui();
  const allAspectRatios = [
    {
      ratio: 1 / 1,
      label: i18n._('Square'),
      icon: <CropSquareIcon sx={{ transform: 'rotate(90deg) scale(2)' }} />,
    },
    {
      ratio: 4 / 5,
      label: '4 : 5',
      icon: <Crop54Icon sx={{ transform: 'rotate(90deg) scale(2)' }} />,
    },
    {
      ratio: 9 / 16,
      label: '9 : 16',
      icon: <Crop169Icon sx={{ transform: 'rotate(90deg) scale(2)' }} />,
    },
  ];

  const onClickDone = async () => {
    const croppedImage = await getCroppedImg(image, croppedAreaPixels, imageType);
    history.push(fillRoute(routes.bannerDetailsNew, { clubId, menuId }), {
      image: croppedImage,
      imageType,
    });
  };

  const onImageSelected = e => {
    e.preventDefault();
    const file = e.target.files[0];

    const { type } = file;
    setImageType(type);
    if (!['image/jpg', 'image/jpeg', 'image/png'].includes(type)) {
      enqueueSnackbar(i18n._('Image type not supported, load ony JPG or PNG type file'));
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onRatioChanged = value => {
    setAspectRatio(value);
  };

  const onImageUploadCancel = e => {
    setImage(null);
    hiddenFileInput.current.value = null;
  };

  return (
    <TopbarPageLayout
      title={i18n._('New promotion/event')}
      onBack={fillRoute(routes.menuList, { clubId })}
    >
      <Container
        sx={{
          py: { xs: 4, md: 8 },
          px: { xs: 4, md: 16 },
          mb: 20,
        }}
      >
        {image ? (
          <>
            <Cropper
              image={image}
              zoom={zoom}
              crop={crop}
              aspect={allAspectRatios[aspectRatio].ratio}
              onCropChange={setCrop}
              onCropComplete={(croppedArea, croppedAreaPixels) => {
                setCroppedAreaPixels(croppedAreaPixels);
              }}
              onZoomChange={setZoom}
            />
            <Box
              sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                p: 3,
                pt: 2,
                display: 'flex',
                justifyContent: 'space-around',
                bgcolor: '#f5f5f5',
              }}
            >
              <IconButton
                aria-label="delete"
                aria-controls="delete-photo"
                aria-haspopup="false"
                onClick={onImageUploadCancel}
              >
                <ClearIcon fontSize="large" color="error" />
              </IconButton>

              {allAspectRatios.map((e, i) => (
                <IconButton
                  key={i}
                  aria-label="crop button"
                  sx={{
                    flexDirection: 'column',
                    color: aspectRatio === i ? '#397FF7' : '#9E9E9E',
                  }}
                  onClick={() => onRatioChanged(i)}
                >
                  {e.icon}
                  <Box component="span" sx={{ textAlign: 'center', fontSize: '13px', mt: 1.5 }}>
                    {e.label}
                  </Box>
                </IconButton>
              ))}

              <IconButton
                aria-label="submit"
                aria-controls="submit-photo"
                aria-haspopup="false"
                onClick={onClickDone}
              >
                <DoneIcon fontSize="large" color="primary" />
              </IconButton>
            </Box>
          </>
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                height: '47vh',
                alignItems: 'end',
                justifyContent: 'center',
              }}
            >
              <HideImageIcon sx={{ fontSize: 200 }} color="disabled" />
            </Box>
            <Box
              sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                p: 3,
                pt: 2,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-around',
                bgcolor: '#f5f5f5',
              }}
            >
              <Box component="span" typography="subtitle2" sx={{ textAlign: 'center' }}>
                {i18n._('By clicking ')}
                <Box component="span" typography="body2">
                  {i18n._('Load image')}
                </Box>
                {i18n._(' below, I declare to accept the ')}
                <Link
                  underline="always"
                  color="text.secondary"
                  variant="body2"
                  onClick={() => setShowTermsOfService(true)}
                >
                  {i18n._('Terms of Service')}
                </Link>
              </Box>
              <Button
                variant="contained"
                color="primary"
                onClick={() => hiddenFileInput.current.click()}
                sx={{
                  borderRadius: '25px',
                  px: 8,
                  py: 1.5,
                  bgcolor: '#397FF7',
                }}
              >
                {i18n._('Load image')}
                <input
                  ref={hiddenFileInput}
                  type="file"
                  hidden
                  onChange={onImageSelected}
                  accept="image/jpg,image/jpeg,image/png"
                />
              </Button>
            </Box>
          </>
        )}

        <MultiselectionModal
          show={showTermsOfService}
          title={`${i18n._('Images disclaimer')}`}
          onConfirm={() => setShowTermsOfService(false)}
        >
          <DialogContentText id="disclaimer-description" variant="body2">
            {i18n._(
              'The application is in the TRIAL phase and is provided for the purpose of being tested. Any different use is to be considered the responsibility of the venue manager.',
            )}{' '}
            <br />
            <br />-{' '}
            {i18n._(
              'The 2DRINK team does not assume responsibility for input errors (prices, unauthorized images), the manager must always check the correct loading of the information on the user side',
            )}{' '}
            <br />-{' '}
            {i18n._(
              'The 2DRINK team assumes no responsibility for images and / or content deemed vulgar and / or offensive',
            )}{' '}
            <br />-{' '}
            {i18n._(
              'The 2DRINK team prohibits any form of advertising not agreed with the team itself',
            )}{' '}
            <br />-{' '}
            {i18n._(
              'The responsibility for uploads lies entirely with the person who uploads the image itself',
            )}{' '}
            <br />- {i18n._('The space inside the platform is owned by 2DRINK')} <br />-{' '}
            {i18n._(
              'The 2DRINK team reserves the right to cancel without prior notice content for advertising purposes not previously agreed',
            )}{' '}
            <br />-{' '}
            {i18n._(
              'The 2DRINK team reserves the right to delete without notice any content deemed offensive and / or vulgar',
            )}{' '}
            <br />
            <br />
            {i18n._('For any information in this regard, contact the 2DRINK Team.')}
          </DialogContentText>
        </MultiselectionModal>
      </Container>
    </TopbarPageLayout>
  );
}
