/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Message from './Message';
import PartnerBrand from './PartnerBrand';


class ClubNameProjection {
    
    constructor() { 
        
        ClubNameProjection.initialize(this);
    }

    
    static initialize(obj) { 
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ClubNameProjection();

            if (data.hasOwnProperty('brand')) {
                obj['brand'] = PartnerBrand.constructFromObject(data['brand']);
            }
            if (data.hasOwnProperty('freeForever')) {
                obj['freeForever'] = ApiClient.convertToType(data['freeForever'], 'Boolean');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('message')) {
                obj['message'] = Message.constructFromObject(data['message']);
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('subscriptionEnabled')) {
                obj['subscriptionEnabled'] = ApiClient.convertToType(data['subscriptionEnabled'], 'Boolean');
            }
            if (data.hasOwnProperty('subscriptionExpiration')) {
                obj['subscriptionExpiration'] = ApiClient.convertToType(data['subscriptionExpiration'], 'Date');
            }
        }
        return obj;
    }

    
    static validateJSON(data) {
        // validate the optional field `brand`
        if (data['brand']) { // data not null
          PartnerBrand.validateJSON(data['brand']);
        }
        // validate the optional field `message`
        if (data['message']) { // data not null
          Message.validateJSON(data['message']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }

        return true;
    }


}




ClubNameProjection.prototype['brand'] = undefined;


ClubNameProjection.prototype['freeForever'] = undefined;


ClubNameProjection.prototype['id'] = undefined;


ClubNameProjection.prototype['message'] = undefined;


ClubNameProjection.prototype['name'] = undefined;


ClubNameProjection.prototype['subscriptionEnabled'] = undefined;


ClubNameProjection.prototype['subscriptionExpiration'] = undefined;






export default ClubNameProjection;

