import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { QrCodeMenuFooter } from './QrCodeMenuFooter';

const useStyles = makeStyles(theme => ({
  footerContent: {
    color: props => props.colorTheme.text,
    font: props => (props.colorTheme && props.colorTheme.font ? props.colorTheme.font : 'inherit'),
    zIndex: 1,
  },
  genericInfo: {
    padding: theme.spacing(1, 3, 1.5, 3),
    whiteSpace: 'pre-wrap',
  },
}));

// TODO: questo componente probabilmente non serve, scorporare
export const QrCodeMenuInfoFooter = props => {
  const classes = useStyles(props);
  const {
    footer,
    clubAddress: address,
    clubPhone: phone,
    clubEmail: email,
    clubInstagram: instagram,
    clubFacebook: facebook,
    clubWebsite: website,
    clubWhatsapp: whatsapp,
  } = props;
  return (
    <div className={classes.footerContent}>
      <div className={classes.genericInfo}>{footer}</div>
      <QrCodeMenuFooter {...{ address, phone, email, instagram, facebook, website, whatsapp }} />
    </div>
  );
};
