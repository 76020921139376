/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ClubTableProjection from './ClubTableProjection';


class GetClubTableListResponse {
    
    constructor() { 
        
        GetClubTableListResponse.initialize(this);
    }

    
    static initialize(obj) { 
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new GetClubTableListResponse();

            if (data.hasOwnProperty('tables')) {
                obj['tables'] = ApiClient.convertToType(data['tables'], [ClubTableProjection]);
            }
        }
        return obj;
    }

    
    static validateJSON(data) {
        if (data['tables']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['tables'])) {
                throw new Error("Expected the field `tables` to be an array in the JSON data but got " + data['tables']);
            }
            // validate the optional field `tables` (array)
            for (const item of data['tables']) {
                ClubTableProjection.validateJsonObject(item);
            };
        }

        return true;
    }


}




GetClubTableListResponse.prototype['tables'] = undefined;






export default GetClubTableListResponse;

