/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Banner from './Banner';
import MenuSection from './MenuSection';
import MenuTheme from './MenuTheme';
import MenuTranslation from './MenuTranslation';


class GetMenuResponse {
    
    constructor() { 
        
        GetMenuResponse.initialize(this);
    }

    
    static initialize(obj) { 
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new GetMenuResponse();

            if (data.hasOwnProperty('adminDisplayName')) {
                obj['adminDisplayName'] = ApiClient.convertToType(data['adminDisplayName'], 'String');
            }
            if (data.hasOwnProperty('banner')) {
                obj['banner'] = Banner.constructFromObject(data['banner']);
            }
            if (data.hasOwnProperty('bannerUrl')) {
                obj['bannerUrl'] = ApiClient.convertToType(data['bannerUrl'], 'String');
            }
            if (data.hasOwnProperty('callWaiterEnabled')) {
                obj['callWaiterEnabled'] = ApiClient.convertToType(data['callWaiterEnabled'], 'Boolean');
            }
            if (data.hasOwnProperty('clubAddress')) {
                obj['clubAddress'] = ApiClient.convertToType(data['clubAddress'], 'String');
            }
            if (data.hasOwnProperty('clubDisplayEmail')) {
                obj['clubDisplayEmail'] = ApiClient.convertToType(data['clubDisplayEmail'], 'String');
            }
            if (data.hasOwnProperty('clubDisplayPhone')) {
                obj['clubDisplayPhone'] = ApiClient.convertToType(data['clubDisplayPhone'], 'String');
            }
            if (data.hasOwnProperty('clubFacebook')) {
                obj['clubFacebook'] = ApiClient.convertToType(data['clubFacebook'], 'String');
            }
            if (data.hasOwnProperty('clubInstagram')) {
                obj['clubInstagram'] = ApiClient.convertToType(data['clubInstagram'], 'String');
            }
            if (data.hasOwnProperty('clubWebsite')) {
                obj['clubWebsite'] = ApiClient.convertToType(data['clubWebsite'], 'String');
            }
            if (data.hasOwnProperty('clubWhatsapp')) {
                obj['clubWhatsapp'] = ApiClient.convertToType(data['clubWhatsapp'], 'String');
            }
            if (data.hasOwnProperty('footer')) {
                obj['footer'] = ApiClient.convertToType(data['footer'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('logo')) {
                obj['logo'] = ApiClient.convertToType(data['logo'], 'String');
            }
            if (data.hasOwnProperty('menuLabelEnabled')) {
                obj['menuLabelEnabled'] = ApiClient.convertToType(data['menuLabelEnabled'], 'Boolean');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('position')) {
                obj['position'] = ApiClient.convertToType(data['position'], 'Number');
            }
            if (data.hasOwnProperty('refreshTime')) {
                obj['refreshTime'] = ApiClient.convertToType(data['refreshTime'], 'Number');
            }
            if (data.hasOwnProperty('sections')) {
                obj['sections'] = ApiClient.convertToType(data['sections'], [MenuSection]);
            }
            if (data.hasOwnProperty('theme')) {
                obj['theme'] = MenuTheme.constructFromObject(data['theme']);
            }
            if (data.hasOwnProperty('translations')) {
                obj['translations'] = ApiClient.convertToType(data['translations'], {'String': MenuTranslation});
            }
            if (data.hasOwnProperty('translationsEnabled')) {
                obj['translationsEnabled'] = ApiClient.convertToType(data['translationsEnabled'], 'Boolean');
            }
        }
        return obj;
    }

    
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['adminDisplayName'] && !(typeof data['adminDisplayName'] === 'string' || data['adminDisplayName'] instanceof String)) {
            throw new Error("Expected the field `adminDisplayName` to be a primitive type in the JSON string but got " + data['adminDisplayName']);
        }
        // validate the optional field `banner`
        if (data['banner']) { // data not null
          Banner.validateJSON(data['banner']);
        }
        // ensure the json data is a string
        if (data['bannerUrl'] && !(typeof data['bannerUrl'] === 'string' || data['bannerUrl'] instanceof String)) {
            throw new Error("Expected the field `bannerUrl` to be a primitive type in the JSON string but got " + data['bannerUrl']);
        }
        // ensure the json data is a string
        if (data['clubAddress'] && !(typeof data['clubAddress'] === 'string' || data['clubAddress'] instanceof String)) {
            throw new Error("Expected the field `clubAddress` to be a primitive type in the JSON string but got " + data['clubAddress']);
        }
        // ensure the json data is a string
        if (data['clubDisplayEmail'] && !(typeof data['clubDisplayEmail'] === 'string' || data['clubDisplayEmail'] instanceof String)) {
            throw new Error("Expected the field `clubDisplayEmail` to be a primitive type in the JSON string but got " + data['clubDisplayEmail']);
        }
        // ensure the json data is a string
        if (data['clubDisplayPhone'] && !(typeof data['clubDisplayPhone'] === 'string' || data['clubDisplayPhone'] instanceof String)) {
            throw new Error("Expected the field `clubDisplayPhone` to be a primitive type in the JSON string but got " + data['clubDisplayPhone']);
        }
        // ensure the json data is a string
        if (data['clubFacebook'] && !(typeof data['clubFacebook'] === 'string' || data['clubFacebook'] instanceof String)) {
            throw new Error("Expected the field `clubFacebook` to be a primitive type in the JSON string but got " + data['clubFacebook']);
        }
        // ensure the json data is a string
        if (data['clubInstagram'] && !(typeof data['clubInstagram'] === 'string' || data['clubInstagram'] instanceof String)) {
            throw new Error("Expected the field `clubInstagram` to be a primitive type in the JSON string but got " + data['clubInstagram']);
        }
        // ensure the json data is a string
        if (data['clubWebsite'] && !(typeof data['clubWebsite'] === 'string' || data['clubWebsite'] instanceof String)) {
            throw new Error("Expected the field `clubWebsite` to be a primitive type in the JSON string but got " + data['clubWebsite']);
        }
        // ensure the json data is a string
        if (data['clubWhatsapp'] && !(typeof data['clubWhatsapp'] === 'string' || data['clubWhatsapp'] instanceof String)) {
            throw new Error("Expected the field `clubWhatsapp` to be a primitive type in the JSON string but got " + data['clubWhatsapp']);
        }
        // ensure the json data is a string
        if (data['footer'] && !(typeof data['footer'] === 'string' || data['footer'] instanceof String)) {
            throw new Error("Expected the field `footer` to be a primitive type in the JSON string but got " + data['footer']);
        }
        // ensure the json data is a string
        if (data['logo'] && !(typeof data['logo'] === 'string' || data['logo'] instanceof String)) {
            throw new Error("Expected the field `logo` to be a primitive type in the JSON string but got " + data['logo']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        if (data['sections']) { // data not null
            // ensure the json data is an array
            if (!Array.isArray(data['sections'])) {
                throw new Error("Expected the field `sections` to be an array in the JSON data but got " + data['sections']);
            }
            // validate the optional field `sections` (array)
            for (const item of data['sections']) {
                MenuSection.validateJsonObject(item);
            };
        }
        // validate the optional field `theme`
        if (data['theme']) { // data not null
          MenuTheme.validateJSON(data['theme']);
        }

        return true;
    }


}




GetMenuResponse.prototype['adminDisplayName'] = undefined;


GetMenuResponse.prototype['banner'] = undefined;


GetMenuResponse.prototype['bannerUrl'] = undefined;


GetMenuResponse.prototype['callWaiterEnabled'] = undefined;


GetMenuResponse.prototype['clubAddress'] = undefined;


GetMenuResponse.prototype['clubDisplayEmail'] = undefined;


GetMenuResponse.prototype['clubDisplayPhone'] = undefined;


GetMenuResponse.prototype['clubFacebook'] = undefined;


GetMenuResponse.prototype['clubInstagram'] = undefined;


GetMenuResponse.prototype['clubWebsite'] = undefined;


GetMenuResponse.prototype['clubWhatsapp'] = undefined;


GetMenuResponse.prototype['footer'] = undefined;


GetMenuResponse.prototype['id'] = undefined;


GetMenuResponse.prototype['logo'] = undefined;


GetMenuResponse.prototype['menuLabelEnabled'] = undefined;


GetMenuResponse.prototype['name'] = undefined;


GetMenuResponse.prototype['position'] = undefined;


GetMenuResponse.prototype['refreshTime'] = undefined;


GetMenuResponse.prototype['sections'] = undefined;


GetMenuResponse.prototype['theme'] = undefined;


GetMenuResponse.prototype['translations'] = undefined;


GetMenuResponse.prototype['translationsEnabled'] = undefined;






export default GetMenuResponse;

