import React, { useState, useEffect } from 'react';
import { styled, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';

const StyledDots = styled(MobileStepper, {
  shouldForwardProp: prop => !['visibledotsnumber', 'translatex'].includes(prop),
})(({ visibledotsnumber, translatex, theme }) => ({
  maxWidth: `${visibledotsnumber * (theme.spacing(1.25) + theme.spacing(0.75) * 2)}px`,
  backgroundColor: '#dadada90',
  padding: theme.spacing(0.5, 0),
  borderRadius: 12,
  backdropFilter: 'blur(8px)',
  margin: 'auto',
  overflow: 'hidden',
  '& .MuiMobileStepper-dots': {
    transition: 'all .2s',
    transform: `translateX(${translatex}px)`,
  },
  '& .MuiMobileStepper-dot': {
    height: theme.spacing(1.25),
    width: theme.spacing(1.25),
    margin: theme.spacing(0, 0.75),
  },
  '& .MuiMobileStepper-dotActive': { backgroundColor: 'white' },
}));

export const StepperDotsSlider = props => {
  const theme = useTheme();
  const { dotsNumber, visibleDotsNumber, currentStep, position } = props;
  const [lastBackwardVisibleDot, setLastBackwardVisibleDot] = useState(0);
  const [lastForwardVisibleDot, setLastForwardVisibleDot] = useState(visibleDotsNumber - 1);
  const [maxIndex, setMaxIndex] = useState(0);
  const [translatex, setTranslatex] = useState(0);

  useEffect(() => {
    setMaxIndex(dotsNumber - 1);
    //is moving forward
    if (currentStep >= lastForwardVisibleDot) {
      //move untill the max
      if (lastForwardVisibleDot < maxIndex) {
        setTranslatex(-theme.spacing(2.75) * (lastBackwardVisibleDot + 1));
        setLastBackwardVisibleDot(lastBackwardVisibleDot + 1);
        setLastForwardVisibleDot(lastForwardVisibleDot + 1);
      }
    }
    //is moving backward
    else if (currentStep <= lastBackwardVisibleDot) {
      //move untill the min
      if (lastBackwardVisibleDot > 0) {
        setTranslatex(-theme.spacing(2.75) * (lastBackwardVisibleDot - 1));
        setLastBackwardVisibleDot(lastBackwardVisibleDot - 1);
        setLastForwardVisibleDot(lastForwardVisibleDot - 1);
      }
    }
  }, [
    theme,
    dotsNumber,
    currentStep,
    lastBackwardVisibleDot,
    lastForwardVisibleDot,
    visibleDotsNumber,
    maxIndex,
  ]);

  return (
    <StyledDots
      variant="dots"
      steps={dotsNumber}
      elevation={4}
      visibledotsnumber={Math.min(visibleDotsNumber, dotsNumber)}
      position={position}
      activeStep={currentStep}
      translatex={translatex}
    />
  );
};
