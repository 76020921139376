import React, { useRef } from 'react';
import { Card, Container, Grid, Stack, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useLingui } from '@lingui/react';
import { useHistory, useParams } from 'react-router-dom';
import TopbarPageLayout from 'layout/TopbarPageLayout';
import { fillRoute, routes } from '@services/routes';
import { isAdmin, menuApi } from '@services/api';
import { useSnackbar } from 'notistack';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Spinner } from '@common/Spinner';

const ClubSettingsCard = ({ title, onClick }) => {
  return (
    <Card
      sx={{
        px: 3,
        py: 2,
        pr: 1,
        borderRadius: 2,
      }}
      onClick={onClick}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body2">{title}</Typography>
        <NavigateNextIcon color="primary" />
      </Stack>
    </Card>
  );
};

export const ClubSettingsBoardPage = props => {
  const history = useHistory();
  const { clubId } = useParams();
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const hiddenFileInput = useRef();
  const { i18n } = useLingui();
  const settingsButtons = [
    {
      key: 'contacts',
      title: i18n._('Contacts'),
      action: () => history.push(fillRoute(routes.clubProfileContacts, { clubId })),
    },
    {
      key: 'payment-plan',
      title: i18n._('Your plan'),
      action: () => history.push(fillRoute(routes.clubProfileManagePlan, { clubId })),
    },
  ];
  const adminSettingsButtons = [
    {
      key: 'upload-excel',
      title: i18n._('Upload Excel Menu'),
      action: () => hiddenFileInput.current.click(),
    },
    {
      key: 'settings',
      title: i18n._('Settings'),
      action: () => history.push(fillRoute(routes.clubProfileSettings, { clubId })),
    },
  ];

  const mutation = useMutation(file => menuApi.createMenuFromExcel(clubId, file), {
    onSuccess: async data => {
      if (data.outcome) {
        snackbar.enqueueSnackbar(data.outcome);
      } else {
        snackbar.enqueueSnackbar(i18n._('Menu loaded succesfully from Excel'), {
          variant: 'success',
        });
        await queryClient.invalidateQueries(['getClubMenuList', clubId]);
      }
    },
  });

  const onFileSelected = e => {
    e.preventDefault();
    const file = e.target.files[0];
    mutation.mutate(file);
  };

  if (mutation.isLoading) {
    return <Spinner />;
  }

  return (
    <TopbarPageLayout title={i18n._('Your club')}>
      <Container
        sx={{
          py: { xs: 4, md: 8 },
          px: { xs: 4, md: 16 },
        }}
      >
        <Grid container spacing={1.5} justifyContent="center">
          {settingsButtons.map(button => (
            <Grid item key={button.key} xs={12} md={8}>
              <ClubSettingsCard title={button.title} onClick={button.action} />
            </Grid>
          ))}
          {isAdmin() &&
            adminSettingsButtons.map(button => (
              <Grid item key={button.key} xs={12} md={8}>
                <ClubSettingsCard title={button.title} onClick={button.action} />
              </Grid>
            ))}

          <input
            ref={hiddenFileInput}
            type="file"
            style={{ display: 'none' }}
            onChange={onFileSelected}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          />
        </Grid>
      </Container>
    </TopbarPageLayout>
  );
};
