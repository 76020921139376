import React from 'react';
import { Spinner } from '@common/Spinner';
import { makeStyles } from '@material-ui/core/styles';
import { useLingui } from '@lingui/react';
import TopbarPageLayout from 'layout/TopbarPageLayout';
import { useParams } from 'react-router-dom';
import { fillRoute, routes } from '@services/routes';
import { useQuery } from '@tanstack/react-query';
import { clubApi } from '@services/api';

const useStyles = makeStyles(theme => ({
  alertContainer: {
    display: 'grid',
    gridAutoRows: 'min-content',
    gridAutoFlow: 'row',
    gridRowGap: theme.spacing(2),
    padding: theme.spacing(3),
  },
  title: {
    fontSize: '16px',
    fontWeight: '500',
  },
}));

export default function AlertPage(props) {
  const classes = useStyles();
  const { clubId } = useParams();
  const { i18n } = useLingui();
  const { isLoading, data } = useQuery([`getClub_${clubId}`], () => clubApi.getClub(clubId));

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <TopbarPageLayout
      title={i18n._('Notice')}
      onBack={fillRoute(routes.clubProfileList, { clubId })}
    >
      <div className={classes.alertContainer}>
        <span>{data.message.fullMessage}</span>
      </div>
    </TopbarPageLayout>
  );
}
