/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class CreateOrUpdateBannerUrlResponse {
    
    constructor() { 
        
        CreateOrUpdateBannerUrlResponse.initialize(this);
    }

    
    static initialize(obj) { 
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CreateOrUpdateBannerUrlResponse();

            if (data.hasOwnProperty('uploadUrl')) {
                obj['uploadUrl'] = ApiClient.convertToType(data['uploadUrl'], 'String');
            }
        }
        return obj;
    }

    
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['uploadUrl'] && !(typeof data['uploadUrl'] === 'string' || data['uploadUrl'] instanceof String)) {
            throw new Error("Expected the field `uploadUrl` to be a primitive type in the JSON string but got " + data['uploadUrl']);
        }

        return true;
    }


}




CreateOrUpdateBannerUrlResponse.prototype['uploadUrl'] = undefined;






export default CreateOrUpdateBannerUrlResponse;

