import { useLingui } from '@lingui/react';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import React, { useState } from 'react';

export default function AccountDeletionRequestPage(props) {
  const [username, setUsername] = useState('');
  const { i18n } = useLingui();

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        background: `linear-gradient(to bottom, ${alpha('#FFFFFF', 0.9)}, ${alpha(
          '#FFFFFF',
          0.9,
        )}), url(/assets/background/overlay_1.jpg)`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        px: 2,
        py: 12,
        minHeight: '100vh',
      }}
    >
      <Stack
        spacing={4}
        sx={{
          p: 4,
          width: 1,
          mx: 'auto',
          flexShrink: 0,
          maxWidth: 400,
          borderRadius: 2,
          bgcolor: '#FFFFFF',
          boxShadow: `-24px 24px 72px -8px ${alpha('#919EAB', 0.24)}`,
          textAlign: { xs: 'center', md: 'left' },
        }}
      >
        <div>
          <Box
            sx={{
              width: '80%',
              lineHeight: 0,
              cursor: 'pointer',
              display: 'inline-flex',
              mb: 4,
            }}
          >
            <img
              src={`${import.meta.env.VITE_S3_URL}/assets/images/logo-horizontal-b.svg`}
              alt="logo"
            />
          </Box>

          <Typography variant="h5" sx={{ pb: 2 }}>
            {i18n._('Request for account deletion')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {i18n._(
              'Enter the phone number of the account you want to delete. After that, send the email that will be opened. We will then contact you to proceed with the deletion of your account.',
            )}
          </Typography>
        </div>

        <Stack spacing={2.5} alignItems="flex-end">
          <TextField
            label={i18n._('Phone')}
            value={username}
            onChange={({ target }) => setUsername(target.value)}
            required
            fullWidth
          />

          <Button
            fullWidth
            color="error"
            size="large"
            type="submit"
            variant="contained"
            disabled={!username}
            onClick={() =>
              (window.location = `mailto:info@airdrink.it?subject=Account%20deletion%20request&body=I%20request%20the%20deletion%20of%20the%20account%20associated%20with%20the%20phone%20number%20${username}`)
            }
          >
            {i18n._('Proceed with deletion request')}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
