/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import MenuItemTranslation from './MenuItemTranslation';


class MenuItem {
    
    constructor() { 
        
        MenuItem.initialize(this);
    }

    
    static initialize(obj) { 
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new MenuItem();

            if (data.hasOwnProperty('additionalNotes')) {
                obj['additionalNotes'] = ApiClient.convertToType(data['additionalNotes'], 'String');
            }
            if (data.hasOwnProperty('allergens')) {
                obj['allergens'] = ApiClient.convertToType(data['allergens'], 'String');
            }
            if (data.hasOwnProperty('bestSeller')) {
                obj['bestSeller'] = ApiClient.convertToType(data['bestSeller'], 'Boolean');
            }
            if (data.hasOwnProperty('branded')) {
                obj['branded'] = ApiClient.convertToType(data['branded'], 'Boolean');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('disabled')) {
                obj['disabled'] = ApiClient.convertToType(data['disabled'], 'Boolean');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('ingredients')) {
                obj['ingredients'] = ApiClient.convertToType(data['ingredients'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('outOfStock')) {
                obj['outOfStock'] = ApiClient.convertToType(data['outOfStock'], 'Boolean');
            }
            if (data.hasOwnProperty('position')) {
                obj['position'] = ApiClient.convertToType(data['position'], 'Number');
            }
            if (data.hasOwnProperty('price')) {
                obj['price'] = ApiClient.convertToType(data['price'], 'Number');
            }
            if (data.hasOwnProperty('priceText')) {
                obj['priceText'] = ApiClient.convertToType(data['priceText'], 'String');
            }
            if (data.hasOwnProperty('suggested')) {
                obj['suggested'] = ApiClient.convertToType(data['suggested'], 'Boolean');
            }
            if (data.hasOwnProperty('translateName')) {
                obj['translateName'] = ApiClient.convertToType(data['translateName'], 'Boolean');
            }
            if (data.hasOwnProperty('translations')) {
                obj['translations'] = ApiClient.convertToType(data['translations'], {'String': MenuItemTranslation});
            }
        }
        return obj;
    }

    
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['additionalNotes'] && !(typeof data['additionalNotes'] === 'string' || data['additionalNotes'] instanceof String)) {
            throw new Error("Expected the field `additionalNotes` to be a primitive type in the JSON string but got " + data['additionalNotes']);
        }
        // ensure the json data is a string
        if (data['allergens'] && !(typeof data['allergens'] === 'string' || data['allergens'] instanceof String)) {
            throw new Error("Expected the field `allergens` to be a primitive type in the JSON string but got " + data['allergens']);
        }
        // ensure the json data is a string
        if (data['description'] && !(typeof data['description'] === 'string' || data['description'] instanceof String)) {
            throw new Error("Expected the field `description` to be a primitive type in the JSON string but got " + data['description']);
        }
        // ensure the json data is a string
        if (data['ingredients'] && !(typeof data['ingredients'] === 'string' || data['ingredients'] instanceof String)) {
            throw new Error("Expected the field `ingredients` to be a primitive type in the JSON string but got " + data['ingredients']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }
        // ensure the json data is a string
        if (data['priceText'] && !(typeof data['priceText'] === 'string' || data['priceText'] instanceof String)) {
            throw new Error("Expected the field `priceText` to be a primitive type in the JSON string but got " + data['priceText']);
        }

        return true;
    }


}




MenuItem.prototype['additionalNotes'] = undefined;


MenuItem.prototype['allergens'] = undefined;


MenuItem.prototype['bestSeller'] = undefined;


MenuItem.prototype['branded'] = undefined;


MenuItem.prototype['description'] = undefined;


MenuItem.prototype['disabled'] = undefined;


MenuItem.prototype['id'] = undefined;


MenuItem.prototype['ingredients'] = undefined;


MenuItem.prototype['name'] = undefined;


MenuItem.prototype['outOfStock'] = undefined;


MenuItem.prototype['position'] = undefined;


MenuItem.prototype['price'] = undefined;


MenuItem.prototype['priceText'] = undefined;


MenuItem.prototype['suggested'] = undefined;


MenuItem.prototype['translateName'] = undefined;


MenuItem.prototype['translations'] = undefined;






export default MenuItem;

