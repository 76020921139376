/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import Address from './Address';
import MenuTheme from './MenuTheme';
import Message from './Message';


class GetClubResponse {
    
    constructor() { 
        
        GetClubResponse.initialize(this);
    }

    
    static initialize(obj) { 
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new GetClubResponse();

            if (data.hasOwnProperty('address')) {
                obj['address'] = ApiClient.convertToType(data['address'], 'String');
            }
            if (data.hasOwnProperty('bannerUpdateEnabled')) {
                obj['bannerUpdateEnabled'] = ApiClient.convertToType(data['bannerUpdateEnabled'], 'Boolean');
            }
            if (data.hasOwnProperty('callWaiterEnabled')) {
                obj['callWaiterEnabled'] = ApiClient.convertToType(data['callWaiterEnabled'], 'Boolean');
            }
            if (data.hasOwnProperty('clubAddress')) {
                obj['clubAddress'] = Address.constructFromObject(data['clubAddress']);
            }
            if (data.hasOwnProperty('displayEmail')) {
                obj['displayEmail'] = ApiClient.convertToType(data['displayEmail'], 'String');
            }
            if (data.hasOwnProperty('displayPhone')) {
                obj['displayPhone'] = ApiClient.convertToType(data['displayPhone'], 'String');
            }
            if (data.hasOwnProperty('enabled')) {
                obj['enabled'] = ApiClient.convertToType(data['enabled'], 'Boolean');
            }
            if (data.hasOwnProperty('facebook')) {
                obj['facebook'] = ApiClient.convertToType(data['facebook'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('instagram')) {
                obj['instagram'] = ApiClient.convertToType(data['instagram'], 'String');
            }
            if (data.hasOwnProperty('logo')) {
                obj['logo'] = ApiClient.convertToType(data['logo'], 'String');
            }
            if (data.hasOwnProperty('menuLabelEnabled')) {
                obj['menuLabelEnabled'] = ApiClient.convertToType(data['menuLabelEnabled'], 'Boolean');
            }
            if (data.hasOwnProperty('message')) {
                obj['message'] = Message.constructFromObject(data['message']);
            }
            if (data.hasOwnProperty('pdfMenuEnabled')) {
                obj['pdfMenuEnabled'] = ApiClient.convertToType(data['pdfMenuEnabled'], 'Boolean');
            }
            if (data.hasOwnProperty('theme')) {
                obj['theme'] = MenuTheme.constructFromObject(data['theme']);
            }
            if (data.hasOwnProperty('translationsEnabled')) {
                obj['translationsEnabled'] = ApiClient.convertToType(data['translationsEnabled'], 'Boolean');
            }
            if (data.hasOwnProperty('website')) {
                obj['website'] = ApiClient.convertToType(data['website'], 'String');
            }
            if (data.hasOwnProperty('whatsapp')) {
                obj['whatsapp'] = ApiClient.convertToType(data['whatsapp'], 'String');
            }
        }
        return obj;
    }

    
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['address'] && !(typeof data['address'] === 'string' || data['address'] instanceof String)) {
            throw new Error("Expected the field `address` to be a primitive type in the JSON string but got " + data['address']);
        }
        // validate the optional field `clubAddress`
        if (data['clubAddress']) { // data not null
          Address.validateJSON(data['clubAddress']);
        }
        // ensure the json data is a string
        if (data['displayEmail'] && !(typeof data['displayEmail'] === 'string' || data['displayEmail'] instanceof String)) {
            throw new Error("Expected the field `displayEmail` to be a primitive type in the JSON string but got " + data['displayEmail']);
        }
        // ensure the json data is a string
        if (data['displayPhone'] && !(typeof data['displayPhone'] === 'string' || data['displayPhone'] instanceof String)) {
            throw new Error("Expected the field `displayPhone` to be a primitive type in the JSON string but got " + data['displayPhone']);
        }
        // ensure the json data is a string
        if (data['facebook'] && !(typeof data['facebook'] === 'string' || data['facebook'] instanceof String)) {
            throw new Error("Expected the field `facebook` to be a primitive type in the JSON string but got " + data['facebook']);
        }
        // ensure the json data is a string
        if (data['instagram'] && !(typeof data['instagram'] === 'string' || data['instagram'] instanceof String)) {
            throw new Error("Expected the field `instagram` to be a primitive type in the JSON string but got " + data['instagram']);
        }
        // ensure the json data is a string
        if (data['logo'] && !(typeof data['logo'] === 'string' || data['logo'] instanceof String)) {
            throw new Error("Expected the field `logo` to be a primitive type in the JSON string but got " + data['logo']);
        }
        // validate the optional field `message`
        if (data['message']) { // data not null
          Message.validateJSON(data['message']);
        }
        // validate the optional field `theme`
        if (data['theme']) { // data not null
          MenuTheme.validateJSON(data['theme']);
        }
        // ensure the json data is a string
        if (data['website'] && !(typeof data['website'] === 'string' || data['website'] instanceof String)) {
            throw new Error("Expected the field `website` to be a primitive type in the JSON string but got " + data['website']);
        }
        // ensure the json data is a string
        if (data['whatsapp'] && !(typeof data['whatsapp'] === 'string' || data['whatsapp'] instanceof String)) {
            throw new Error("Expected the field `whatsapp` to be a primitive type in the JSON string but got " + data['whatsapp']);
        }

        return true;
    }


}




GetClubResponse.prototype['address'] = undefined;


GetClubResponse.prototype['bannerUpdateEnabled'] = undefined;


GetClubResponse.prototype['callWaiterEnabled'] = undefined;


GetClubResponse.prototype['clubAddress'] = undefined;


GetClubResponse.prototype['displayEmail'] = undefined;


GetClubResponse.prototype['displayPhone'] = undefined;


GetClubResponse.prototype['enabled'] = undefined;


GetClubResponse.prototype['facebook'] = undefined;


GetClubResponse.prototype['id'] = undefined;


GetClubResponse.prototype['instagram'] = undefined;


GetClubResponse.prototype['logo'] = undefined;


GetClubResponse.prototype['menuLabelEnabled'] = undefined;


GetClubResponse.prototype['message'] = undefined;


GetClubResponse.prototype['pdfMenuEnabled'] = undefined;


GetClubResponse.prototype['theme'] = undefined;


GetClubResponse.prototype['translationsEnabled'] = undefined;


GetClubResponse.prototype['website'] = undefined;


GetClubResponse.prototype['whatsapp'] = undefined;






export default GetClubResponse;

