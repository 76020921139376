import React, { useRef } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useLingui } from '@lingui/react';
import Button from '@material-ui/core/Button';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { userApi } from '@services/api';
import { Spinner } from '@common/Spinner';
import { routes } from '@services/routes';
import { IconButton, InputAdornment, MenuItem, TextField } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useSnackbar } from 'notistack';
import * as Sentry from '@sentry/react';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    gridTemplateRows: `min-content auto`,
    gridRowGap: theme.spacing(2),
    placeItems: 'center',
  },
  topContainer: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    gridTemplateRows: 'min-content',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxWidth: theme.spacing(260),
    padding: theme.spacing(2, 3),
  },
  loginButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifySelf: 'center',
  },
  disclaimerText: {
    color: '#757575',
  },

  formContainer: {
    width: '80%',
    height: '100%',
  },
  buttonContainer: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    gridTemplateRows: 'min-content',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(8, 0, 4, 0),
  },

  stepFormContainer: {
    display: 'grid',
    gridAutoRows: 'min-content',
    gridAutoFlow: 'row',
    gridRowGap: theme.spacing(4),
    alignItems: 'center',
  },
  infoSpan: {
    fontSize: '20px',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
  },
}));

const brands = [
  {
    value: 'AirDrink',
    label: 'AirDrink',
  },
  {
    value: 'Martini (Bacardi)',
    label: 'Martini (Bacardi)',
  },
  {
    value: 'Carlsberg',
    label: 'Carlsberg',
  },
  {
    value: 'Fratelli Francòli',
    label: 'Fratelli Francòli',
  },
];

export default function SignupPage(props) {
  const classes = useStyles(props);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { i18n } = useLingui();
  const [activeStep, setActiveStep] = useState(0);
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');

  const stepTitles = [i18n._('Create your account'), i18n._('Login details')];
  const stepDescriptions = [
    i18n._(
      'Do you run a restaurant, cocktail bar or pub? Fill your details, a member of our team will get back to you shortly.',
    ),
    i18n._('Share further details that you will use to log in.'),
  ];

  const mutation = useMutation(
    () =>
      userApi.createUser({
        phone: phoneNumber,
        password,
        name,
        surname: lastName,
        email,
      }),
    {
      onSuccess: () => {
        history.push(routes.login);
        enqueueSnackbar(i18n._('Your account has been created. You can now log in'), {
          variant: 'info',
        });
      },
      onError: error => {
        if (error.status === 409) {
          enqueueSnackbar(
            i18n._(
              "An account with this phone number already exists. If it's not yours or if you lost your password, please contact us",
            ),
            { variant: 'warning' },
          );
        } else {
          enqueueSnackbar(i18n._('An error occurred'));
          Sentry.captureException(error);
        }
      },
    },
  );

  const onFormSubmit = event => {
    event.preventDefault();
    if (hasNextStep()) {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    } else {
      mutation.mutate();
    }
  };

  const hasNextStep = () => activeStep !== stepTitles.length - 1;

  if (mutation.isLoading) {
    return <Spinner />;
  }

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            <TextField
              label={i18n._('Name')}
              value={name}
              onChange={({ target }) => setName(target.value)}
              required
            />
            <TextField
              label={i18n._('Last name')}
              value={lastName}
              onChange={({ target }) => setLastName(target.value)}
              required
            />
            <TextField
              label={i18n._('Email')}
              value={email}
              onChange={({ target }) => setEmail(target.value)}
              type="email"
              required
            />
          </>
        );
      default:
        return (
          <>
            <TextField
              label={i18n._('Phone number')}
              value={phoneNumber}
              onChange={({ target }) => setPhoneNumber(target.value)}
              type="number"
              required
            />
            <TextField
              label={i18n._('Password')}
              value={password}
              onChange={({ target }) => setPassword(target.value)}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                minLength: 8,
                maxLength: 20,
              }}
              required
              helperText={i18n._('Remember the password to log in for the first time.')}
            />
          </>
        );
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.topContainer}>
        <div />
        <div className={classes.loginButtonContainer}>
          <span className={classes.disclaimerText}>{i18n._('Already have an Account?')}</span>
          <Button color="primary" variant="text" onClick={() => history.push(routes.login)}>
            {i18n._('Sign in')}
          </Button>
        </div>
      </div>
      <div className={classes.formContainer}>
        <form className={classes.stepFormContainer} onSubmit={onFormSubmit}>
          <div>
            <div className={classes.infoSpan}>{stepTitles[activeStep]}</div>
            <span>{stepDescriptions[activeStep]}</span>
          </div>
          {getStepContent(activeStep)}

          <div className={classes.buttonContainer}>
            {activeStep === 0 ? (
              <div />
            ) : (
              <Button
                onClick={() => setActiveStep(prevActiveStep => prevActiveStep - 1)}
                className={classes.backButton}
              >
                {i18n._('Back')}
              </Button>
            )}
            <Button type="submit" variant="contained" color="primary">
              {hasNextStep() ? i18n._('Next') : i18n._('Submit')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
