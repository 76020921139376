import React from 'react';
import { Spinner } from '@common/Spinner';
import { useLingui } from '@lingui/react';
import { Button } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TextField } from '@mui/material';
import { userApi } from '@services/api';
import { routes } from '@services/routes';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'grid',
    height: '100vh',
    width: '100vw',
    alignItems: 'center',
    justifyContent: 'center',
  },

  topContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(3),
    fontSize: '16px',
  },

  formContainerInner: {
    display: 'flex',
    flexDirection: 'column',
    gridRowGap: theme.spacing(2),
    padding: theme.spacing(3),
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleText: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
}));

export default function PasswordRecoveryPage(props) {
  const classes = useStyles(props);
  const { enqueueSnackbar } = useSnackbar();
  const [username, setUsername] = useState('');
  const history = useHistory();
  const { i18n } = useLingui();
  const mutation = useMutation(() => userApi.passwordRecovery({ phone: username }), {
    onSuccess: () => {
      enqueueSnackbar(i18n._('We sent you an email with the recovery informations'), {
        variant: 'info',
      });
    },
  });

  if (mutation.isLoading) {
    return <Spinner />;
  }

  return (
    <div className={classes.container}>
      <div className={classes.topContainer}>
        <span className={classes.titleText}>{i18n._('Find your account')}</span>
        <span>
          {i18n._(
            'Please enter the phone number associated to your account and we will send you a link to reset your password via email.',
          )}
        </span>
      </div>

      <form className={classes.formContainerInner}>
        <TextField
          label={i18n._('Phone')}
          value={username}
          onChange={({ target }) => setUsername(target.value)}
          required
          fullWidth
        />

        <Button color="primary" variant="contained" onClick={mutation.mutate} disabled={!username}>
          {i18n._('Next')}
        </Button>
        <Button color="primary" variant="text" onClick={() => history.push(routes.login)}>
          {i18n._('Back to Login')}
        </Button>
      </form>

      <div />
    </div>
  );
}
