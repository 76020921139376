import React, { useState } from 'react';
import { useLingui } from '@lingui/react';
import {
  Box,
  Container,
  Grid,
  Typography,
  Radio,
  RadioGroup,
  Stack,
  Chip,
  Divider,
  Button,
} from '@mui/material';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { clubApi } from '@services/api';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import TopbarPageLayout from 'layout/TopbarPageLayout';
import FormControlLabel, { formControlLabelClasses } from '@mui/material/FormControlLabel';
import { alpha } from '@mui/material/styles';

import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { useSnackbar } from 'notistack';
import { fillRoute, routes } from '@services/routes';
import * as Sentry from '@sentry/react';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_API_KEY);

export default function PaymentPage() {
  const { i18n } = useLingui();
  const { clubId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const { isLoading: isLoadingSetupPayment, data: setupPayment } = useQuery(
    ['setupClubPayment', clubId],
    () => clubApi.setupClubPayment(clubId),
  );

  const { isLoading: isLoadingPaymentPlans, data: paymentPlans } = useQuery(
    ['getClubAvailablePaymentPlans', clubId],
    () => clubApi.getClubAvailablePaymentPlans(clubId),
  );

  const onSubscribeClick = async ({ stripe, elements, paymentPlanId }) => {
    setDisableSubmitButton(true);
    stripeMutation.mutate({ stripe, elements, paymentPlanId });
  };

  const stripeMutation = useMutation(
    async ({ stripe, elements }) => {
      const response = await stripe.confirmSetup({
        elements,
        redirect: 'if_required',
      });
      if (response.error) {
        throw response.error;
      }
    },
    {
      onError: error => {
        setDisableSubmitButton(false);
        enqueueSnackbar(i18n._('An error occurred') + ': ' + error.message);
        Sentry.captureException(error);
      },
      onSuccess: (data, { paymentPlanId }) => clubSubscribeMutation.mutate({ paymentPlanId }),
      // We don't retry automatically since sometimes Stripe goes into a unexpected_payment_intent_state
      retry: false,
    },
  );

  const clubSubscribeMutation = useMutation(
    ({ paymentPlanId }) => clubApi.subscribeClub(clubId, { paymentPlanId }),
    {
      onSuccess: async () => {
        enqueueSnackbar(i18n._('Succesfully subscribed!'), {
          variant: 'success',
        });
        await queryClient.invalidateQueries([`getClubsOwned`]);
        history.replace(fillRoute(routes.menuList, { clubId }));
      },
      onError: () => setDisableSubmitButton(false),
    },
  );

  return (
    <TopbarPageLayout
      title={i18n._('Payment')}
      isLoading={isLoadingSetupPayment || isLoadingPaymentPlans}
    >
      <Elements stripe={stripePromise} options={{ clientSecret: setupPayment?.clientSecret }}>
        <PaymentPageContent
          paymentPlans={paymentPlans}
          onSubscribeClick={onSubscribeClick}
          disableSubmitButton={disableSubmitButton}
        />
      </Elements>
    </TopbarPageLayout>
  );
}

// Needed since Stripe hooks must be in an children components of Elements
function PaymentPageContent({ paymentPlans, onSubscribeClick, disableSubmitButton }) {
  const { i18n } = useLingui();
  const stripe = useStripe();
  const elements = useElements();
  const [paymentPlanIndex, setPaymentPlanIndex] = useState(0);
  const [cardFormFilled, setCardFormFilled] = useState(false);

  const calculateDiscountChip = (price, months) => {
    const maxPricePerMonth = paymentPlans[0].price / paymentPlans[0].months;
    const currentPricePerMonth = price / months;
    const savings = Math.floor(100 - (100 * currentPricePerMonth) / maxPricePerMonth);
    if (savings < 1) return null;
    return (
      <Chip
        label={`${i18n._(`SAVE MORE THAN `)} ${savings}%`}
        size="small"
        sx={{
          bgcolor: '#36B37E29',
          color: '#1B806A',
        }}
      />
    );
  };

  const onPaymentElementChange = event => {
    setCardFormFilled(event.complete);
  };

  return (
    <Container
      sx={{
        overflow: 'hidden',
        minHeight: 1,
        pt: { xs: 5, md: 8 },
        pb: { xs: 2, md: 7 },
      }}
    >
      <Typography variant="h5" gutterBottom>
        {i18n._('Choose the plan that suits you best')}
      </Typography>
      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{ color: 'text.secondary', mb: { xs: 5, md: 8 } }}
      >
        {i18n._(
          'To start the free trial, you have to add a payment method. During the trial period, you will not be charged.',
        )}
      </Typography>

      <Grid>
        <Grid item xs={12} md={8}>
          <Stack>
            <RadioGroup
              value={paymentPlanIndex}
              onChange={({ target }) => setPaymentPlanIndex(target.value)}
            >
              <Stack spacing={2.5}>
                {paymentPlans?.map(({ months, price }, index) => (
                  <Box
                    sx={{
                      borderRadius: 1,
                      border: theme => `solid 1px ${alpha(theme.palette.grey[500], 0.24)}`,
                      ...(index === Number(paymentPlanIndex) && {
                        boxShadow: theme => `0 0 0 1px ${theme.palette.text.primary}`,
                        borderColor: theme => theme.palette.primary.main,
                        backgroundColor: theme => theme.palette.grey[50],
                      }),
                    }}
                    key={index}
                  >
                    <FormControlLabel
                      value={index}
                      control={
                        <Radio
                          disableRipple
                          checkedIcon={<CheckCircleOutlinedIcon />}
                          sx={{ mx: 1 }}
                        />
                      }
                      label={
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                          <Stack>
                            <Box component="span" sx={{ typography: 'subtitle1', flexGrow: 1 }}>
                              {`${months} ${i18n._('months')}`}
                            </Box>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                              {eurFormatter.format(price / 100)}
                            </Typography>
                          </Stack>

                          <Stack spacing={1} direction="row" alignItems="center">
                            {calculateDiscountChip(price, months)}
                          </Stack>
                        </Stack>
                      }
                      sx={{
                        m: 0,
                        py: 2,
                        pr: 2.5,
                        width: 1,
                        [`& .${formControlLabelClasses.label}`]: {
                          width: 1,
                        },
                      }}
                    />
                  </Box>
                ))}
              </Stack>
            </RadioGroup>
          </Stack>
        </Grid>

        <Grid item xs={12} md={8}>
          <Box
            gap={3}
            display="grid"
            sx={{
              p: { md: 5 },
              borderRadius: 2,
              border: theme => ({
                md: `dashed 1px ${theme.palette.divider}`,
              }),
              mt: 5,
            }}
          >
            <Typography variant="h5" component="div">
              {i18n._('Payment Method')}
            </Typography>
            <PaymentElement onChange={onPaymentElementChange} />
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12} md={8}>
        <Stack>
          <Typography
            variant="h5"
            component="div"
            sx={{
              pt: { xs: 5, md: 8 },
              pb: { xs: 3, md: 6 },
            }}
          >
            {i18n._('Subscription Details')}
          </Typography>

          <Box
            sx={{
              p: 5,
              borderRadius: 4,
              bgcolor: '#F5F5F7',
            }}
          >
            <Stack spacing={2.5}>
              <Stack>
                <Typography variant="subtitle2">AirDrink</Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  AirDrink {i18n._(' free for 1 month')}
                </Typography>
              </Stack>

              <Divider />

              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {i18n._(
                  'No commitment. You can cancel at any time on Settings at least one day before each renewal date. The plan will automatically renew until cancelled.',
                )}
              </Typography>

              <Divider />

              <Stack spacing={2.5}>
                <Stack>
                  <Typography variant="subtitle2">{i18n._('Trial period of 1 month')}</Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {i18n._('Starting today')}
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="subtitle2">{`${
                    paymentPlans[paymentPlanIndex].months
                  } ${i18n._('months')}`}</Typography>
                  <Typography variant="subtitle2">
                    {eurFormatter.format(paymentPlans[paymentPlanIndex].price / 100)}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {i18n._('Starting from ')} {new Date().toLocaleDateString()}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Box>

          <Button
            fullWidth
            size="large"
            color="primary"
            type="submit"
            variant="contained"
            sx={{ mt: 5, mb: 3 }}
            disabled={!cardFormFilled || disableSubmitButton}
            onClick={() =>
              onSubscribeClick({
                stripe,
                elements,
                paymentPlanId: paymentPlans[paymentPlanIndex].id,
              })
            }
          >
            {i18n._('Subscribe')}
          </Button>
        </Stack>
      </Grid>
    </Container>
  );
}

const eurFormatter = new Intl.NumberFormat('it-IT', {
  style: 'currency',
  currency: 'EUR',
});
