import { createTheme } from '@material-ui/core';
import { mergeDeepRight } from 'ramda';

const defaultTheme = createTheme();

export const generalPalette = {
  primary: {
    main: '#397FF7',
    contrastText: '#fff',
  },
  secondary: {
    main: '#FB535B',
    contrastText: '#fff',
  },
  common: {
    white: '#fff',
    black: '#000',
    coral: '#ff5555',
    red: '#FB535B',
    azure: '#55c3ff',
    gold: '#DFBD69',
    transparent: 'transparent',
  },
  info: {
    main: '#757575',
    contrastText: '#fff',
  },
  error: {
    main: '#ff5555',
    contrastText: '#fff',
  },
  success: {
    main: '#00ff00',
    contrastText: '#fff',
  },
  warning: {
    main: '#ffa500',
    contrastText: '#000',
  },
  danger: {
    main: '#ff5555',
    contrastText: '#fff',
  },
  grey: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    1000: '#121212',
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#303030',
    A700: '#616161',
  },
};

export const defaultPalette = mergeDeepRight(generalPalette, {
  background: generalPalette.grey[100],
  icons: {
    default: generalPalette.common.black,
    topbar: {
      back: generalPalette.primary.contrastText,
      dark: generalPalette.primary.contrastText,
      profile: generalPalette.primary.contrastText,
    },
    input: {
      empty: generalPalette.grey[800],
    },
    button: {
      primary: generalPalette.common.white,
      secondary: generalPalette.primary.main,
      default: generalPalette.common.black,
      warning: generalPalette.common.red,
    },
    actionBar: {
      default: generalPalette.primary.main,
    },
    banner: {
      buttons: {
        default: generalPalette.grey[500],
        disabled: generalPalette.grey[300],
        primary: generalPalette.primary.main,
        warning: generalPalette.common.red,
      },
    },
    modal: {
      default: generalPalette.common.white,
    },
    building: {
      inspection: {
        goTo: generalPalette.common.black,
      },
      components: generalPalette.common.black,
    },
    swipeable: {
      search: generalPalette.common.black,
      delete: generalPalette.common.white,
      undo: generalPalette.common.black,
    },
  },
  topbar: {
    title: generalPalette.common.contrastText,
    subtitle: generalPalette.common.black,
  },
  loader: {
    color: generalPalette.primary.main,
    white: generalPalette.primary.white,
  },
  banner: {
    default: generalPalette.grey[500],
    primary: generalPalette.primary.main,
    warning: generalPalette.common.red,
  },
  emptyContent: {
    color: generalPalette.grey[500],
  },
  dialog: {
    background: generalPalette.common.white,
    color: generalPalette.common.black,
    footer: {
      border: 'rgba(255,255,255,0.75)',
    },
  },
  card: {
    background: generalPalette.common.white,
    selectedBackground: generalPalette.common.white,
    color: generalPalette.common.black,
    border: generalPalette.primary.main,
    borderMenu: generalPalette.grey[900],
    list: {
      border: generalPalette.grey[200],
    },
    data: {
      header: {
        color: generalPalette.grey[400],
      },
    },
    premium: {
      background: generalPalette.common.gold,
      color: generalPalette.common.white,
    },
  },
  form: {
    section: {
      title: generalPalette.grey[400],
      background: generalPalette.common.white,
    },
  },
  button: {
    disabled: {
      background: generalPalette.grey[500],
      color: generalPalette.grey[200],
    },
  },
  input: {
    error: generalPalette.error.main,
    border: generalPalette.grey[200],
    label: {
      color: generalPalette.grey[500],
    },
    select: {
      background: generalPalette.common.white,
      color: generalPalette.grey[900],
    },
    text: {
      color: generalPalette.common.black,
      warning: generalPalette.common.red,
    },
    search: {
      background: generalPalette.grey[400],
      text: {
        placeholder: generalPalette.grey[900],
        content: generalPalette.grey[900],
      },
      hover: {
        background: generalPalette.grey[300],
      },
    },
  },
  drawer: {
    link: {
      color: generalPalette.primary.main,
      disabled: generalPalette.grey[300],
      hover: {
        background: generalPalette.common.azure,
      },
      titleColor: generalPalette.grey[500],
    },
    version: {
      color: generalPalette.grey[500],
    },
    logout: {
      color: generalPalette.common.coral,
    },
  },
  actionBar: {
    menu: {
      background: generalPalette.primary.main,
      color: generalPalette.primary.main,
      divider: generalPalette.grey[300],
      open: {
        button: {
          background: generalPalette.common.white,
          border: generalPalette.primary.main,
        },
      },
    },
    button: {
      background: generalPalette.primary.main,
      color: generalPalette.common.white,
      border: generalPalette.primary.main,
    },
  },
  swipeable: {
    delete: {
      background: generalPalette.error.main,
    },
    undo: {
      background: generalPalette.grey[300],
      color: generalPalette.common.primary,
      spinner: generalPalette.common.primary,
      progressBar: {
        background: 'rgba(0,0,0,0.1)',
        active: 'rgba(0,0,0,0.3)',
      },
    },
  },
  tabs: {
    background: generalPalette.common.black,
    text: generalPalette.common.white,
    indicator: generalPalette.common.red,
  },
  constructionElements: {
    category: {
      background: `rgba(57, 127, 247, 0.2)`,
    },
    element: {
      icon: {
        background: generalPalette.common.white,
      },
      name: {
        color: generalPalette.common.black,
      },
    },
  },
});

export const buildDefaultOverrides = (palette, extraOverrides = {}) => ({
  MuiButton: {
    root: {
      borderRadius: '32px',
      fontSize: '16px',
      fontWeight: '400',
      textTransform: 'none',
      padding: defaultTheme.spacing(1, 10),
      transition: 'none',
      '&$disabled': {
        backgroundColor: `${palette.button.disabled.background} !important`,
        color: `${palette.button.disabled.color} !important`,
      },
    },
    contained: {
      boxShadow: 'none !important',
    },
  },
  MuiFab: {
    root: {
      width: defaultTheme.spacing(6),
      height: defaultTheme.spacing(6),
      transition: 'none',
      '&:hover': {
        background: 'none',
      },
      '&:active': {
        background: 'none',
      },
    },
  },
  MuiIconButton: {
    root: {
      padding: defaultTheme.spacing(0.5),
      color: palette.primary.main,
    },
  },
  // MuiToggleButtonGroup: {
  //   root: {
  //     display: 'grid',
  //     gridAutoFlow: 'column',
  //     gridColumnGap: defaultTheme.spacing(1),
  //     Color: defaultTheme.palette.common.black,
  //     '&.Mui-selected, &.Mui-selected:hover': {
  //       backgroundColor: defaultTheme.palette.primary.main,
  //       color: defaultTheme.palette.common.white,
  //     },
  //   },
  // },

  MuiSvgIcon: {
    root: { width: '22px', height: '22px', WebkitUserSelect: 'none' },
  },
  MuiDialog: {
    paper: {
      backgroundColor: palette.dialog.background,
      color: palette.dialog.color,
    },
    scrollPaper: {
      display: 'inherit',
      marginTop: defaultTheme.spacing(4),
    },
  },
  MuiBackdrop: {
    root: {
      backgroundColor: 'rgba(0,0,0,0.5)',
    },
  },
  MuiBottomNavigationAction: {
    root: {
      maxWidth: 'none',
      padding: '8px 12px 8px',
      borderTop: `1px solid ${palette.dialog.footer.border}`,
      '&:not(:last-child)': {
        borderRight: `1px solid ${palette.dialog.footer.border}`,
      },
      color: palette.dialog.color,
    },
  },
  MuiListItem: {
    root: {
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: 'rgba(0,0,0,0.16)',
      },
    },
  },
  MuiPopover: {
    paper: {
      backgroundColor: palette.input.select.background,
      color: palette.input.select.color,
    },
  },
  MuiInput: {
    root: {
      height: '100%',
    },
    underline: {
      '&:before': {
        borderBottom: `1px solid ${palette.input.border}`,
      },
    },
    input: {
      textAlign: 'end',
      fontSize: '14px',
      color: palette.input.text.color,
      backgroundColor: palette.input.select.background,
    },
    formControl: {
      marginTop: '0 !important',
    },
  },
  MuiInputBase: {
    root: {
      height: '100%',
    },
    input: {
      color: palette.input.text.color,
      backgroundColor: palette.input.select.background,
    },
  },
  MuiFormHelperText: {
    root: {
      height: '100%',
    },
    contained: {
      color: palette.input.text.warning,
    },
  },
  MuiInputAdornment: {
    root: {
      height: 'inherit',
      maxHeight: 'none',
      whiteSpace: 'nowrap',
      fontSize: '14px',
      color: palette.input.label.color,
    },
  },
  MuiInputLabel: {
    root: {
      whiteSpace: 'nowrap',
      fontSize: '14px',
      color: palette.input.label.color,
    },
    formControl: {
      transform: 'translate(0, 8px) scale(1)',
    },
  },
  MuiFormControl: {
    marginNormal: {
      marginTop: '0px',
    },
  },
  MuiSelect: {
    select: {
      color: palette.input.select.color,
      '&:focus': {
        backgroundColor: 'none',
      },
    },
  },
  // MuiTabs: {
  //   flexContainer: {
  //     height: defaultTheme.spacing(10),
  //   },
  // },
  // MuiTab: {
  //   wrapper: {
  //     alignSelf: 'flex-end',
  //   },
  // },
  ...extraOverrides,
});
