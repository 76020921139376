/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class CountryApiCountry {
    
    constructor() { 
        
        CountryApiCountry.initialize(this);
    }

    
    static initialize(obj) { 
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CountryApiCountry();

            if (data.hasOwnProperty('iso2')) {
                obj['iso2'] = ApiClient.convertToType(data['iso2'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
        }
        return obj;
    }

    
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['iso2'] && !(typeof data['iso2'] === 'string' || data['iso2'] instanceof String)) {
            throw new Error("Expected the field `iso2` to be a primitive type in the JSON string but got " + data['iso2']);
        }
        // ensure the json data is a string
        if (data['name'] && !(typeof data['name'] === 'string' || data['name'] instanceof String)) {
            throw new Error("Expected the field `name` to be a primitive type in the JSON string but got " + data['name']);
        }

        return true;
    }


}




CountryApiCountry.prototype['iso2'] = undefined;


CountryApiCountry.prototype['name'] = undefined;






export default CountryApiCountry;

