import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: props => (props.notFullscreen ? '100%' : '100vw'),
    height: props => (props.notFullscreen ? '100%' : '100vh'),
    color: props => (props.colorSpinner ? props.colorSpinner : theme.palette.primary.main),
  },
}));

export const Spinner = props => {
  const classes = useStyles(props);

  return (
    <div className={classes.container}>
      <CircularProgress color="inherit" />
    </div>
  );
};
