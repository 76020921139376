import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { QrCodeTabBody } from './QrCodeTabBody';
import SwipeableViews from 'react-swipeable-views';
import { StepperDotsSlider } from '../../common/StepperDotsSlider';
import { i18n } from '@lingui/core';
import { languages } from '@services/routes';
import { decodeHtml } from '@common/encode-translation-fix';

const useStyles = makeStyles(theme => ({
  containerMenu: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateRows: 'min-content auto',
    height: '100%',
    '& .MuiMobileStepper-positionStatic': {
      position: 'sticky',
      bottom: theme.spacing(2),
    },
  },
  appBar: {
    position: 'sticky',
    top: theme.spacing(10),
    backgroundColor: props => props.colorTheme.tabBackground,
    color: props => props.colorTheme.tabText,
    zIndex: '1',
    width: '100vw',
  },
  indicator: {
    background: props => props.colorTheme.main,
  },
  tabFont: {
    font: props => (props.colorTheme && props.colorTheme.font ? props.colorTheme.font : 'inherit'),
    color: props => props.colorTheme.tabText,
    fontWeight: '500',
  },
}));

export const QrCodeMenuBody = props => {
  const classes = useStyles(props);
  const { menuSections, colorTheme, containerRef } = props;
  const [currentSection, setCurrentSection] = useState(0);

  const onSectionChanged = newSectionIndex => {
    containerRef.current.scroll(0, 0);
    setCurrentSection(newSectionIndex);
  };

  const sortSectionItems = items => {
    if (items && items.length) {
      // Sorts 2 booleans in order (true, false)
      // TODO: questo non può essere semplificato ad a - b?
      const sortByBoolean = (a, b) => (a && !b ? -1 : !a && b ? 1 : 0);
      // Sorts the elements by putting the suggested ones on top, followed by the best sellers
      // and then the other. Elements of the same category are sorted by position.
      // Extremely important! Slice duplicates the array to prevent sorting it in place and messing up with the refresh popup.
      return items.slice(0).sort((a, b) => {
        const sortBySuggested = sortByBoolean(a.suggested, b.suggested);
        if (sortBySuggested !== 0) {
          return sortBySuggested;
        }
        const sortByBestSeller = sortByBoolean(a.bestSeller, b.bestSeller);
        if (sortByBestSeller !== 0) {
          return sortByBestSeller;
        }
        return a.position - b.position;
      });
    }
    return [];
  };

  const userLang = navigator.language || navigator.userLanguage;
  const languageCode = userLang ? userLang.toLowerCase().substring(0, 2) : 'it';
  console.log(`${userLang} ${languageCode}`);

  useEffect(() => i18n.activate(languages.includes(languageCode) ? languageCode : 'en'), [
    languageCode,
  ]);

  return (
    <div className={classes.containerMenu}>
      {menuSections && (
        <>
          <div className={classes.appBar}>
            <Tabs
              value={currentSection}
              onChange={(event, newSection) => onSectionChanged(newSection)}
              variant="scrollable"
              scrollButtons="on"
              classes={{
                indicator: classes.indicator,
              }}
            >
              {menuSections.map((b, i) => (
                <Tab
                  label={
                    <span className={classes.tabFont}>
                      {languageCode === 'it'
                        ? b.name
                        : decodeHtml(b.translations?.[languageCode]?.translatedName) ??
                          decodeHtml(b.translations?.['en']?.translatedName) ??
                          b.name}
                    </span>
                  }
                  key={i}
                />
              ))}
            </Tabs>
          </div>

          <SwipeableViews axis="x" index={currentSection} onChangeIndex={onSectionChanged}>
            {menuSections.map((section, i) => (
              <div role="tabpanel" id={`tabpanel-${i}`} key={i}>
                {currentSection === i && (
                  <QrCodeTabBody items={sortSectionItems(section.items)} colorTheme={colorTheme} />
                )}
              </div>
            ))}
          </SwipeableViews>

          {menuSections.length > 1 && (
            <StepperDotsSlider
              dotsNumber={menuSections.length}
              visibleDotsNumber={5}
              currentStep={currentSection}
              position="static"
            />
          )}
        </>
      )}
    </div>
  );
};
