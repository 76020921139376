export const routes = {
  clubProfileList: '/clubs/:clubId/profile',
  clubProfileContacts: '/clubs/:clubId/profile/contacts',
  clubProfileSettings: '/clubs/:clubId/profile/settings',
  clubProfileManagePlan: '/clubs/:clubId/profile/plan',
  clubQrCode: '/clubs/:clubId/qrcode',
  menuList: '/clubs/:clubId/menu',
  menuSectionList: '/clubs/:clubId/menu/:menuId',
  menuBanner: '/clubs/:clubId/menu/:menuId/banner',
  menuEdit: '/clubs/:clubId/menu/:menuId/edit',
  menuNew: '/clubs/:clubId/menu/new',
  menuSchedulingList: '/clubs/:clubId/menu/:menuId/schedulings',
  menuItemList: '/clubs/:clubId/menu/:menuId/sections/:sectionId',
  menuItemEdit: '/clubs/:clubId/menu/:menuId/sections/:sectionId/items/:itemId',
  menuItemNew: '/clubs/:clubId/menu/:menuId/sections/:sectionId/items/new',
  bannerNew: '/clubs/:clubId/menu/:menuId/banner/new',
  bannerDetails: '/clubs/:clubId/menu/:menuId/banner/details',
  bannerDetailsNew: '/clubs/:clubId/menu/:menuId/banner/new/details',
  alert: '/clubs/:clubId/notice',
  qrMenuList: '/public/qrcodemenu/:clubId/:tableId',
  qrMenuView: '/public/menu/:clubId/:tableId/:menuId',
  login: '/login',
  signup: '/signup',
  passwordRecovery: '/password/recovery',
  passwordReset: '/password/reset',
  subscriptionPayment: '/subscription/payment',
  clubNew: '/clubs/new',
  clubPayment: '/clubs/:clubId/payment',
  accountDeletion: '/account/delete',
};

export function fillRoute(route: string, params: Record<string, string>) {
  return route
    .split('/')
    .map(segment => (segment.startsWith(':') ? params[segment.substring(1)] : segment))
    .join('/');
}

export const languages = ['it', 'en'];
