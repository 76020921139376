import React, { useState } from 'react';
import { Box, Button, Container, Stack, Switch, Typography } from '@mui/material';
import { Spinner } from '@common/Spinner';
import { useLingui } from '@lingui/react';
import { useParams } from 'react-router-dom';
import TopbarPageLayout from 'layout/TopbarPageLayout';
import { fillRoute, routes } from '@services/routes';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { clubApi } from '@services/api';

export const AdminClubSettings = props => {
  const { i18n } = useLingui();
  const { clubId } = useParams();
  const queryClient = useQueryClient();
  const [settings, setSettings] = useState({
    clubEnabled: false,
    bannerUpdateEnabled: false,
    menuLabelEnabled: false,
    pdfMenuEnabled: false,
    translationsEnabled: false,
  });

  const { isInitialLoading } = useQuery([`getClub_${clubId}`], () => clubApi.getClub(clubId), {
    onSuccess: data =>
      setSettings({
        clubEnabled: data.enabled,
        bannerUpdateEnabled: data.bannerUpdateEnabled,
        menuLabelEnabled: data.menuLabelEnabled,
        pdfMenuEnabled: data.pdfMenuEnabled,
        translationsEnabled: data.translationsEnabled,
      }),
  });
  const mutation = useMutation(() => clubApi.updateClubSettings(clubId, settings), {
    onSuccess: () => queryClient.invalidateQueries([`getClub_${clubId}`]),
  });

  const handleToggle = settingName => {
    setSettings(prevSettings => ({
      ...prevSettings,
      [settingName]: !prevSettings[settingName],
    }));
  };

  const switchSettings = [
    { name: 'clubEnabled', label: i18n._('Club Enabled') },
    { name: 'bannerUpdateEnabled', label: i18n._('Promotions - Banner') },
    { name: 'menuLabelEnabled', label: i18n._('Promotions - Labels') },
    { name: 'pdfMenuEnabled', label: i18n._('PDF') },
    { name: 'translationsEnabled', label: i18n._('Translations') },
  ];

  return (
    <TopbarPageLayout
      title={i18n._('Settings')}
      onBack={fillRoute(routes.clubProfileList, { clubId })}
      isLoading={isInitialLoading || mutation.isLoading}
    >
      <Container sx={{ py: { xs: 6, md: 8 }, px: { xs: 4, md: 16 } }}>
        <form>
          <Stack spacing={2} sx={{ mb: 5 }}>
            {switchSettings.map(setting => (
              <Stack
                key={setting.name}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="overline" sx={{ color: 'text.secondary' }}>
                  {setting.label}
                </Typography>
                <Switch
                  checked={settings[setting.name]}
                  onChange={() => handleToggle(setting.name)}
                  color="primary"
                  name={setting.name}
                  inputProps={{ 'aria-label': 'checkbox' }}
                />
              </Stack>
            ))}
          </Stack>
        </form>

        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            p: 3,
            pt: 2,
            display: 'flex',
            justifyContent: 'space-around',
            bgcolor: '#f5f5f5',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            sx={{ borderRadius: '25px', px: 8, py: 1.5, bgcolor: '#397FF7' }}
            onClick={mutation.mutate}
          >
            {i18n._('Save')}
          </Button>
        </Box>
      </Container>
    </TopbarPageLayout>
  );
};
