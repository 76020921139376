import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import { i18n } from '@lingui/core';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  tutorialContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: '#fff',
    fontSize: `16px`,
  },
  title: {
    fontSize: `20px`,
  },
  swipeAnimation: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  dot: {
    width: '20px',
    height: '20px',
    position: 'relative',
    background: 'rgba(240, 240, 240, 0.5)',
    borderRadius: '40px',
    top: '34px',
    left: '75px',
    visibility: 'hidden',

    animation: `$swipeDot 2s 0.5s infinite`,
  },
  handIcon: {
    position: 'relative',
    backgroundImage: `url('https://2order.s3.eu-south-1.amazonaws.com/assets/images/hand.png')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100px',
    height: '100px',

    transformOrigin: '52% 62%',
    animation: `$swipeHand 2s infinite`,
  },
  '@keyframes swipeHand': {
    '25%': { transform: 'translate(20px) rotate(30deg)' },
    '50%': { transform: 'translate(-20px) rotate(-15deg)' },
    '100%': { transform: 'translate(0px) rotate(0)' },
  },
  '@keyframes swipeDot': {
    '12%': {
      visibility: 'visible',
      width: '40px',
    },
    '25%': {
      visibility: 'visible',
      transform: 'translate(-65px)',
      width: '20px',
    },
    '26%': { visibility: 'hidden' },
  },
}));

export const QrCodeTutorial = props => {
  const classes = useStyles(props);
  const { open, onClose } = props;

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const onTouchStart = e => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = e => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe) onClose();
  };

  return (
    <Backdrop
      className={classes.backdrop}
      open={open}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      <div className={classes.tutorialContainer}>
        <span className={classes.title}>{i18n._('Change page')}</span>
        <div className={classes.swipeAnimation}>
          <div className={classes.dot} />
          <div className={classes.handIcon} />
        </div>
        <span>{i18n._('Swipe right or left to continue')}</span>
      </div>
    </Backdrop>
  );
};
