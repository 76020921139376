import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import { useLingui } from '@lingui/react';

export default function WordConfirmationModal(props) {
  const { i18n } = useLingui();
  const [confirmationWord, setConfirmationWord] = useState('');
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight + 'px');
  const inputRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      // Update height to adapt to apple behaviour
      setViewportHeight(window.innerHeight + 'px');
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleFocus = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      // android behaviour
      setTimeout(() => {
        inputRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 300);
    }
  };

  return (
    <Dialog
      open={props.show}
      onClose={props.onClose}
      aria-labelledby="wordconfirmation-dialog"
      sx={{
        '& .MuiDialog-container': {
          height: viewportHeight,
        },
        '& .MuiDialog-paper': {
          width: '100%',
          maxWidth: { xs: '350px', md: '600px' },
        },
      }}
    >
      <DialogTitle id="testinput-dialog-title" sx={{ pb: 0 }}>
        {props.title}
      </DialogTitle>
      <DialogContent
        sx={{
          '&:first-of-type': {
            paddingTop: 2,
          },
        }}
      >
        <Typography sx={{ pb: 1, color: 'text.disabled' }}>{`${i18n._(
          'To confirm type "delete"',
        )}`}</Typography>
        <TextField
          id="name"
          fullWidth
          variant="outlined"
          onChange={({ target }) => setConfirmationWord(target.value)}
          onFocus={handleFocus}
          ref={inputRef}
        />
      </DialogContent>
      <DialogActions
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
        }}
      >
        <Button onClick={props.onClose} sx={{ color: '#fb535b' }}>
          {i18n._('CANCEL')}
        </Button>
        <Button
          onClick={props.onConfirm}
          disabled={confirmationWord.toLowerCase().trim() !== i18n._('delete')}
          sx={{ color: '#397ff7' }}
        >
          {i18n._('CONFIRM')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
