import React, { useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';

import { useLingui } from '@lingui/react';

export function MultiselectionModal({
  show,
  title,
  message,
  cancelText,
  items,
  multiselection,
  onConfirm,
  onCancel,
  selectedItems: initialSelectedItems,
  children,
}) {
  const [selectedItems, setSelectedItems] = useState(initialSelectedItems ?? []);
  const { i18n } = useLingui();

  useEffect(() => {
    setSelectedItems(initialSelectedItems ?? []);
  }, [initialSelectedItems]);

  const handleToggle = item => {
    setSelectedItems(prevSelectedItems => {
      const currentIndex = prevSelectedItems.indexOf(item);
      if (currentIndex === -1) {
        // Add item
        return multiselection ? [...prevSelectedItems, item] : [item];
      } else {
        // Remove item
        return prevSelectedItems.filter(selectedItem => selectedItem !== item);
      }
    });
  };

  return (
    <Dialog
      open={show}
      onClose={onCancel}
      aria-labelledby="multiselection-dialog"
      transitionDuration={300}
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',
          maxWidth: { xs: '350px', md: '600px' },
        },
      }}
    >
      <DialogTitle id="multiselection-dialog-title">{title}</DialogTitle>
      <DialogContent
        dividers={!!items}
        sx={{
          p: 0,
          '&:first-of-type': {
            paddingTop: 2,
          },
        }}
      >
        <Typography sx={{ px: 2, color: 'text.disabled' }}>{message ?? children}</Typography>
        {items && (
          <List>
            {items.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem
                  button
                  selected={selectedItems.indexOf(item) !== -1}
                  onClick={() => handleToggle(item)}
                >
                  <ListItemText primary={item.adminDisplayName || item.name || item} />
                </ListItem>
                {index < items.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          display: 'grid',
          gridTemplateColumns: onCancel ? '1fr 1fr' : '1fr',
        }}
      >
        {onCancel && (
          <Button onClick={onCancel} sx={{ color: '#fb535b' }}>
            {cancelText ? i18n._(cancelText) : i18n._('CANCEL')}
          </Button>
        )}
        <Button onClick={() => onConfirm(selectedItems)} sx={{ color: '#397ff7' }}>
          {i18n._('CONFIRM')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
