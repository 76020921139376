import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { isMobile } from 'react-device-detect';
import { Redirect } from 'react-router-dom';
import { routes } from '@services/routes';

const useStyles = makeStyles(theme => ({
  logoContainer: {
    height: '100vh',
    width: '100vw',
    display: 'grid',
    background: theme.palette.common.white,
    padding: '0',
  },
  logo: {
    placeSelf: 'center',
    maxWidth: '300px',
  },
  logoMobile: {
    placeSelf: 'center',
    maxWidth: '75vw',
  },
}));

// TODO: togliere quando mettiamo apps su store
export default function SplashPage(props) {
  const classes = useStyles(props);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setRedirect(true), 3000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className={classes.logoContainer}>
      <img
        className={!!isMobile ? classes.logoMobile : classes.logo}
        src={`${import.meta.env.VITE_S3_URL}/assets/images/logo-vertical-b.svg`}
        alt="logo"
      />
      {redirect && <Redirect to={routes.login} />}
    </div>
  );
}
