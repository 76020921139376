import React, { useRef } from 'react';
import { Spinner } from '@common/Spinner';
import { Box, Button, Container, Stack, TextField } from '@mui/material';
import { useLingui } from '@lingui/react';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { clubApi } from '@services/api';
import TopbarPageLayout from 'layout/TopbarPageLayout';
import { useParams } from 'react-router-dom';
import { fillRoute, routes } from '@services/routes';

export const ClubContactsPage = props => {
  const [clubURL, setClubURL] = useState('');
  const [clubPhone, setClubPhone] = useState('');
  const [clubEmail, setClubEmail] = useState('');
  const [clubIg, setClubIg] = useState('');
  const [clubFb, setClubFb] = useState('');
  const [clubWhatsapp, setClubWhatsapp] = useState('');
  const queryClient = useQueryClient();
  const { clubId } = useParams();
  const { i18n } = useLingui();
  const { isLoading } = useQuery([`getClub_${clubId}`], async () => {
    const response = await clubApi.getClub(clubId);
    setClubURL(response.website ?? '');
    setClubPhone(response.displayPhone ?? '');
    setClubEmail(response.displayEmail ?? '');
    setClubIg(response.instagram ?? '');
    setClubFb(response.facebook ?? '');
    setClubWhatsapp(response.whatsapp ?? '');

    return response;
  });

  const mutation = useMutation(
    () =>
      clubApi.updateClub(clubId, {
        instagram: clubIg,
        facebook: clubFb,
        displayEmail: clubEmail,
        website: clubURL,
        displayPhone: clubPhone,
        whatsapp: clubWhatsapp,
      }),
    {
      onSuccess: () => queryClient.invalidateQueries([`getClub_${clubId}`]),
    },
  );

  if (isLoading || mutation.isLoading) {
    return <Spinner />;
  }

  return (
    <TopbarPageLayout
      title={i18n._('Contacts')}
      onBack={fillRoute(routes.clubProfileList, { clubId })}
    >
      <Container
        sx={{
          py: { xs: 6, md: 8 },
          px: { xs: 4, md: 16 },
          '& .MuiTextField-root': {
            bgcolor: 'background.paper',
          },
        }}
      >
        <form>
          <Stack spacing={2} sx={{ mb: 5 }}>
            <Box component="span" typography="subtitle2">
              {i18n._('This information can be viewed by customers who scan the menu')}
            </Box>

            <TextField
              label={i18n._('The site of your club')}
              name="club-url"
              value={clubURL}
              onChange={({ target }) => setClubURL(target.value)}
              margin="normal"
              variant="outlined"
            />

            <TextField
              label={i18n._('Phone')}
              name="phone"
              value={clubPhone}
              onChange={({ target }) => setClubPhone(target.value)}
              margin="normal"
              variant="outlined"
            />

            <TextField
              label={i18n._('Email')}
              name="email"
              value={clubEmail}
              onChange={({ target }) => setClubEmail(target.value)}
              margin="normal"
              variant="outlined"
            />

            <TextField
              label={i18n._('Instagram')}
              name="instagram"
              value={clubIg}
              onChange={({ target }) => setClubIg(target.value)}
              margin="normal"
              variant="outlined"
            />

            <TextField
              label={i18n._('Facebook')}
              name="facebook"
              value={clubFb}
              onChange={({ target }) => setClubFb(target.value)}
              margin="normal"
              variant="outlined"
            />
            <TextField
              label={i18n._('Whatsapp')}
              name="whatsapp"
              value={clubWhatsapp}
              onChange={({ target }) => setClubWhatsapp(target.value)}
              margin="normal"
              variant="outlined"
            />
          </Stack>
        </form>

        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            p: 3,
            pt: 2,
            display: 'flex',
            justifyContent: 'space-around',
            bgcolor: '#f5f5f5',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => mutation.mutate()}
            sx={{
              borderRadius: '25px',
              px: 8,
              py: 1.5,
              bgcolor: '#397FF7',
            }}
          >
            {i18n._('Save')}
          </Button>
        </Box>
      </Container>
    </TopbarPageLayout>
  );
};
