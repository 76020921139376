import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import { useLingui } from '@lingui/react';

export default function TextInputModal(props) {
  const { i18n } = useLingui();
  const [input, setInput] = useState(props.currentValue || '');
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight + 'px');
  const inputRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      // Update height to adapt to apple behaviour
      setViewportHeight(window.innerHeight + 'px');
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setInput(props.currentValue || '');
  }, [props.currentValue]);

  const handleFocus = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      // android behaviour
      setTimeout(() => {
        inputRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }, 300);
    }
  };

  return (
    <Dialog
      open={props.show}
      onClose={props.onClose}
      aria-labelledby="testinput-dialog"
      transitionDuration={300}
      sx={{
        '& .MuiDialog-container': {
          height: viewportHeight,
        },
        '& .MuiDialog-paper': {
          width: '100%',
          maxWidth: { xs: '350px', md: '600px' },
        },
      }}
    >
      <DialogTitle id="testinput-dialog-title" sx={{ pb: 0 }}>
        {props.title}
      </DialogTitle>
      <DialogContent
        sx={{
          '&:first-of-type': {
            paddingTop: 2,
          },
        }}
      >
        <Typography sx={{ pb: 1, color: 'text.disabled' }}>{props.message}</Typography>
        <TextField
          id="name"
          fullWidth
          variant="outlined"
          value={input}
          onChange={({ target }) => setInput(target.value)}
          onFocus={handleFocus}
          ref={inputRef}
        />
      </DialogContent>
      <DialogActions
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
        }}
      >
        <Button onClick={props.onClose} sx={{ color: '#fb535b' }}>
          {i18n._('CANCEL')}
        </Button>
        <Button onClick={() => props.onConfirm(input)} sx={{ color: '#397ff7' }}>
          {i18n._('CONFIRM')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
