/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';


class CreateMenuFromExcelResponse {
    
    constructor() { 
        
        CreateMenuFromExcelResponse.initialize(this);
    }

    
    static initialize(obj) { 
    }

    
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CreateMenuFromExcelResponse();

            if (data.hasOwnProperty('outcome')) {
                obj['outcome'] = ApiClient.convertToType(data['outcome'], 'String');
            }
        }
        return obj;
    }

    
    static validateJSON(data) {
        // ensure the json data is a string
        if (data['outcome'] && !(typeof data['outcome'] === 'string' || data['outcome'] instanceof String)) {
            throw new Error("Expected the field `outcome` to be a primitive type in the JSON string but got " + data['outcome']);
        }

        return true;
    }


}




CreateMenuFromExcelResponse.prototype['outcome'] = undefined;






export default CreateMenuFromExcelResponse;

