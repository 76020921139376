import React, { useState } from 'react';
import { useLingui } from '@lingui/react';
import {
  Box,
  Card,
  Divider,
  IconButton,
  MenuItem,
  ToggleButton,
  TextField,
  ToggleButtonGroup,
  Typography,
  Stack,
  Switch,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

export default function MenuSchedulingCard(props) {
  const { scheduling, zones, cardIndex, onDelete } = props;
  const [zoneId, setZoneId] = useState(scheduling.tableZoneId);
  const [startTime, setStartTime] = useState(scheduling.startTime);
  const [endTime, setEndTime] = useState(scheduling.endTime);
  const [days, setDays] = useState(scheduling.days);
  const [allWeek, setAllWeek] = useState(scheduling.days.length === 7);
  const [allDay, setAllDay] = useState(scheduling.startTime === scheduling.endTime);
  const { i18n } = useLingui();
  const toggleAllDay = () => {
    const newAllDay = !allDay;
    setAllDay(newAllDay);
    if (newAllDay) {
      setStartTime('00:00');
      setEndTime('00:00');
      scheduling.startTime = '00:00';
      scheduling.endTime = '00:00';
    }
  };
  const dayNames = [
    i18n._('Mo'),
    i18n._('Tu'),
    i18n._('We'),
    i18n._('Th'),
    i18n._('Fr'),
    i18n._('Sa'),
    i18n._('Su'),
  ];

  const toggleAllWeek = () => {
    const newAllWeek = !allWeek;
    const newDays = newAllWeek ? [0, 1, 2, 3, 4, 5, 6] : [];
    setDays(newDays);
    setAllWeek(newAllWeek);
    scheduling.days = newDays;
  };

  const toggleDay = day => {
    let newDays;
    if (days.includes(day)) {
      newDays = days.filter(e => e !== day);
      setDays(newDays);
      setAllWeek(false);
    } else {
      newDays = [...days, day].sort();
      setDays(newDays);
      setAllWeek(newDays.length === 7);
    }
    scheduling.days = newDays;
  };

  const onZoneChange = ({ target }) => {
    setZoneId(target.value);
    scheduling.tableZoneId = target.value;
  };

  const onStartTimeChange = ({ target }) => {
    setStartTime(target.value);
    setAllDay(target.value === endTime);
    scheduling.startTime = target.value;
  };

  const onEndTimeChange = ({ target }) => {
    setEndTime(target.value);
    setAllDay(startTime === target.value);
    scheduling.endTime = target.value;
  };

  return (
    <Card
      spacing={2}
      sx={{
        px: 3,
        py: 2,
        borderRadius: 2,
      }}
    >
      <Stack spacing={1}>
        <Stack direction="row" alignItems="center" sx={{ typography: 'subtitle1' }}>
          <Stack direction="row" alignItems="center" flexGrow={1}>
            <Box component="span">
              {i18n._('Scheduling')} {cardIndex}
            </Box>
          </Stack>

          <IconButton
            aria-label="delete"
            aria-controls="long-menu"
            aria-haspopup="false"
            onClick={() => onDelete()}
          >
            <DeleteIcon color="error" />
          </IconButton>
        </Stack>

        <Divider />

        {zones.length > 1 && (
          <Stack sx={{ pt: 2 }}>
            <TextField
              select
              name="zoneId"
              label={i18n._('Define the Zone')}
              variant="outlined"
              onChange={onZoneChange}
              value={zoneId}
            >
              {zones.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        )}

        <Stack
          direction={{ xs: 'row' }}
          justifyContent={{ xs: 'space-between' }}
          alignItems={{ xs: 'center' }}
        >
          <Typography variant="overline" sx={{ color: 'text.secondary' }}>
            {i18n._('All day')}
          </Typography>
          <Switch
            checked={allDay}
            onChange={toggleAllDay}
            color="primary"
            name="allDay"
            inputProps={{ 'aria-label': 'checkbox' }}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent={{ xs: 'space-around' }}
          spacing={2}
          sx={{ typography: 'subtitle1' }}
        >
          <TextField
            type="time"
            label={i18n._('Start Time')}
            name="startTime"
            fullWidth
            onChange={onStartTimeChange}
            value={startTime}
            margin="normal"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            required
          />
          <TextField
            type="time"
            label={i18n._('End Time')}
            name="endTime"
            fullWidth
            onChange={onEndTimeChange}
            value={endTime}
            margin="normal"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            required
          />
        </Stack>

        <Stack
          direction={{ xs: 'row' }}
          justifyContent={{ xs: 'space-between' }}
          alignItems={{ xs: 'center' }}
        >
          <Typography variant="overline" sx={{ color: 'text.secondary' }}>
            {i18n._('All week')}
          </Typography>
          <Switch
            checked={allWeek}
            onChange={toggleAllWeek}
            color="primary"
            name="allDay"
            inputProps={{ 'aria-label': 'checkbox' }}
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-evenly"
          sx={{ typography: 'subtitle1' }}
        >
          <ToggleButtonGroup
            color="primary"
            value={days}
            fullWidth
            size="small"
            aria-label="DaysSelection"
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >
            {[...Array(7).keys()].map(i => (
              <ToggleButton key={i} value={i} onClick={() => toggleDay(i)}>
                {dayNames[i]}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Stack>
      </Stack>
    </Card>
  );
}
