import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Stack, Switch, TextField, Typography } from '@mui/material';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { useLingui } from '@lingui/react';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import TopbarPageLayout from 'layout/TopbarPageLayout';
import { fillRoute, routes } from '@services/routes';
import { bannerApi, uploadToS3 } from '@services/api';
import { Spinner } from '@common/Spinner';
import * as Sentry from '@sentry/react';
import { useSnackbar } from 'notistack';

export default function BannerDetailsPage(props) {
  const queryClient = useQueryClient();
  const params = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { clubId, menuId } = params;
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [price, setPrice] = useState('');
  const [forYou, setForYou] = useState(false);
  const { image, imageType } = history.location.state ?? {};
  const isNewBanner = useRouteMatch().path === routes.bannerDetailsNew;
  const previousRoute = fillRoute(isNewBanner ? routes.bannerNew : routes.menuBanner, {
    clubId,
    menuId,
  });
  const { i18n } = useLingui();
  const { isInitialLoading } = useQuery(
    [`getBanner_${menuId}_${clubId}`],
    async () => {
      const response = await bannerApi.getBanner(clubId, menuId);
      setTitle(response.title ?? '');
      setSubtitle(response.subtitle ?? '');
      setDescription(response.description ?? '');
      setPrice(response.price ?? '');
      setForYou(response.forYou ?? false);
      return response;
    },
    {
      enabled: !isNewBanner,
      // TODO: temporary ignore 404 to allow seemless transition
      onError: error => {
        if (error.status !== 404) {
          enqueueSnackbar(i18n._('An error occurred'));
          Sentry.captureException(error);
        }
      },
    },
  );

  const onFormSubmit = e => {
    e.preventDefault();
    mutation.mutate();
  };

  const mutation = useMutation(
    async () => {
      const { uploadUrl } = await bannerApi.createOrUpdateBanner(clubId, menuId, {
        title,
        subtitle,
        description,
        price,
        forYou,
        generateBannerUrl: isNewBanner,
      });
      if (isNewBanner) {
        await uploadToS3(uploadUrl, image, imageType);
      }
    },
    {
      onSuccess: async () => {
        // Invalidate and refetch
        await queryClient.invalidateQueries(['getClubMenuList', clubId]);
        history.replace(fillRoute(routes.menuList, { clubId }));
      },
    },
  );

  useEffect(() => {
    if (isNewBanner && (!image || !imageType) && !mutation.isSuccess) {
      // Returns to the previous page if banner data is lost (only new banner).
      // Mutation check added to prevent this being invoked after the succesful mutation
      history.replace(previousRoute);
    }
  }, [history, image, imageType, isNewBanner, previousRoute, mutation.isSuccess]);

  if (isInitialLoading || mutation.isLoading) {
    return <Spinner />;
  }

  return (
    <TopbarPageLayout title={i18n._('Details')} onBack={previousRoute}>
      <Container
        sx={{
          py: { xs: 6, md: 8 },
          px: { xs: 4, md: 16 },
          '& .MuiTextField-root': {
            bgcolor: 'background.paper',
          },
        }}
      >
        <form onSubmit={onFormSubmit}>
          <Stack spacing={2} sx={{ mb: 5 }}>
            <TextField
              label={i18n._('Title')}
              name="title"
              value={title}
              onChange={({ target }) => setTitle(target.value)}
              margin="normal"
              variant="outlined"
              inputProps={{
                maxLength: 100,
              }}
              required
            />

            <TextField
              label={i18n._('Subtitle')}
              name="subtitle"
              value={subtitle}
              onChange={({ target }) => setSubtitle(target.value)}
              margin="normal"
              variant="outlined"
              inputProps={{
                maxLength: 255,
              }}
              multiline
            />

            <TextField
              label={i18n._('Description')}
              name="description"
              value={description}
              onChange={({ target }) => setDescription(target.value)}
              margin="normal"
              variant="outlined"
              inputProps={{
                maxLength: 1000,
              }}
              multiline
            />

            <TextField
              label={i18n._('Price')}
              name="price"
              value={price}
              onChange={({ target }) => setPrice(target.value)}
              margin="normal"
              variant="outlined"
              required
              inputProps={{
                maxLength: 45,
              }}
              multiline
            />

            <Stack
              direction={{ xs: 'row' }}
              justifyContent={{ xs: 'space-between' }}
              alignItems={{ xs: 'center' }}
            >
              <Typography variant="overline" sx={{ color: 'text.secondary' }}>
                {i18n._('Selected for you')}
              </Typography>
              <Switch
                checked={forYou}
                onChange={() => setForYou(!forYou)}
                color="primary"
                name="label"
                inputProps={{ 'aria-label': 'checkbox' }}
              />
            </Stack>
          </Stack>

          <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              p: 3,
              pt: 2,
              display: 'flex',
              justifyContent: 'space-around',
              bgcolor: '#f5f5f5',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{
                borderRadius: '25px',
                px: 8,
                py: 1.5,
                bgcolor: '#397FF7',
              }}
            >
              {i18n._('Continue')}
            </Button>
          </Box>
        </form>
      </Container>
    </TopbarPageLayout>
  );
}
