import React, { useState } from 'react';
import { Box, Button, Card, Container, Grid, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useLingui } from '@lingui/react';
import { Spinner } from '@common/Spinner';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useHistory, useParams } from 'react-router-dom';
import { menuApi } from '@services/api';
import TopbarPageLayout from 'layout/TopbarPageLayout';
import { CommonActionMenu } from 'components/menu/common-actions-menu';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { EmptyContent } from 'components/menu/empty-content';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import WordConfirmationModal from 'components/modals/word-confirmation-modal';
import TextInputModal from 'components/modals/text-input-modal';
import { fillRoute, routes } from '@services/routes';

export default function CategoriesPage(props) {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { clubId, menuId } = useParams();
  const { i18n } = useLingui();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showNewCategoryModal, setShowNewCategoryModal] = useState(false);
  const [selectedSection, setSelectedSection] = useState();

  const { isLoading, data } = useQuery([`getMenu_${clubId}_${menuId}`], () =>
    menuApi.getMenu(clubId, menuId),
  );
  const deleteMutation = useMutation(
    () => menuApi.deleteMenuSection(clubId, menuId, selectedSection.id),
    {
      onSuccess: async () => await queryClient.invalidateQueries([`getMenu_${clubId}_${menuId}`]),
    },
  );
  const updateSectionsMutation = useMutation(
    sections => menuApi.batchCreateOrUpdateMenuSection(clubId, menuId, { sections }),
    {
      onSuccess: async data =>
        await queryClient.invalidateQueries([
          `getMenu_${clubId}_${menuId}`,
          //`getMenuSection_${clubId}_${menuId}_${sectionId}`,
        ]),
    },
  );

  const onClickRename = section => {
    setSelectedSection(section);
    setShowRenameModal(true);
  };

  const onRenameConfirm = newSectionName => {
    updateSectionsMutation.mutate([{ ...selectedSection, name: newSectionName }]);
    setShowRenameModal(false);
  };

  const onDeleteConfirm = () => {
    deleteMutation.mutate();
    setShowDeleteModal(false);
  };

  const onClickDelete = section => {
    setSelectedSection(section);
    setShowDeleteModal(true);
  };

  const onClickDisable = section => {
    section.disabled = !section.disabled;
    updateSectionsMutation.mutate([section]);
  };

  const onNewCategoryConfirm = sectionName => {
    updateSectionsMutation.mutate([
      {
        name: sectionName,
        position: data.sections.length,
      },
    ]);
    setShowNewCategoryModal(false);
  };

  if (isLoading || deleteMutation.isLoading || updateSectionsMutation.isLoading) {
    return <Spinner />;
  }

  const sortedSections = data.sections.sort((a, b) => a.position - b.position);
  const onDragAndDrop = droppedItem => {
    if (!droppedItem.destination || droppedItem.source.index === droppedItem.destination.index)
      return;
    const [reorderedItem] = sortedSections.splice(droppedItem.source.index, 1);
    sortedSections.splice(droppedItem.destination.index, 0, reorderedItem);
    sortedSections.forEach((item, index) => (item.position = index));
    updateSectionsMutation.mutate(sortedSections);
  };
  return (
    <TopbarPageLayout
      title={data.adminDisplayName ?? data.name}
      onBack={fillRoute(routes.menuList, { clubId })}
    >
      <Container
        sx={{
          py: { xs: 4, md: 8 },
          px: { xs: 4, md: 16 },
          '& .MuiTextField-root': {
            bgcolor: 'background.paper',
          },
          mb: 20,
        }}
      >
        {sortedSections && sortedSections.length > 0 ? (
          <DragDropContext onDragEnd={onDragAndDrop}>
            <Droppable droppableId="list-container">
              {provided => (
                <Grid
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  container
                  spacing={1.5}
                  justifyContent="center"
                >
                  {sortedSections.map((section, index) => (
                    <Draggable key={section.id} draggableId={section.id.toString()} index={index}>
                      {provided => (
                        <Grid
                          item
                          ref={provided.innerRef}
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}
                          xs={12}
                          md={8}
                        >
                          <Card
                            onClick={() =>
                              history.push(
                                fillRoute(routes.menuItemList, {
                                  clubId,
                                  menuId,
                                  sectionId: section.id,
                                }),
                              )
                            }
                            spacing={2}
                            sx={{
                              px: 3,
                              py: 2,
                              pr: 1,
                              borderRadius: 2,
                              backgroundColor: section.disabled ? 'rgba(230, 230, 230, 0.7)' : null,
                              boxShadow: section.disabled ? '0px 0px 0px rgba(0,0,0,0.02)' : null,
                              opacity: section.disabled ? 0.5 : 1,
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ typography: 'subtitle1' }}
                            >
                              <Stack direction="row" alignItems="center" flexGrow={1}>
                                <Box component="span">{section.name}</Box>
                              </Stack>

                              {section.disabled && <VisibilityOffIcon color="action" />}

                              <CommonActionMenu
                                onClickEdit={() => onClickRename(section)}
                                onClickDisable={() => onClickDisable(section)}
                                editButtonName={i18n._('Rename')}
                                disableButtonLabel={
                                  section.disabled ? i18n._('Enable') : i18n._('Disable')
                                }
                                onClickDelete={() => onClickDelete(section)}
                              />
                            </Stack>
                          </Card>
                        </Grid>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <EmptyContent text={i18n._('This menu is empty. Please, add a category')} />
        )}

        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            p: 3,
            pt: 2,
            display: 'flex',
            justifyContent: 'space-around',
            bgcolor: '#f5f5f5',
          }}
        >
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => setShowNewCategoryModal(true)}
            sx={{
              borderRadius: '25px',
              px: 8,
              py: 1.5,
              bgcolor: '#397FF7',
            }}
          >
            {i18n._('Add category')}
          </Button>
        </Box>
      </Container>
      <WordConfirmationModal
        show={showDeleteModal}
        title={`${i18n._('Delete category')}: ${selectedSection?.name}`}
        onConfirm={onDeleteConfirm}
        onClose={() => setShowDeleteModal(false)}
      />
      <TextInputModal
        show={showRenameModal}
        title={i18n._('Rename Category')}
        message={i18n._('Type the new Category name to continue')}
        currentValue={selectedSection?.name}
        onConfirm={onRenameConfirm}
        onClose={() => setShowRenameModal(false)}
      />
      <TextInputModal
        show={showNewCategoryModal}
        message={i18n._('Type the new category name')}
        onConfirm={onNewCategoryConfirm}
        onClose={() => setShowNewCategoryModal(false)}
      />
    </TopbarPageLayout>
  );
}
